import {encodeQueryData} from "@/plugins/resource/queryReq";
import ReqJson from "@/plugins/resource/reqJson";
import {store} from "@/plugins/store/store";

const state = {
    categories: [],
    allCategoriesCount: 0,
    category: {},
    allCategories: [],
};

const getters = {
    getCategoriesList(state) {
        return state.categories
    },
    getAllCategoriesCount(state) {
        return state.allCategoriesCount
    },
    getCategory(state) {
        return state.category
    },
    getAllCategories(state) {
        return state.allCategories
    },
};

const mutations = {
    setCategoriesList(state, categories) {
        state.categories = categories
    },
    setAllCategoriesCount(state, allCategoriesCount) {
        state.allCategoriesCount = allCategoriesCount
    },
    setCategory(state, category) {
        state.category = category
    },
    setAllCategories(state, allCategories) {
        state.allCategories = allCategories
    },
};

const actions = {

    getCategories({commit}, query) {

        this.dispatch('sendRequest', {url: `Categories${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (response.meta)
                    commit('setAllCategoriesCount', response.meta.TotalCount)

                commit('setCategoriesList', response.result)

            })
    },

    getAllCategories({getters, commit, dispatch}, query) {
        if (query.index === 0)
            commit('setAllCategories', [])

        this.dispatch('sendRequest', {url: `Categories${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                getters.getAllCategories.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    query.index = getters.getAllCategories.length
                    dispatch('getAllCategories', query)
                }

            })
    },

    getSingleCategory({commit}, categoryID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Categories/${categoryID}`, method: 'get'})
                .then((response) => {
                    commit('setCategory', response.result[0])
                    resolve(response.result[0])
                })
        })
    },

    addCategory({getters, commit,dispatch}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: 'Categories', body: new ReqJson(data.data).reqJS(), method: 'post'})
                .then(() => {
                    commit("setAllCategoriesCount", getters.getAllCategoriesCount + 1)
                    resolve()

                    if (getters.getAllCategories.length !== 0) {
                        dispatch('getAllCategories', {index: 0, limit: store.getters.getLimit30,})
                    }
                })
        })
    },

    editCategory({getters,dispatch}, data) {

        this.dispatch('sendRequest', {url: `Categories/${data.categoryID}`, body: new ReqJson(data.data).reqJS(), method: 'put'})
            .then(() => {

                dispatch('getSingleCategory',data.categoryID).then((res) => {

                    const index = getters.getCategoriesList.findIndex(item => item.ID === data.categoryID);
                    if (index !== -1) {
                        getters.getCategoriesList.splice(index, 1, res)
                    }

                    const index2 = getters.getAllCategories.findIndex(item => item.ID === data.categoryID);
                    if (index2 !== -1) {
                        getters.getAllCategories.splice(index2, 1, res)
                    }
                })

            })
    },

    deleteCategory({getters, commit}, categoryID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Categories/${categoryID}`, method: 'delete'})
                .then(() => {
                    commit("setAllCategoriesCount", getters.getAllCategoriesCount - 1)
                    resolve()

                    const index2 = getters.getAllCategories.findIndex(item => item.ID === categoryID);
                    if (index2 !== -1) {
                        getters.getAllCategories.splice(index2, 1)
                    }
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
