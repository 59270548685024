import router from "@/plugins/router/router";
import {encodeQueryData} from "@/plugins/resource/queryReq";
import ReqJson from "@/plugins/resource/reqJson";

const state = {
    lessons: [],
    allLessonsCount: 0,
    lesson: {},
    sectionLessons: [],
    allSectionLessonsCount: 0,
};

const getters = {
    getLessonsList(state) {
        return state.lessons
    },
    getAllLessonsCount(state) {
        return state.allLessonsCount
    },
    getLesson(state) {
        return state.lesson
    },
    getSectionLessonsList(state) {
        return state.sectionLessons
    },
    getAllSectionLessonsCount(state) {
        return state.allSectionLessonsCount
    },
};

const mutations = {
    setLessonsList(state, lessons) {
        state.lessons = lessons
    },
    setAllLessonsCount(state, allLessonsCount) {
        state.allLessonsCount = allLessonsCount
    },
    setLesson(state, lesson) {
        state.lesson = lesson
    },
    setSectionLessonsList(state, sectionLessons) {
        state.sectionLessons = sectionLessons
    },
    setAllSectionLessonsCount(state, allSectionLessonsCount) {
        state.allSectionLessonsCount = allSectionLessonsCount
    },
};

const actions = {

    getLessons({commit}, query) {

        this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/lessons${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllLessonsCount', response.meta.TotalCount)
                commit('setLessonsList', response.result)

            })
    },

    getSingleLesson({commit}, lessonID) {

        this.dispatch('sendRequest', {url: `Lessons/${lessonID}`, method: 'get'})
            .then(response => {

                commit('setLesson', response.result[0])

            })
    },

    addLesson({getters, commit}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: 'Lessons', body: new ReqJson(data.data).reqJS(), method: 'post'})
                .then(() => {
                    commit("setAllLessonsCount", getters.getAllLessonsCount + 1)
                    resolve()
                })
        })
    },

    editLesson({getters}, data) {

        this.dispatch('sendRequest', {url: `Lessons/${data.lessonID}`, body: new ReqJson(data.data).reqJS(), method: 'put'})
            .then((response) => {

                const index = getters.getLessonsList.findIndex(item => item.ID === data.lessonID);
                if (index !== -1) {
                    getters.getLessonsList.splice(index, 1, response.result[0])
                }

            })
    },

    deleteLesson({getters, commit}, lessonID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Lessons/${lessonID}`, method: 'delete'})
                .then(() => {
                    commit("setAllLessonsCount", getters.getAllLessonsCount - 1)
                    resolve()
                })
        })
    },

    getSectionLessons({commit}, query) {
        if (query.index == 0) {
            commit('setAllSectionLessonsCount', 0)
            commit('setSectionLessonsList', [])
        }

        this.dispatch('sendRequest', {url: `Sections/${router.currentRoute.params.sectionID}/lessons${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllSectionLessonsCount', response.meta.TotalCount)
                commit('setSectionLessonsList', response.result)

            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
