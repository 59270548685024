import {constMethods} from "@/mixin/constMethods.js";


export const validation = {
    methods: {

        validMobile(val) {
            if (val === '') return true
            let mobile = constMethods.methods.convertNumbersToEnglish(val)
            let re = /09(0(\d)|1(\d)|2(\d)|3(\d)|(9(\d)))\d{7}$/;
            return re.test(mobile.toLowerCase());
        },

        validNC(val) {
            if (val === '') return true
            let input = constMethods.methods.convertNumbersToEnglish(val)

            if (!/^\d{10}$/.test(input))
                return false;

            let check = parseInt(input[9]);
            let sum = 0;
            let i;
            for (i = 0; i < 9; ++i) {
                sum += parseInt(input[i]) * (10 - i);
            }
            sum %= 11;

            return (sum < 2 && check === sum) || (sum >= 2 && check + sum === 11);
        },

        isNumber(val) {
            val = val + ''
            if (val === undefined || val === "") {
                return true
            } else {
                val = val.replace(/,/g, '')
                let res = constMethods.methods.convertNumbersToEnglish(val)

                return !isNaN(res)
            }
        },
    }
}