import router from "@/plugins/router/router";
import {encodeQueryData} from "@/plugins/resource/queryReq";
import ReqJson from "@/plugins/resource/reqJson";

const state = {
    supports: [],
    allSupportsCount: 0,
    support: {},
};

const getters = {
    getSupportsList(state) {
        return state.supports
    },
    getAllSupportsCount(state) {
        return state.allSupportsCount
    },
    getSupport(state) {
        return state.support
    },
};

const mutations = {
    setSupportsList(state, supports) {
        state.supports = supports
    },
    setAllSupportsCount(state, allSupportsCount) {
        state.allSupportsCount = allSupportsCount
    },
    setSupport(state, support) {
        state.support = support
    },
};

const actions = {

    getSupports({commit}, query) {

        this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/supports${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllSupportsCount', response.meta.TotalCount)
                commit('setSupportsList', response.result)

            })
    },

    getSingleSupport({commit}, supportID) {

        this.dispatch('sendRequest', {url: `Supports/${supportID}`, method: 'get'})
            .then(response => {

                commit('setSupport', response.result[0])

            })
    },

    addSupport({getters, commit}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/supports`, body: new ReqJson(data.data).reqJS(), method: 'post'})
                .then(() => {
                    commit("setAllSupportsCount", getters.getAllSupportsCount + 1)
                    resolve()
                })
        })
    },

    editSupport({getters}, data) {

        this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/supports/${data.supportID}`, body: new ReqJson(data.data).reqJS(), method: 'put'})
            .then((response) => {

                const index = getters.getSupportsList.findIndex(item => item.ID === data.supportID);
                if (index !== -1) {
                    getters.getSupportsList.splice(index, 1, response.result[0])
                }

            })
    },

    deleteSupport({getters, commit}, supportID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/supports/${supportID}`, method: 'delete'})
                .then(() => {
                    commit("setAllSupportsCount", getters.getAllSupportsCount - 1)
                    resolve()
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
