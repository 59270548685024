const state = {
    bearer: 'bearer',
    ACK: 'ACK',
    limit: 10,
    limit30: 30,
    isLoading: false,
    progress: 0,
    request: {},
    cancelCurrentRequest: false,
};
const getters = {
    getBearer(state) {
        return state.bearer
    },
    getACK(state) {
        return state.ACK
    },
    getLimit(state) {
        return state.limit
    },
    getLimit30(state) {
        return state.limit30
    },
    isLoading(state) {
        return state.isLoading
    },
    getProgress(state) {
        return state.progress
    },
    getRequest(state) {
        return state.request
    },
    isCancelCurrentRequest(state) {
        return state.cancelCurrentRequest
    },
};
const mutations = {
    setLoading(state, isLoading) {
        state.isLoading = isLoading
    },
    setProgress(state, progress) {
        state.progress = progress
    },
    setRequest(state, request) {
        state.request = request
    },
    setCancelCurrentRequest(state, cancelCurrentRequest) {
        state.cancelCurrentRequest = cancelCurrentRequest
    },
};
const actions = {
    cancelRequest({commit, state}) {
        if (state.request !== {})
            state.request.abort()
        commit('setRequest', {})
        commit('setCancelCurrentRequest', true)
        commit('setLoading', false)
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}
