<template>
  <div>

    <v-container class="mt-8 d-flex flex-column">

      <add-tag
          @getList="getTags(calculateIndex($store.getters.getAllTagsCount, true, pageIndex))"
          class="align-self-end mb-4"></add-tag>

      <v-data-table
          :headers="headers"
          :items="tags"
          :options.sync="options"
          :server-items-length="$store.getters.getAllTagsCount"
          :loading="$store.getters.isLoading"
          class="elevation-1">

        <template v-slot:item.actions="{ item }">
          <v-btn small dark class="primary ml-2" @click="onDetailTag(item)">جزییات</v-btn>
          <v-btn dark class="red" small @click="deleteTag(item)">حذف</v-btn>
        </template>
      </v-data-table>

    </v-container>

  </div>
</template>

<script>
import AddTag from "@/components/dashboard/sections/basicSettings/Tags/AddTag";
import {constMethods} from "@/mixin/constMethods";

export default {
  name: "ManageTags",
  mixins: [
    constMethods
  ],
  components: {
    AddTag
  },
  data() {
    return {
      pageIndex: 0,
      options: {},
      headers: [
        {text: 'نام برچسب', align: 'center', sortable: false, value: 'Title',},
        {text: 'تعداد استفاده', align: 'center', sortable: false, value: 'UseCount',},
        {text: 'عملیات', align: 'center', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getTags(index) {
      this.pageIndex = index

      const query = {
        index,
        limit: this.$store.getters.getLimit
      }

      this.$store.dispatch('getTags', query)
    },
    deleteTag(item) {
      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {
            this.$store.dispatch('deleteTag', item.ID).then(
                () => {
                  this.getTags(this.calculateIndex(this.$store.getters.getAllTagsCount, false, this.pageIndex))
                })
          })
    },
    onDetailTag(item) {
      this.$router.push({name: this.$store.state.rn.tagDetail, query: {ID: item.ID}})
    }
  },
  computed: {
    tags() {
      return this.$store.getters.getTagsList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getTags( (val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>