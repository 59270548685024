const state = {

};
const getters = {

};
const mutations = {};
const actions = {};

export default {
    state,
    getters,
    mutations,
    actions
}