var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mt-8 d-flex flex-column"},[_c('add-banner',{staticClass:"align-self-end mb-4",on:{"getList":function($event){_vm.getBanners(_vm.calculateIndex(_vm.$store.getters.getAllBannersCount, true, _vm.pageIndex))}}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.banners,"options":_vm.options,"server-items-length":_vm.$store.getters.getAllBannersCount,"loading":_vm.$store.getters.isLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Type",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item === 'course' ? 'لینک' : 'دوره'))]}},{key:"item.Banner",fn:function(ref){
var item = ref.item;
return [_c('v-img',{ref:"imgBanner",staticClass:"ma-0 my-2 mx-auto",attrs:{"src":_vm.$store.getters.getImageServerURL + item.Banner,"max-width":"60","aspect-ratio":16/9},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{attrs:{"max-width":"100","type":"image","aspect-ratio":16/9}})]},proxy:true}],null,true)})]}},{key:"item.Link",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"mEllipse",staticStyle:{"width":"150px","direction":"ltr"},attrs:{"target":"_blank","href":item.Link}},[_vm._v(_vm._s(item.Link))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('add-banner',{attrs:{"obj":item}}),_c('v-btn',{staticClass:"red mr-2",attrs:{"dark":"","small":""},on:{"click":function($event){return _vm.deleteBanner(item)}}},[_vm._v("حذف")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }