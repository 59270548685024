import {encodeQueryData} from "@/plugins/resource/queryReq";
import ReqJson from "@/plugins/resource/reqJson";
import {store} from "@/plugins/store/store";

const state = {
    lessonTags: [],
    allLessonTags: [],
};

const getters = {
    getLessonTagsList(state) {
        return state.lessonTags
    },
    getAllLessonTagsList(state) {
        return state.allLessonTags
    },
};

const mutations = {
    setLessonTagsList(state, lessonTags) {
        state.lessonTags = lessonTags
    },
    setAllLessonTagsList(state, allLessonTags) {
        state.allLessonTags = allLessonTags
    },
};

const actions = {

    getLessonTags({commit}, data) {
        if (data.query.index == 0) {
            commit('setAllLessonTagsCount', 0)
            commit('setLessonTagsList', [])
        }

        this.dispatch('sendRequest', {url: `Lessons/${data.lessonID}/tags${encodeQueryData(data.query)}`, method: 'get'})
            .then(response => {

                if (data.query.index == 0 && response.meta)
                    commit('setAllLessonTagsCount', response.meta.TotalCount)
                commit('setLessonTagsList', response.result)

            })
    },

    getAllLessonTags({getters, commit, dispatch}, data) {

        this.dispatch('sendRequest', {url: `Lessons/${data.lessonID}/tags${encodeQueryData(data.query)}`, method: 'get'})
            .then(response => {

                if (data.query.index == 0)
                    commit('setAllLessonTagsList', [])

                getters.getAllLessonTagsList.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    data.query.index = getters.getAllLessonTagsList.length
                    dispatch('getAllLessonTags', data.query)
                }

            })
    },

    addLessonTag({dispatch}, data) {//افزودن برچسب به صورت تکی

        this.dispatch('sendRequest', {url: `Lessons/${data.lessonID}/addTag`, body: new ReqJson(data.data).reqJS(), method: 'post'})
            .then(() => {
                dispatch('getAllLessonTags', {lessonID: data.lessonID, query: {index: 0, limit: store.getters.getLimit30}})

                dispatch('getAllTags', {index: 0, limit: store.getters.getLimit30})//احتمال اینکه برچسب جدید باشد
            })
    },

    addLessonTags({dispatch}, data) {//افزودن برچسب به صورت گروهی که از لیست کلی گرفته میشوند و بر خلاف بالایی برچسب جدیدی به لیست کل اضافه نمیشود

        this.dispatch('sendRequest', {url: `Lessons/${data.lessonID}/addTags`, body: new ReqJson(data.data).reqJS(), method: 'post'})
            .then(() => {
                dispatch('getAllLessonTags', {lessonID: data.lessonID, query: {index: 0, limit: store.getters.getLimit30}})
            })
    },

    deleteLessonTag(context, data) {
        return new Promise((resolve) => {
            this.dispatch('sendRequest', {url: `Lessons/${data.lessonID}/deleteTag/${data.tagID}`, method: 'delete'})
                .then(() => {
                    resolve(data.tagID)
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
