<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!obj" color="green" dark v-bind="attrs" v-on="on">ایجاد بخش جدید</v-btn>
        <v-btn v-else dark class="orange" small v-on="on">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }"><v-icon class="white--text" v-bind="attrs" v-on="on">mdi-pencil</v-icon></template>
            <span>ویرایش</span>
          </v-tooltip>
        </v-btn>
      </template>

      <v-card>

        <v-card-title v-if="!obj"><h3>ایجاد بخش جدید</h3></v-card-title>
        <v-card-title v-else><h3>ویرایش بخش</h3></v-card-title>

        <v-card-text>
          <v-form @submit.prevent="submit" ref="form" class="px-3">

            <v-text-field label="عنوان بخش" v-model="section.Title" prepend-icon="mdi-folder" :rules="rules.sectionTitleRules"></v-text-field>

            <v-textarea label="توضیحات بخش" rows="2" v-model="section.Description" prepend-icon="mdi-folder" :rules="rules.sectionDescriptionRules"></v-textarea>

            <v-btn depressed dark class="green mx-0 mt-3" type="submit">تایید</v-btn>
          </v-form>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "AddCoursePopup",
  mixins: [
    constMethods
  ],
  props: {
    obj: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      section: this.obj ? Object.assign({}, this.obj) : {},
      dialog: false,
      rules: {},
    }
  },
  methods: {
    submit() {

      if (this.$refs.form.validate()) {
        // this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
        //     () => {
              const data = {
                "data": {
                  "CourseID": parseInt(this.$route.params.ID),
                  "Title": this.section.Title,
                  "Description": this.section.Description,
                },
              }

              if (!this.obj) {//add new section
                this.$store.dispatch('addSection', data).then(() => {
                  this.$emit('getList')
                })
              } else {//edit section
                data['sectionID'] = this.obj.ID
                this.$store.dispatch('editSection', data)
              }

              this.dialog = false
            // })

      }
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      this.section =  this.obj ? Object.assign({}, this.obj) : {}
    }
  },
  mounted() {
    this.rules = {
      sectionTitleRules: [v => !!v || 'عنوان بخش را وارد کنید', v => (v && v.length >= 5) || 'عنوان بخش نباید کمتر از 5 حرف باشد',],
      sectionDescriptionRules: [v => !!v || 'توضیحات بخش را وارد کنید', v => (v && v.length >= 5) || 'توضیحات بخش نباید کمتر از 5 حرف باشد',],
    }
  },
  watch: {
    dialog() {
      this.reset()
    }
  },
}
</script>

<style scoped>

</style>