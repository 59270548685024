<template>
  <div>

    <v-container class="mt-8 d-flex flex-column">

      <add-banner
          @getList="getBanners(calculateIndex($store.getters.getAllBannersCount, true, pageIndex))"
          class="align-self-end mb-4">
      </add-banner>

      <v-data-table
          :headers="headers"
          :items="banners"
          :options.sync="options"
          :server-items-length="$store.getters.getAllBannersCount"
          :loading="$store.getters.isLoading"
          class="elevation-1">

        <template v-slot:item.Type="{ item }">{{ item === 'course' ? 'لینک' : 'دوره' }}</template>

        <template v-slot:item.Banner="{ item }">
          <v-img
              :src="$store.getters.getImageServerURL + item.Banner"
              ref="imgBanner"
              class="ma-0 my-2 mx-auto"
              max-width="60"
              :aspect-ratio="16/9">

            <template v-slot:placeholder>

              <v-skeleton-loader
                  max-width="100"
                  type="image"
                  :aspect-ratio="16/9"
              ></v-skeleton-loader>

            </template>


          </v-img>

        </template>

        <template v-slot:item.Link="{ item }"><a target="_blank" class="mEllipse" :href="item.Link" style="width: 150px;direction: ltr">{{ item.Link }}</a>
        </template>

        <template v-slot:item.actions="{ item }">
          <add-banner :obj="item"></add-banner>
          <v-btn dark class="red mr-2" small @click="deleteBanner(item)">حذف</v-btn>
        </template>
      </v-data-table>

    </v-container>

  </div>
</template>

<script>
import AddBanner from "@/components/dashboard/sections/basicSettings/Banner/AddBanner";
import {constMethods} from "@/mixin/constMethods";

export default {
  name: "ManageBanners",
  mixins: [
    constMethods
  ],
  components: {
    AddBanner
  },
  data() {
    return {
      pageIndex: 0,
      options: {},
      headers: [
        {text: 'نام', align: 'center', sortable: false, value: 'Title',},
        {text: 'لینک', align: 'center', sortable: false, value: 'Link',},
        {text: 'عکس', align: 'center', justify: 'center', sortable: false, value: 'Banner',},
        {text: 'عملیات', align: 'center', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getBanners(index) {
      this.pageIndex = index

      const query = {
        index,
        limit: this.$store.getters.getLimit
      }

      this.$store.dispatch('getBanners', query)
    },
    deleteBanner(item) {
      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {
            this.$store.dispatch('deleteBanner', item.ID).then(
                () => {
                  this.getBanners(this.calculateIndex(this.$store.getters.getAllBannersCount, false, this.pageIndex))
                })
          })
    },
    onDetailTag(item) {
      this.$router.push({name: this.$store.state.rn.tagDetail, query: {ID: item.ID}})
    }
  },
  computed: {
    banners() {
      return this.$store.getters.getBannersList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getBanners((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>