import {encodeQueryData} from "@/plugins/resource/queryReq";

const state = {
    archivedCourses: [],
    allArchivedCoursesCount: 0,
};

const getters = {
    getArchivedCoursesList(state) {
        return state.archivedCourses
    },
    getAllArchivedCoursesCount(state) {
        return state.allArchivedCoursesCount
    },
};

const mutations = {
    setArchivedCoursesList(state, archivedCourses) {
        state.archivedCourses = archivedCourses
    },
    setAllArchivedCoursesCount(state, allArchivedCoursesCount) {
        state.allArchivedCoursesCount = allArchivedCoursesCount
    },
};

const actions = {

    getArchivedCourses({commit}, query) {
        this.dispatch('sendRequest', {url: `Courses/archives${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllArchivedCoursesCount', response.meta.TotalCount)
                commit('setArchivedCoursesList', response.result)

            })
    },

    addToArchive({getters, commit}, courseID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Courses/${courseID}/archive?r=1`, method: 'get'})
                .then(() => {
                    commit("setAllCoursesCount", getters.getAllCoursesCount - 1)
                    resolve()
                })
        })
    },

    removeFromArchive({getters, commit}, courseID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Courses/${courseID}/archive?r=0`, method: 'get'})
                .then(() => {
                    commit("setAllArchivedCoursesCount", getters.getAllArchivedCoursesCount - 1)
                    resolve()
                })
        })
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
