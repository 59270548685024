<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn dark class="mr-2 orange" small v-bind="attrs" v-on="on">مشاهده و ثبت نظر</v-btn>
      </template>

      <v-card>

        <v-card-title><h3>مشاهده و ثبت نظر</h3></v-card-title>

        <v-card-text>

          <v-card v-if="userExam.SupportReview">
            <v-card-title class="pa-2" style="font-size: 1rem">نظر قبلی:</v-card-title>
            <v-card-text>{{ userExam.SupportReview }}</v-card-text>
          </v-card>

          <v-form @submit.prevent="addComment" ref="form" class="px-3">

            <v-text-field label="نظر" v-model.trim="comment" prepend-icon="mdi-folder" :rules="commentRules"></v-text-field>

            <v-btn depressed dark class="green mx-0 mt-5 mr-4" type="submit">ثبت</v-btn>


          </v-form>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "AddUserExamComment",
  mixins: [
    constMethods
  ],
  props: {
    userExam: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      dialog: false,
      comment: '',
      commentRules: [v => !!v || 'نظر را وارد کنید'],
    }
  },
  methods: {
    addComment() {
      if (this.$refs.form.validate()) {

        const data = {
          userExamID: this.userExam.ID,
          comment: {
            r: this.comment
          }
        }

        this.$store.dispatch('addComment', data).then(() => {
          this.dialog = false
        })

        this.reset()
      }
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()
      this.comment = ''
    }
  },
  watch: {
    dialog(n) {
      if (n) {
        this.reset()
      }
    }
  },
}
</script>

<style scoped>

</style>