<template>
  <div class="text-center">
    <v-dialog v-model="$store.getters.isLoading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="px-2 py-2">

          <div class="d-flex justify-space-between align-center">
            <p class="mb-0 white--text">منتظر بمانید...</p>
            <v-progress-circular v-if="progress !== 0" :rotate="360" :size="40" :width="5" :value="progress">
              {{ progress }}
            </v-progress-circular>

            <v-progress-circular v-else :size="40" indeterminate></v-progress-circular>
          </div>

          <v-btn v-if="progress !== 0" small text class="white--text" v-on:click="$store.dispatch('cancelRequest')">انصراف از ارسال</v-btn>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      show: true,
    }
  },
  methods: {
    // cancelRequest() {
    //   this.$store.getters.getRequest.abort()
    //   this.$store.commit('setRequest', {});
    //   this.$store.commit('setCancelCurrentRequest', true);
    //   this.$store.commit('setLoading', false);
    // }
  },
  computed: {
    progress() {
      return this.$store.getters.getProgress
    }
  },

}
</script>

<style scoped>

</style>
