<template>
  <v-tooltip v-if="isEllipsisActive" top>
    <template v-slot:activator="{ on, attrs }">
      <p ref="mText" class="mEllipse mb-0" :style="{'width' : width + 'px'}" style="cursor: pointer" v-bind="attrs" v-on="on">{{ mText }}</p>
    </template>
    <span>{{ mText }}</span>
  </v-tooltip>
  <p v-else ref="mText" class="mEllipse mb-0" :style="{'width' : width + 'px'}" style="cursor: text">{{ mText }}</p>

</template>

<script>
export default {
  name: "nameOverflow",
  props: {
    mText: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      isEllipsisActive: false
    }
  },
  methods: {
    detectOverflow() {
      let e = this.$refs.mText
      if (e)
        return e.offsetWidth < e.scrollWidth;
      else return null
    }
  },
  mounted() {
    setTimeout(() => {
      this.isEllipsisActive = this.detectOverflow()
    }, 100)
  },
  watch: {
    mText: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.isEllipsisActive = this.detectOverflow()
        }, 100)
      },
    },
  },
}
</script>

<style scoped>

</style>