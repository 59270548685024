<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      scrollable
      persistent
      transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark class="mr-2 primaryLight" small v-bind="attrs" v-on:click="getAllQuestion()" v-on="on">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }"><v-icon class="white--text" v-bind="attrs" v-on="on">mdi-file-question</v-icon></template>
          <span>سوالات</span>
        </v-tooltip>
      </v-btn>
    </template>

    <v-card>

      <v-toolbar dark color="primary" style="max-height: 64px">
        <v-toolbar-title><strong>مدیریت سوالات :</strong> {{ exam.Title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>


      <v-card-text class="pa-0">
        <v-row class="ma-0">

          <v-col cols="12" md="3">
            <div>

              <v-subheader class="pr-0">افزودن سوال جدید</v-subheader>

              <v-form @submit.prevent="addQuestion" ref="form">

                <v-textarea rows="1" label="عنوان سوال" class="mb-4" v-model="question.title" prepend-icon="mdi-folder" :rules="rules.questionTitle"></v-textarea>


                <div class="d-block">
                  <v-textarea rows="1" outlined :label="answerItems[0].title" v-model="question.q1" :rules="rules.q1"></v-textarea>
                  <v-textarea rows="1" outlined :label="answerItems[1].title" v-model="question.q2" :rules="rules.q2"></v-textarea>
                  <v-textarea rows="1" outlined :label="answerItems[2].title" v-model="question.q3" :rules="rules.q3"></v-textarea>
                  <v-textarea rows="1" outlined :label="answerItems[3].title" v-model="question.q4" :rules="rules.q4"></v-textarea>
                </div>

                <p class="mt-4 mb-0">پاسخ صحیح:</p>

                <v-radio-group row v-model="question.answer" :rules="rules.Answer">
                  <v-radio
                      style="width: 105px"
                      class="ma-0"
                      v-for="(item,i) in answerItems"
                      :key="i"
                      :label="item.title"
                      :value="item.answer">
                  </v-radio>
                </v-radio-group>

                <v-btn dark class="green mt-5" type="submit">ثبت سوال</v-btn>

              </v-form>

            </div>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col cols="12" md="9" class="overflow-y-auto pa-0">

            <v-card-text class="pa-0">
              <div v-for="(n , i) in allQuestions" v-bind:key="i" class="d-flex flex-column">
                <v-icon style="cursor: pointer" class="red--text align-self-end ml-2 mt-2" @click="onDeleteQuestion(n)">mdi-close-circle</v-icon>
                <p class="pa-4 ma-0 font-weight-bold">{{ n.QuestionTitle }}</p>
                <v-row class="pa-4 pt-0 ma-0">
                  <v-col cols="12" sm="6" class="d-flex">
                    <p class="font-weight-bold ma-0">گزینه اول: </p>
                    <p class="mr-2 ma-0">{{ n.Q1 }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" class="d-flex">
                    <p class="font-weight-bold ma-0">گزینه دوم: </p>
                    <p class="mr-2 ma-0">{{ n.Q2 }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" class="d-flex">
                    <p class="font-weight-bold ma-0">گزینه سوم: </p>
                    <p class="mr-2 ma-0">{{ n.Q3 }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" class="d-flex">
                    <p class="font-weight-bold ma-0">گزینه چهارم: </p>
                    <p class="mr-2 ma-0">{{ n.Q4 }}</p>
                  </v-col>
                </v-row>
                <p class="pa-4 ma-0 font-weight-bold green--text">گزینه صحیح : {{ n.Answer }}</p>
                <v-divider></v-divider>

              </div>
            </v-card-text>
          </v-col>

        </v-row>

      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import {constMethods} from "@/mixin/constMethods";
import {store} from "@/plugins/store/store";

export default {
  name: "MangeContentPopup",
  components: {},
  mixins: [
    constMethods
  ],
  props: {
    exam: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      dialog: false,
      question: {
        title: "",
        q1: "",
        q2: "",
        q3: "",
        q4: "",
        answer: 0
      },
      rules: [],
      answerItems: [
        {title: 'گزینه اول', answer: 1},
        {title: 'گزینه دوم', answer: 2},
        {title: 'گزینه سوم', answer: 3},
        {title: 'گزینه چارم', answer: 4},
      ],
    }
  },
  methods: {
    getAllQuestion() {
      this.$store.commit('setAllQuestionsList', [])
      this.$store.dispatch('getAllQuestions', {examID: this.exam.ID, query: {index: 0, limit: store.getters.getLimit30}})
    },
    addQuestion() {

      if (this.$refs.form.validate()) {

        const data = {
          examID: this.exam.ID,
          data: {
            "QuestionTitle": this.question.title,
            "Q1": this.question.q1,
            "Q2": this.question.q2,
            "Q3": this.question.q3,
            "Q4": this.question.q4,
            "Answer": this.question.answer,
          },
        }

        this.$store.dispatch('addQuestion', data).then(() => {
          this.reset()
        })

      }
    },
    onDeleteQuestion(question) {

      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {

            const data = {
              examID: this.exam.ID,
              questionID: question.ID
            }

            this.$store.dispatch('deleteQuestion', data)
          })
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      this.question = {
        title: "",
        q1: "",
        q2: "",
        q3: "",
        q4: "",
        answer: 0,
      }
    },
  },
  mounted() {

    this.rules = {
      questionTitle: [v => !!v || 'عنوان سوال را وارد کنید',],
      q1: [v => !!v || 'محتوای گزینه اول را وارد کنید'],
      q2: [v => !!v || 'محتوای گزینه دوم را وارد کنید'],
      q3: [v => !!v || 'محتوای گزینه سوم را وارد کنید'],
      q4: [v => !!v || 'محتوای گزینه چهارم را وارد کنید'],
      Answer: [v => !!v || 'گزینه صحیح را وارد کنید'],
    }


  },
  computed: {
    allQuestions() {
      return this.$store.getters.getAllQuestionsList
    },
    allQuestionsCount() {
      return this.$store.getters.getAllQuestionsCount
    }
  },
  watch: {
    dialog() {
      this.reset()

    },
  },
}
</script>

<style scoped>

</style>