<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!obj" color="green" dark v-bind="attrs" :block="$vuetify.breakpoint.xsOnly" v-on="on">ایجاد درس جدید</v-btn>
        <v-btn v-else dark class="orange" small v-on="on">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }"><v-icon class="white--text" v-bind="attrs" v-on="on">mdi-pencil</v-icon></template>
            <span>ویرایش</span>
          </v-tooltip>
        </v-btn>
      </template>

      <v-card>

        <v-card-title v-if="!obj"><h3>ایجاد درس جدید</h3></v-card-title>
        <v-card-title v-else><h3>ویرایش درس</h3></v-card-title>

        <v-card-text>
          <v-form @submit.prevent="submit" ref="form" class="px-3">

            <v-text-field label="عنوان درس" v-model="lesson.Title" prepend-icon="mdi-folder" :rules="rules.lessonTitleRules"></v-text-field>

            <v-textarea rows="2" label="توضیحات درس" v-model="lesson.Description" prepend-icon="mdi-folder" :rules="rules.lessonDescriptionRules"></v-textarea>

            <v-select
                v-if="lesson.Section"
                v-model="lesson.Section.ID"
                :items="allSections"
                :rules="rules.sectionRules"
                label="بخش"
                :menu-props="{ auto: true, overflowY: true}"
                item-text="Title"
                item-value="ID"
                prepend-icon="mdi-map"
            ></v-select>

            <v-checkbox class="ma-0" hide-details="auto" v-model="lesson.Public" :label="'نمایش عمومی در جستجو'"></v-checkbox>


            <v-btn depressed dark class="green mx-0 mt-3" type="submit">تایید</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "AddCoursePopup",
  mixins: [
    constMethods
  ],
  props: {
    obj: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      lesson: this.obj ? Object.assign({}, this.obj) : {Section: {}},
      dialog: false,
      rules: {},
    }
  },
  methods: {
    submit() {

      if (this.$refs.form.validate()) {
        // this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
        //     () => {
        const data = {
          "data": {
            "SectionID": this.lesson.Section.ID,
            "Title": this.lesson.Title,
            "Description": this.lesson.Description,
            "Public": this.lesson.Public ? 1 : 0,
          },
        }

        if (!this.obj) {//add new lesson
          this.$store.dispatch('addLesson', data).then(() => {
            this.$emit('getList')
          })
        } else {//edit lesson
          data['lessonID'] = this.obj.ID
          this.$store.dispatch('editLesson', data)
        }

        this.dialog = false
        // })

      }
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      this.lesson = this.obj ? Object.assign({}, this.obj) : {Section: {}}
    }
  },
  mounted() {
    this.rules = {
      lessonTitleRules: [v => !!v || 'عنوان درس را وارد کنید', v => (v && v.length >= 5) || 'عنوان درس نباید کمتر از 5 حرف باشد',],
      lessonDescriptionRules: [v => !!v || 'توضیحات درس را وارد کنید', v => (v && v.length >= 5) || 'توضیحات درس نباید کمتر از 5 حرف باشد',],
      sectionRules: [v => !!v || 'بخش را انتخاب کنید',],
    }
  },
  computed: {
    allSections() {
      return this.$store.getters.getAllSectionsList
    }
  },
  watch: {
    dialog() {
      this.reset()
    }
  },
}
</script>

<style scoped>

</style>
