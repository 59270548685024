<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!obj" color="green" dark v-bind="attrs" v-on="on">ایجاد بنر جدید</v-btn>
        <v-btn v-else small dark class="orange" v-bind="attrs" v-on="on">ویرایش</v-btn>
      </template>
      <v-card>

        <v-card-title v-if="!obj"><h3>ایجاد بنر جدید</h3></v-card-title>
        <v-card-title v-else><h3>ویرایش بنر</h3></v-card-title>

        <v-card-text>
          <v-form @submit.prevent="submit" ref="form" class="px-3">
            <v-text-field label="عنوان" v-model.trim="banner.Title" prepend-icon="mdi-folder" :rules="rules.bannerTitleRules"></v-text-field>

            <v-textarea v-model="banner.Description" label="توضیحات" rows="2" prepend-icon="mdi-comment"></v-textarea>

            <!--            <v-radio-group :rules="rules.bannerType" prepend-icon="mdi-map" label="نوع بنر:" v-model="banner.Type" class="mt-0" row>-->
            <!--              <v-radio label="دوره" value=course></v-radio>-->
            <!--              <v-radio label="لینک" value=link></v-radio>-->
            <!--            </v-radio-group>-->

            <v-text-field label="لینک" v-model.trim="banner.Link" prepend-icon="mdi-link-variant" :rules="rules.link"></v-text-field>

            <v-file-input v-model="file" small-chips :clearable="false" accept="image/*" @change="onChangeImage('something', $event)" label="بارگزاری عکس" :rules="rules.file"></v-file-input>

            <p class="ma-0">- نسبت عکس باید ۱۶ به ۹ باشد</p>

            <v-img
                v-if="imagePreview"
                :src="imagePreview"
                ref="imgBanner"
                class="mt-2"
                max-width="250"
                :aspect-ratio="16/9">

              <template v-slot:placeholder>

                <v-skeleton-loader
                    max-width="250"
                    height="140.63"
                    type="image"
                    :aspect-ratio="16/9"
                ></v-skeleton-loader>

              </template>

            </v-img>

            <v-btn depressed dark class="green mx-0 mt-3" type="submit">تایید</v-btn>

          </v-form>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";
import {validation} from "@/mixin/validation";

export default {
  inject: {
    theme: {
      default: {isDark: false},
    },
  },
  name: "AddBanner",
  props: {
    obj: {
      type: Object,
      default: () => {

      }
    }
  },
  mixins: [
    constMethods,
    validation
  ],
  data() {
    return {
      banner: this.obj ? Object.assign({}, this.obj) : {},
      dialog: false,
      rules: {},
      imagePreview: '',
      file: null,
    }
  },
  methods: {
    onChangeImage(_, file) {
      this.createImage(file).then(res => this.imagePreview = res)
    },
    submit() {

      if (this.$refs.form.validate()) {

        const data = {
          data: {
            "Title": this.banner.Title,
            "Description": this.banner.Description,
            "Type": 'course',
            "Link": this.banner.Link
          },
          file: this.file
        }

        if (!this.obj) {//add new banner
          this.$store.dispatch('addBanner', data)
              .then(() => {
                this.$emit('getList')
              })
        } else {//edit banner
          data['bannerID'] = this.obj.ID
          this.$store.dispatch('editBanner', data)
        }

        this.dialog = false
      }
    },
    reset() {

      if (this.$refs.form)
        this.$refs.form.resetValidation()

      this.banner = this.obj ? Object.assign({}, this.obj) : {}
      this.imagePreview = ''
      if (this.obj) {
        this.imagePreview = this.$store.getters.getImageServerURL + this.banner.Banner
        this.file = {}
      } else {
        this.file = null
      }
    }
  },
  mounted() {
    this.rules = {
      bannerTitleRules: [v => !!v || 'عنوان را وارد کنید'],
      bannerType: [v => !!v || 'نوع بنر را انتخاب کنید',],
      link: [v => !!v || 'لینک را وارد کنید', v => this.validURL(v) || 'لینک معتبر نیست'],
      file: [v => !!v || 'عکس بنر را انتخاب کنید']
    }
  },
  watch: {
    dialog() {
      this.reset()
    }
  },
}
</script>

<style scoped>

</style>