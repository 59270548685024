var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[(_vm.checkAccessList({property: 'Name', value: 'addSection'}))?_c('add-section-course-popup',{staticClass:"align-self-end mb-4",on:{"getList":function($event){_vm.getSections(_vm.calculateIndex(_vm.$store.getters.getAllSectionsCount, true, _vm.pageIndex))}}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sections,"options":_vm.options,"server-items-length":_vm.$store.getters.getAllSectionsCount,"loading":_vm.$store.getters.isLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Title",fn:function(ref){
var item = ref.item;
return [_c('name-overflow',{attrs:{"mText":item.Title,"width":70}})]}},{key:"item.Description",fn:function(ref){
var item = ref.item;
return [_c('name-overflow',{attrs:{"mText":item.Description,"width":240}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.checkAccessList({property: 'Name', value: 'editSection'}))?_c('add-section-course-popup',{attrs:{"obj":item}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.checkAccessList({property: 'Name', value: 'deleteSection'}))?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 red",attrs:{"dark":"","small":""},on:{"click":function($event){return _vm.deleteSection(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("حذف")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }