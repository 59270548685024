export const encodeQueryData = (obj) => {

    Object.keys(obj).forEach((key) => {
        obj[key] = obj[key] === 0 || obj[key] === false ? obj[key] + "" : obj[key]
        !obj[key] ? delete obj[key] : null
    })

    return '?' + Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}
// let x = false
// let y = null
// let z = undefined
// let m = ''
// let n = 0