<template>

  <div class="d-flex flex-column">

    <v-row class="mb-4" no-gutters>


      <v-col class="d-flex align-center" cols="12" sm="4" md="2">
        <v-text-field
            v-model="txtSearch"
            label="جستجو"
            placeholder="نام یا شماره همراه"
            outlined
            hide-details
            v-on:keydown.enter="applySearch"
            @input="onSearchTxtChange()"
            dense>

        </v-text-field>
      </v-col>
      <v-col class="d-flex align-center" cols="12" sm="1" md="1">
        <v-btn v-show="txtSearch !== ''" color="green" dark class="mr-2" @click="applySearch">اعمال</v-btn>
      </v-col>
    </v-row>


    <v-data-table
        :headers="headers"
        :items="users"
        :options.sync="options"
        :server-items-length="$store.getters.getAllUsersCount"
        :loading="$store.getters.isLoading"
        class="elevation-1">

      <template v-slot:item.Name="{ item }">
        <name-overflow :mText="item.Name" :width="70"></name-overflow>
      </template>

      <template v-slot:item.Family="{ item }">
        <name-overflow :mText="item.Family" :width="70"></name-overflow>
      </template>

      <template v-if="users.City" v-slot:item.City.Title="{ item }">{{ item.Title }}</template>

      <template v-slot:item.LastLogin="{ item }">{{ convertFormat(item.LastLogin, 'jYYYY/jMM/jDD - HH:mm') }}</template>

      <template v-slot:item.actions="{ item }">

        <div class="d-flex align-center">

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark class="primary ml-2" small v-bind="attrs" v-on="on" v-on:click="getUserCourses(item)">
                <v-icon class="white--text">mdi-book-open-outline</v-icon>
              </v-btn>
            </template>
            <span>دوره ها</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark class="primaryDark" v-bind="attrs" v-on="on" small @click="getUserFamily(item)">
                <v-icon class="white--text">mdi-human-male-female-child</v-icon>
              </v-btn>
            </template>
            <span>اعضای خانواده</span>
          </v-tooltip>


        </div>

      </template>
    </v-data-table>

    <user-courses-popup :dialog="showUserCoursesPopup" @hide="showUserCoursesPopup = false"></user-courses-popup>
    <user-family-popup :dialog="showUserFamilyPopup" @hide="showUserFamilyPopup = false"></user-family-popup>

  </div>
</template>

<script>
import {constMethods} from "@/mixin/constMethods";
import UserCoursesPopup from "@/components/dashboard/sections/systemUsers/UserCoursesPopup";
import UserFamilyPopup from "@/components/dashboard/sections/systemUsers/UserFamilyPopup";
import nameOverflow from "@/components/helper/nameOverflow";
export default {
  name: "SystemUsers",
  components: {
    UserCoursesPopup,
    UserFamilyPopup,
    nameOverflow
  },
  mixins: [
    constMethods
  ],
  data() {
    return {
      options: {},
      headers: [
        {text: 'نام', align: 'center', sortable: false, value: 'Name',},
        {text: 'نام خانوادگی', align: 'center', sortable: false, value: 'Family',},
        {text: 'همراه', align: 'center', sortable: false, value: 'Mobile',},
        {text: 'شهر', align: 'center', sortable: false, value: 'City.Title',},
        {text: 'آخرین ورود', align: 'center', sortable: false, value: 'LastLogin'},
        {text: '', align: 'center', sortable: false, value: 'actions'},
      ],
      txtSearch: '',
      showUserCoursesPopup: false,
      showUserFamilyPopup: false,
    }
  },
  methods: {
    getSystemUsers(index) {
      const query = {
        q: this.txtSearch,
        index,
        limit: this.$store.getters.getLimit
      }
      this.$store.dispatch('getUsers', query)
    },
    getUserCourses(user) {
      this.$store.dispatch('getUserCourses', user).then(() => {
        this.showUserCoursesPopup = true
      })
    },
    getUserFamily(user) {
      if (!user.FamilyCount || user.FamilyCount !== 0) {
        this.$store.dispatch('getUserFamily', user).then(() => {
          this.showUserFamilyPopup = true
        })
      } else {
        this.popup({type: 'error', title: 'این کاربر اعضای خانواده ندارد!', button: 2})
      }
    },
    applySearch() {
      if (this.txtSearch !== '')
        this.getSystemUsers(0)
    },
    onSearchTxtChange() {
      if (this.txtSearch === '')
        this.getSystemUsers(0)
    }
  },
  computed: {
    users() {
      return this.$store.getters.getUsersList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getSystemUsers((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>