import router from "@/plugins/router/router";
import {encodeQueryData} from "@/plugins/resource/queryReq";
import ReqJson from "@/plugins/resource/reqJson";
import {store} from "@/plugins/store/store";

const state = {
    examinations: [],
    allExaminationsCount: 0,
    examinationTypes: [],
    allExaminations: [],
    examination: {},
    sectionExaminations: [],
    allSectionExaminationsCount: 0,
};

const getters = {
    getExaminationsList(state) {
        return state.examinations
    },
    getAllExaminationsCount(state) {
        return state.allExaminationsCount
    },
    getExaminationTypesList(state) {
        return state.examinationTypes
    },
    getAllExaminationsList(state) {
        return state.allExaminations
    },
    getExamination(state) {
        return state.examination
    },
    getSectionExaminationsList(state) {
        return state.sectionExaminations
    },
    getAllSectionExaminationsCount(state) {
        return state.allSectionExaminationsCount
    },
};

const mutations = {
    setExaminationsList(state, examinations) {
        state.examinations = examinations
    },
    setAllExaminationsCount(state, allExaminationsCount) {
        state.allExaminationsCount = allExaminationsCount
    },
    setExaminationTypes(state, examinationTypes) {
        state.examinationTypes = examinationTypes
    },
    setAllExaminationsList(state, allExaminations) {
        state.allExaminations = allExaminations
    },
    setExamination(state, examination) {
        state.examination = examination
    },
    setSectionExaminationsList(state, sectionExaminations) {
        state.sectionExaminations = sectionExaminations
    },
    setAllSectionExaminationsCount(state, allSectionExaminationsCount) {
        state.allSectionExaminationsCount = allSectionExaminationsCount
    },
};

const actions = {

    getExaminations({commit}, query) {

        this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/exams${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllExaminationsCount', response.meta.TotalCount)
                commit('setExaminationsList', response.result)

            })
    },

    getAllExaminations({getters, commit, dispatch}, query) {
        if (query.index === 0)
            commit('setAllExaminationsList', [])

        this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/exams${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                getters.getAllExaminationsList.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    query.index = getters.getAllExaminationsList.length
                    dispatch('getAllExaminations', query)
                }

            })
    },

    getSingleExamination({commit}, examinationID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Examination/${examinationID}`, method: 'get'})
                .then((response) => {
                    commit('setExamination', response.result[0])
                    resolve(response.result[0])
                })
        })
    },

    addExamination({getters, commit, dispatch}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: 'Examination', body: new ReqJson(data.data).reqJS(), method: 'post'})
                .then(() => {
                    commit("setAllExaminationsCount", getters.getAllExaminationsCount + 1)
                    resolve()

                    if (getters.getAllExaminationsList.length !== 0) {
                        dispatch('getAllExaminations', {index: 0, limit: store.getters.getLimit30,})
                    }
                })
        })
    },

    editExamination({getters, dispatch}, data) {

        this.dispatch('sendRequest', {url: `Examination/${data.examinationID}`, body: new ReqJson(data.data).reqJS(), method: 'put'})
            .then(() => {

                dispatch('getSingleExamination', data.examinationID).then((res) => {

                    const index = getters.getExaminationsList.findIndex(item => item.ID === data.examinationID);
                    if (index !== -1) {
                        getters.getExaminationsList.splice(index, 1, res)
                    }

                    const index2 = getters.getAllExaminationsList.findIndex(item => item.ID === data.examinationID);
                    if (index2 !== -1) {
                        getters.getAllExaminationsList.splice(index2, 1, res)
                    }
                })

            })
    },

    deleteExamination({getters, commit}, examinationID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Examination/${examinationID}`, method: 'delete'})
                .then(() => {
                    commit("setAllExaminationsCount", getters.getAllExaminationsCount - 1)
                    resolve()

                    const index2 = getters.getAllExaminationsList.findIndex(item => item.ID === examinationID);
                    if (index2 !== -1) {
                        getters.getAllExaminationsList.splice(index2, 1)
                    }
                })
        })
    },

    getExaminationTypes({getters, commit, dispatch}, query) {
        if (query.index === 0)
            commit('setExaminationTypes', [])

        this.dispatch('sendRequest', {url: `ExamTypes${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                getters.getExaminationTypesList.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    query.index = getters.getExaminationTypesList.length
                    dispatch('getExaminationTypes', query)
                }

            })
    },

    getSectionExaminations({commit}, query) {
        if (query.index == 0) {
            commit('setAllSectionExaminationsCount', 0)
            commit('setSectionExaminationsList', [])
        }

        this.dispatch('sendRequest', {url: `Sections/${router.currentRoute.params.sectionID}/exams${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllSectionExaminationsCount', response.meta.TotalCount)
                commit('setSectionExaminationsList', response.result)

            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
