import {encodeQueryData} from "@/plugins/resource/queryReq";
import router from "@/plugins/router/router";

const state = {
    enrolledUsers: [],
    allEnrolledUsersCount: 0,
};

const getters = {
    getEnrolledUsersList(state) {
        return state.enrolledUsers
    },
    getAllEnrolledUsersCount(state) {
        return state.allEnrolledUsersCount
    },
};

const mutations = {
    setEnrolledUsersList(state, enrolledUsers) {
        state.enrolledUsers = enrolledUsers
    },
    setAllEnrolledUsersCount(state, allEnrolledUsersCount) {
        state.allEnrolledUsersCount = allEnrolledUsersCount
    },
};

const actions = {

    getEnrolledUsers({commit}, query) {

        if (query.index == 0) {
            commit('setAllEnrolledUsersCount', 0)
            commit('setEnrolledUsersList', [])
        }

        this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/enrolledUsers${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllEnrolledUsersCount', response.meta.TotalCount)
                commit('setEnrolledUsersList', response.result)

            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
