<template>
  <nav>

    <v-app-bar class="elevation-3" app>
      <v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text d-flex pa-0">
        <v-divider vertical class="mx-2 my-auto" style="height: 30px"></v-divider>
        <v-icon v-if="showBackBtn($route)" style="cursor: pointer" @click="$router.go(-1)">mdi-arrow-right</v-icon>
        <v-divider v-if="showBackBtn($route)" vertical class="mx-2 my-auto" style="height: 30px"></v-divider>
        <v-icon style="cursor: pointer" @click="goToHomePage">mdi-home</v-icon>
        <v-divider vertical class="mx-2 my-auto" style="height: 30px"></v-divider>
        <h1 class="subtitle-1 grey--text pa-2">{{ setHeaderTitle($route) }}</h1>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="text-center">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="grey" text v-bind="attrs" v-on="on">
              <v-icon>mdi-chevron-down</v-icon>
              <span>منو</span>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, index) in links" :key="index" :to="{name:item.route}">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-btn text color="pink" @click="goToHomePage">
        <span>صفحه اصلی</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" right class="primaryLight darken-3" app>

      <span class="font-weight-bold text-center d-block white--text mt-10">دوره {{ course.Title }}</span>


      <input type="file" accept="image/*" ref="fileInput" @change="onChangeImage($event)" style="display: none">
      <div class="d-flex flex-column align-center mt-5">
        <v-avatar size="80" class="grey lighten-2">
          <img v-if="course.Cover" :src="$store.getters.getImageServerURL + course.Cover" alt="a">
          <v-icon v-else class="white--text">mdi-plus-thick</v-icon>
        </v-avatar>
        <v-btn small outlined color="grey" class="mt-2" @click="$refs.fileInput.click()">
          <v-icon small>mdi-upload</v-icon>
          <span>بارگزاری عکس دوره</span>
        </v-btn>
      </div>

      <v-list nav dense class="mt-4">
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item v-for="(link,index) in links" :key="index" :to="{name: link.route}">
            <v-list-item-icon>
              <!--              <v-icon class="white&#45;&#45;text">{{ link.icon }}</v-icon>-->
              <v-icon class="white--text">mdi-{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "Navbar",
  components: {},
  mixins: [
    constMethods
  ],
  data() {
    return {
      rn: this.$store.state.rn,
      drawer: true,
      items: [
        {icon: 'book-open-outline', text: 'بخش ها', route: this.$store.state.rn.sections},
        {icon: 'book-open-page-variant', text: 'دروس', route: this.$store.state.rn.lessons},
        {icon: 'page-layout-body', text: 'امتحانات', route: this.$store.state.rn.exams},
        // {icon: 'typewriter', text: 'تمرین', route: this.$store.state.rn.exercise},
        {icon: 'account-multiple', text: 'شرکت کنندگان دوره', route: this.$store.state.rn.participants},
        {icon: 'face-agent', text: 'پشتیبان', route: this.$store.state.rn.supports},
      ],
      snackbar: false
    }
  },
  methods: {
    setHeaderTitle(route) {
      let n = route.name
      let q = route.query.name
      let s = this.$store.state.rn
      switch (n) {
        case s.sections:
          return 'بخش ها'
        case s.sectionLessons:
          return `بخش ${q} / دروس`
        case s.sectionExaminations:
          return `بخش ${q} / امتحانات`
        case s.lessons:
          return 'دروس'
        case s.exams:
          return 'امتحانات'
        case s.examsResults:
          return `امتحانات / ${q} (${route.query.examType})`
        case s.exercise:
          return 'تمرین'
        case s.participants:
          return 'شرکت کنندگان دوره'
        case s.supports:
          return 'پشتیبان'
      }
    },
    showBackBtn(route) {
      let n = route.name
      let s = this.$store.state.rn
      return n !== s.sections && n !== s.lessons && n !== s.exams && n !== s.exercise && n !== s.participants && n !== s.supports;
    },
    onChangeImage(event) {
      this.$store.dispatch('uploadCourseCover', event.target.files[0])
    },
    goToHomePage() {
      this.$router.replace({name: this.$store.state.rn.courses})
    },
  },
  computed: {
    course() {
      return this.$store.getters.getCourse
    },
    links() {
      return this.items.filter(item => {
        return (item.route === this.rn.sections && this.checkAccessList({property: 'Name', value: 'course_sectionList'}))
            || (item.route === this.rn.lessons && this.checkAccessList({property: 'Name', value: 'course_lessonsList'}))
            || (item.route === this.rn.exams && this.checkAccessList({property: 'Name', value: 'course_examsList'}))
            || item.route === this.rn.exercise
            || item.route === this.rn.participants
            || (item.route === this.rn.supports && this.checkAccessList({property: 'Name', value: 'course_supportsList'}))
      })
    }
  }
}
</script>

<style scoped>

</style>