<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn dark class="mr-2 green-bg" small v-bind="attrs" v-on="on">مشاهده پیوست ها</v-btn>
      </template>

      <v-card>

        <v-card-title><h3>مشاهده پیوست ها</h3></v-card-title>

        <v-card-text>

          <div class="d-inline" v-for="(item, i) in userExam.Files" :key="i">
            <v-chip class="ma-1" style="cursor: pointer" @click="onOpenNewTab(item.FileID)">مشاهده {{ item.Title }}</v-chip>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "ManageTagsPopup",
  mixins: [
    constMethods
  ],
  props: {
    userExam: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {},
}
</script>

<style scoped>

</style>