import ReqJson from "@/plugins/resource/reqJson";
import {encodeQueryData} from "@/plugins/resource/queryReq";

const state = {
    banners: [],
    allBannersCount: 0,
    allBanners: [],
    banner: {},
};

const getters = {
    getBannersList(state) {
        return state.banners
    },
    getAllBannersCount(state) {
        return state.allBannersCount
    },
    getAllBannersList(state) {
        return state.allBanners
    },
    getBanner(state) {
        return state.banner
    },
};

const mutations = {
    setBannersList(state, banners) {
        state.banners = banners
    },
    setAllBannersList(state, allBanners) {
        state.allBanners = allBanners
    },
    setAllBannersCount(state, allBannersCount) {
        state.allBannersCount = allBannersCount
    },
    setBanner(state, banner) {
        state.banner = banner
    },
    refreshBanner(state, banner) {
        const index = state.banners.findIndex(item => item.ID === banner.ID);
        if (index !== -1) {
            state.banners.splice(index, 1, banner)
        }

        const index2 = state.banners.findIndex(item => item.ID === banner.ID);
        if (index2 !== -1) {
            state.banners.splice(index2, 1, banner)
        }
    }
};

const actions = {

    getBanners({commit}, query) {

        this.dispatch('sendRequest', {url: `Sliders${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllBannersCount', response.meta.TotalCount)
                commit('setBannersList', response.result)

            })
    },

    getAllBanners({getters, commit, dispatch}, query) {
        if (query.index === 0)
            commit('setAllBannersList', [])

        this.dispatch('sendRequest', {url: `Sliders${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                getters.getAllBannersList.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    query.index = getters.getAllBannersList.length
                    dispatch('getAllBanners', query)
                }

            })
    },

    getSingleBanner({commit}, bannerID) {
        commit('setBanner', {})
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Sliders/${bannerID}`, method: 'get'})
                .then(response => {
                    commit('setBanner', response.result[0])
                    resolve(response.result[0])
                })
        })
    },

    addBanner({getters, commit, dispatch}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: 'Sliders', body: new ReqJson(data.data).reqJS(), method: 'post'})
                .then((response) => {
                    commit("setAllBannersCount", getters.getAllBannersCount + 1)

                    // if (getters.getAllBannersList.length !== 0) {
                    //     dispatch('getAllBanners', {index: 0, limit: store.getters.getLimit30,})
                    // }
                    const uploadData = {
                        bannerID: response.result[0].ID,
                        file: data.file
                    }
                    dispatch('uploadBanner', uploadData).then(() => {
                        resolve()
                    })
                })
        })
    },

    editBanner({commit, dispatch}, data) {

        this.dispatch('sendRequest', {url: `Sliders/${data.bannerID}`, body: new ReqJson(data.data).reqJS(), method: 'put'})
            .then(() => {

                if (!data.file.name) {//اگر زمانی که ویرایش کرد ولی عکس بنر رو عوض نکرد دیگه بی جهت آپلود نکند
                    dispatch('getSingleBanner', data.bannerID).then(res => commit('refreshBanner', res))
                } else {
                    const uploadData = {
                        bannerID: data.bannerID,
                        file: data.file
                    }
                    dispatch('uploadBanner', uploadData)
                        .then(() => dispatch('getSingleBanner', data.bannerID).then(res => commit('refreshBanner', res)))
                }


            })
    },

    deleteBanner({getters, commit}, bannerID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Sliders/${bannerID}`, method: 'delete'})
                .then(() => {
                    commit("setAllBannersCount", getters.getAllBannersCount - 1)
                    resolve()

                    const index2 = getters.getAllBannersList.findIndex(item => item.ID === bannerID);
                    if (index2 !== -1) {
                        getters.getAllBannersList.splice(index2, 1)
                    }
                })
        })
    },

    uploadBanner(_, data) {
        return new Promise(resolve => {

            const fd = new FormData();

            fd.append('image', data.file, data.file.name);

            this.dispatch('sendRequest', {url: `Sliders/${data.bannerID}/file`, body: fd, method: 'post'})
                .then(() => {
                    resolve()
                })
        })
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
