<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn dark class="mr-2 primaryLight" small v-bind="attrs" @click="getAllFiles()" v-on="on">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }"><v-icon class="white--text" v-bind="attrs" v-on="on">mdi-attachment</v-icon></template>
            <span>پیوست ها</span>
          </v-tooltip>
        </v-btn>
      </template>

      <v-card>

        <v-card-title><h3>مدیریت پیوست ها</h3></v-card-title>

        <v-card-text>

          <div class="d-inline" v-for="(item,i) in allFileTypes" v-bind:key="i">
            <v-chip class="ma-1" close color="primary" outlined @click:close="removeFileType(item)">{{ item.Title }}</v-chip>
          </div>
          <v-form @submit.prevent="addFileType" ref="fileType" class="px-3">

            <v-text-field label="عنوان پیوست" v-model.trim="fileTypeTitle" prepend-icon="mdi-folder" :rules="fileTypeTitleRules"></v-text-field>

            <v-select
                v-model="fileType"
                :items="fileTypes"
                :rules="fileTypeRules"
                item-text="titleFa"
                item-value="title"
                :menu-props="{ auto: true, overflowY: true}"
                label="نوع پیوست"
                prepend-icon="mdi-map"
            ></v-select>


            <v-btn depressed dark class="green mx-0 mt-5 mr-4" type="submit">افزودن</v-btn>


          </v-form>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "ManageTagsPopup",
  mixins: [
    constMethods
  ],
  props: {
    exam: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      dialog: false,
      fileTypes: [
        {title: 'sound', titleFa: 'صدا'},
        {title: 'video', titleFa: 'ویدیو'},
        {title: 'image', titleFa: 'تصویر'},
        {title: 'other', titleFa: 'سایر'},
      ],
      fileTypeTitle: '',
      fileType: '',
      fileTypeTitleRules: [v => !!v || 'عنوان پیوست را وارد کنید',],
      fileTypeRules: [v => !!v || 'نوع پیوست را وارد کنید',],
    }
  },
  methods: {
    getAllFiles() {
      this.$store.commit('setAllFileTypesList', [])
      this.$store.dispatch('getAllFileTypes', {examID: this.exam.ID, query: {index: 0, limit: this.$store.getters.getLimit30}})
    },
    addFileType() {
      if (this.$refs.fileType.validate()) {
        const data = {
          examID: this.exam.ID,
          data: {
            Title: this.fileTypeTitle,
            Type: this.fileType,
          }
        }
        this.$store.dispatch('addFileType', data)

        this.reset()
      }
    },
    removeFileType(item) {
      const data = {
        examID: this.exam.ID,
        questionID: item.ID
      }
      this.$store.dispatch('deleteFileType', data)

      this.reset()
    },
    reset() {
      if (this.$refs.fileType)
        this.$refs.fileType.resetValidation()
      this.fileTypeTitle = ''
      this.fileType = ''
    }
  },
  computed: {
    allFileTypes() {
      return this.$store.getters.getAllFileTypesList
    },
    allFileTypesCount() {
      return this.$store.getters.getAllFileTypesCount
    }
  },
  watch: {
    dialog(n) {
      if (n) {
        this.reset()
      }
    }
  },
}
</script>

<style scoped>

</style>