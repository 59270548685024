<template>
<div class="splash">
  لطفا منتظر بمانید
</div>
</template>

<script>
export default {
name: "splash"
}
</script>

<style scoped>
.splash{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>