<template>

  <v-row>
    <v-col cols="12" sm="6" md="4" class="pt-0" v-for="(item,index) in items" :key="index">
      <v-card class="text-center mb-3">
        <v-card-text>
          <p class="subtitle-1 text--primary">{{ item.name }}</p>

          <v-icon x-large>{{ item.icon }}</v-icon>

        </v-card-text>

        <v-card-actions>
          <v-btn outlined color="primary" :to="{name : item.route}">
            <span>ورود</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: "BasicSettings",
  data() {
    return {
      items: [
        {name: 'برچسب ها', route: this.$store.state.rn.manageTags, icon: 'mdi-music-accidental-sharp'},
        {name: 'رده سنی', route: this.$store.state.rn.manageAgeCategories, icon: 'mdi-counter'},
        {name: 'دسته بندی', route: this.$store.state.rn.manageCategories, icon: 'mdi-shape-outline'},
        {name: 'بنر', route: this.$store.state.rn.manageBanners, icon: 'mdi-seal-variant'},
      ]
    }
  }
}
</script>

<style scoped>

</style>