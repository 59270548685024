<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        @click:outside="$emit('hide')"
        v-on:keydown.esc="$emit('hide')"
        scrollable
        max-width="700px">
      <!--      <template v-slot:activator="{ on, attrs }">-->
      <!--        <v-icon style="cursor: pointer" v-bind="attrs" v-on="on">mdi-pencil</v-icon>-->
      <!--      </template>-->
      <v-card>
        <v-card-title>ویرایش محتوا</v-card-title>
        <v-divider class="mb-5"></v-divider>
        <v-form @submit.prevent="onEditContent" ref="form">

          <v-card-text style="height: 300px;">
            <v-text-field label="عنوان محتوا" v-model="content.Title" :rules="rules.contentTitle" prepend-icon="mdi-folder"></v-text-field>

            <v-textarea rows="6" label="توضیحات" v-model="content.Text" :rules="rules.contentText" prepend-icon="mdi-folder"></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="green darken-1" dark type="submit">
              تایید و ویرایش
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "EditContentDialog",
  mixins: [
    constMethods
  ],
  props: {
    obj: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: this.obj ? Object.assign({}, this.obj) : {ContentType: {}},

      rules: [],
    }
  },
  methods: {
    onEditContent() {
      if (this.$refs.form.validate()) {
        const data = {
          contentID: this.content.ID,
          data: {
            "Title": this.content.Title,
            "ContentTypeID": this.content.ContentType.ID,
            "LessonID": this.content.LessonID,
            "Text": this.content.Text,
            "FileName": "",
          }
        }
        this.$store.dispatch('editContent', data).then(() => {
          this.$emit('hide')
        })
      }
    },
  },
  watch: {
    dialog(n) {
      if (n) {
        this.content = (this.obj && Object.keys(this.obj).length !== 0) ? Object.assign({}, this.obj) : {ContentType: {}}
        this.rules = {
          contentTitle: [v => !!v || 'عنوان محتوا را وارد کنید'],
          contentText: [v => !!v || this.content.ContentType.Title !== 'Text' || 'متن را وارد کنید'],
        }
      }
    },
  },
}
</script>

<style scoped>

</style>