<template>

  <v-card>
    <v-card-text>

      <div class="mt-8 d-flex flex-column flex-sm-row">
        <input type="file" accept="image/*" ref="fileInput" @change="onChangeImage($event)" style="display: none">

        <div class="d-flex flex-column align-center align-sm-start mb-4 mb-sm-0">
          <v-avatar color="primary" rounded size="100">
            <img v-if="profile.Avatar" :src="$store.getters.getImageServerURL + profile.Avatar" alt="a">
            <v-icon v-else x-large dark>mdi-account-circle</v-icon>
          </v-avatar>
          <v-btn small outlined color="grey" class="mt-2 d-block" width="100" @click="$refs.fileInput.click()">
            <v-icon small>mdi-upload</v-icon>
            <span>بارگزاری</span>
          </v-btn>
        </div>

        <v-form ref="form">
          <v-row class="mr-sm-4">

            <v-col cols="12" sm="6" lg="4" class="pt-1">
              <v-text-field v-model="profile.Name" :rules="rules.nameRules" dense  outlined label="نام"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" lg="4" class="pt-1">
              <v-text-field v-model="profile.Family" :rules="rules.familyRules" dense  outlined label="نام خانوادگی"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" lg="4" class="pt-1">
              <v-select v-if="profile.Province" v-model="profile.Province.LinkID" item-text="Title" item-value="LinkID" :items="provinces"
                        label="استان محل سکونت" dense @change="onGetProvince($event)" :rules="rules.provinceRules" :menu-props="{ auto: true, overflowY: true }" outlined></v-select>
            </v-col>

            <v-col cols="12" sm="6" lg="4" class="pt-1">
              <v-select v-if="profile.City" v-model="profile.City.ID" :rules="rules.cityRules" item-text="Title" item-value="ID" :items="cities"
                        label="شهر محل سکونت" dense :menu-props="{ auto: true, overflowY: true }" outlined></v-select>
            </v-col>

            <p></p>
            <v-col cols="12" sm="6" lg="4" class="pt-1">

              <v-text-field readonly outlined dense id="date" label="تاریخ تولد" v-model="formattedDate" :rules="rules.birthDateRules"></v-text-field>

            </v-col>

          </v-row>
        </v-form>
      </div>

      <div class="text-left">
        <v-btn class="green mt-4" dark @click="submitProfile">ثبت پروفایل</v-btn>
      </div>

    </v-card-text>


    <date-picker
        type="date"
        v-model="profile.BirthDate"
        format="YYYY-MM-DD"
        element="date"/>

  </v-card>

</template>

<script>
import {constMethods} from "@/mixin/constMethods";
import momentJalali from "moment-jalaali";

export default {
  name: "Profile",
  mixins: [
    constMethods
  ],
  data() {
    return {
      rules: {},
    }
  },
  methods: {
    onChangeImage(event) {
      this.$store.dispatch('uploadAvatar', event.target.files[0])
    },
    onGetProvince(provinceLinkID) {
      this.profile.City = {}
      this.$store.dispatch('getCities', provinceLinkID)
    },
    submitProfile() {
      if (this.$refs.form.validate()) {
        // this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
        //     () => {
              const data = {
                "CityID": this.profile.City.ID,
                "Name": this.profile.Name,
                "Family": this.profile.Family,
                "BirthDate": this.convertJalaliToGregorian(this.profile.BirthDate)
              }

              this.$store.dispatch('setProfile', data)
            // })
      }
    }
  },
  mounted() {

    this.rules = {
      nameRules: [v => !!v || 'نام را وارد کنید'],
      familyRules: [v => !!v || 'نام خانوادگی را وارد کنید'],
      provinceRules: [v => !!v || 'استان محل سکونت را وارد کنید'],
      cityRules: [v => !!v || 'شهر محل سکونت را وارد کنید'],
      birthDateRules: [v => !!v || 'تاریخ تولد را وارد کنید'],
    }
  },
  computed: {
    profile() {
      return this.$store.getters.getMProfile
    },
    provinces() {
      return this.$store.getters.getProvincesList
    },
    cities() {
      return this.$store.getters.getCitiesList
    },
    formattedDate() {
      return this.profile.BirthDate ? momentJalali(this.profile.BirthDate).format('jYYYY/jMM/jDD') : ''
    },
  },
}
</script>

<style scoped>

</style>