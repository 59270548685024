import {encodeQueryData} from "@/plugins/resource/queryReq";
import ReqJson from "@/plugins/resource/reqJson";
import {constMethods} from "@/mixin/constMethods";

const state = {
    contents: [],
    allContentsCount: 0,
    contentTypes: [],
    allContents: [],
    newContentID: 0,
};

const getters = {
    getContentsList(state) {
        return state.contents
    },
    getAllContentsCount(state) {
        return state.allContentsCount
    },
    getContentTypesList(state) {
        return state.contentTypes
    },
    getAllContentsList(state) {
        return state.allContents
    },
    getNewContentID(state) {
        return state.newContentID
    },
};

const mutations = {
    setContentsList(state, contents) {
        state.contents = contents
    },
    setAllContentsCount(state, allContentsCount) {
        state.allContentsCount = allContentsCount
    },
    setContentTypes(state, contentTypes) {
        state.contentTypes = contentTypes
    },
    setAllContentsList(state, allContents) {
        state.allContents = allContents
    },
    setNewContentID(state, newContentID) {
        state.newContentID = newContentID
    },
    array_move(state, indexs) {
        if (indexs.new >= state.allContents.length) {
            let k = indexs.new - state.allContents.length + 1;
            while (k--) {
                state.allContents.push(undefined);
            }
        }
        state.allContents.splice(indexs.new, 0, state.allContents.splice(indexs.old, 1)[0]);
        return state.allContents; // for testing
    }
};

const actions = {

    getContents({commit}, data) {
        if (data.query.index == 0) {
            commit('setAllContentsCount', 0)
            commit('setContentsList', [])
        }

        this.dispatch('sendRequest', {url: `Lessons/${data.lessonID}/contents${encodeQueryData(data.query)}`, method: 'get'})
            .then(response => {

                if (data.query.index == 0 && response.meta)
                    commit('setAllContentsCount', response.meta.TotalCount)
                commit('setContentsList', response.result)

            })
    },

    getAllContents({getters, commit, dispatch}, data) {
        if (data.query.index === 0)
            commit('setAllContentsList', [])

        this.dispatch('sendRequest', {url: `Lessons/${data.lessonID}/contents${encodeQueryData(data.query)}`, method: 'get'})
            .then(response => {

                getters.getAllContentsList.push(...response.result)

                if (response.result.length === getters.getLimit) {
                    data.query.index = getters.getAllContentsList.length
                    dispatch('getAllContents', data)
                }

            })
    },

    getOneContent({getters, commit}, contentID) {

        this.dispatch('sendRequest', {url: `Contents/${contentID}`, method: 'get'})
            .then(response => {
                commit('setNewContentID', 0)
                constMethods.methods.popup({type: 'success', title: 'محتوای جدید اضافه شد!', button: 1, progress: true, timer: 4000, outSideClick: false, escapeKey: false}).then(
                    () => {
                        getters.getContentsList.push(response.result[0])
                        getters.getAllContentsList.push(response.result[0])
                        commit("setAllContentsCount", getters.getAllContentsCount + 1)
                    })
            })
    },

    async addContent({dispatch}, data) {

        const response = await this.dispatch('sendRequest', {url: `Lessons/${data.lessonID}/addContent`, body: new ReqJson(data.data).reqJS(), method: 'post'})

        if (data.contentType !== 'Text') {
            const uploadData = {
                contentID: response.result.ID,
                file: data.file
            }
            dispatch('uploadContent', uploadData)
        } else {
            dispatch('getOneContent', response.result.ID)
        }

        return response.result.ID
    },

    editContent({getters}, data) {
        return new Promise(resolve => {

            this.dispatch('sendRequest', {url: `Contents/${data.contentID}`, body: new ReqJson(data.data).reqJS(), method: 'put'})
                .then((response) => {

                    const index = getters.getAllContentsList.findIndex(item => item.ID === data.contentID);
                    if (index !== -1) {
                        getters.getAllContentsList.splice(index, 1, response.result[0])
                    }

                    constMethods.methods.toast({type: 'success', title: 'محتوا ویرایش شد', timer: 3000})
                    resolve()

                })
        })
    },

    deleteContent({getters, commit}, contentID) {

        this.dispatch('sendRequest', {url: `Contents/${contentID}`, method: 'delete'})
            .then(() => {
                constMethods.methods.toast({type: 'success', title: 'محتوا حذف شد', timer: 3000})

                const index = getters.getContentsList.findIndex(item => item.ID === contentID);
                if (index !== -1) {
                    commit("setAllContentsCount", getters.getAllContentsCount - 1)
                    getters.getContentsList.splice(index, 1)
                }

                const index2 = getters.getAllContentsList.findIndex(item => item.ID === contentID);
                if (index2 !== -1) {
                    getters.getAllContentsList.splice(index2, 1)
                }

            })
    },

    reorderContent({getters, commit}, data) {

        this.dispatch('sendRequest', {url: `Contents/${data.contentID}/reOrder/${data.reorderType}`, method: 'get'})
            .then(() => {

                const index = getters.getAllContentsList.findIndex(item => item.ID === data.contentID);

                if (index !== -1)
                    commit('array_move', {old: index, new: data.reorderType === 1 ? index + 1 : index - 1})

                constMethods.methods.toast({type: 'success', title: 'جایگاه محتوا تغییر کرد', timer: 3000})
            })
    },

    getContentTypes({getters, commit, dispatch}, query) {
        if (query.index === 0)
            commit('setContentTypes', [])

        this.dispatch('sendRequest', {url: `ContentTypes${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                getters.getContentTypesList.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    query.index = getters.getContentTypesList.length
                    dispatch('getContentTypes', query)
                }

            })
    },

    uploadContent({dispatch}, data) {

        const fd = new FormData();

        fd.append('image', data.file, data.file.name);

        this.dispatch('sendRequest', {url: `Contents/${data.contentID}/file`, body: fd, method: 'post'})
            .then(() => {

                dispatch('getOneContent', data.contentID)

            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
