<template>

  <div class="d-flex flex-column">

    <v-row class="mb-4" no-gutters>
      <v-col cols="12" sm="5" md="3" order="2" order-sm="1">
        <v-select v-model="selectedSectionID" @change="onSelectSection" :items="allSections" item-text="Title" item-value="ID" hide-details="auto" label="همه بخش ها" dense solo :menu-props="{ auto: true, overflowY: true, closeOnContentClick: true}">

          <template v-slot:prepend-item>
            <v-list-item @click="onSelectSection(0)">
              <v-list-item-content>
                <v-list-item-title>همه بخش ها</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

        </v-select>
      </v-col>
      <v-col cols="12" sm="5" md="3" class="mr-0 mr-sm-auto text-left mb-4 mb-sm-0" order="1" order-sm="2">
        <add-lesson-course-popup v-if="checkAccessList({property: 'Name', value: 'addLesson'})"
                                 @getList="getLessons(calculateIndex($store.getters.getAllLessonsCount, true, pageIndex))">
        </add-lesson-course-popup>
      </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="lessons"
        :options.sync="options"
        :server-items-length="$store.getters.getAllLessonsCount"
        :loading="$store.getters.isLoading"
        class="elevation-1">

      <template v-slot:item.Title="{ item }">
        <name-overflow :mText="item.Title" :width="70"></name-overflow>
      </template>

      <template v-if="lessons.Section" v-slot:item.Section.Title="{ item }">
        <name-overflow :mText="item.Section.Title" :width="70"></name-overflow>
      </template>

      <template v-slot:item.Description="{ item }">
        <name-overflow :mText="item.Description" :width="240"></name-overflow>
      </template>

      <template v-slot:item.actions="{ item }">
        <add-lesson-course-popup :obj="item" v-if="checkAccessList({property: 'Name', value: 'editLesson'})"></add-lesson-course-popup>
        <mange-content-popup :lesson="item" v-if="checkAccessList({property: 'Name', value: 'lessonContents'})"></mange-content-popup>
        <manage-tags-popup :lesson="item" v-if="checkAccessList({property: 'Name', value: 'lessonTags'})"></manage-tags-popup>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark class="mr-2 red" v-bind="attrs" v-on="on" small @click="deleteLesson(item)" v-if="checkAccessList({property: 'Name', value: 'deleteLesson'})">
              <v-icon class="white--text">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>حذف</span>
        </v-tooltip>
      </template>
    </v-data-table>

  </div>

</template>

<script>
import {constMethods} from "@/mixin/constMethods";
import AddLessonCoursePopup from "@/components/dashboard/sections/courses/course/sections/lessons/AddLessonCoursePopup";
import MangeContentPopup from "@/components/dashboard/sections/courses/course/sections/lessons/manageContent/MangeContentPopup";
import ManageTagsPopup from "@/components/dashboard/sections/courses/course/sections/lessons/ManageTagsPopup";
import nameOverflow from "@/components/helper/nameOverflow";

export default {
  name: "Lessons",
  mixins: [
    constMethods
  ],
  components: {
    'addLessonCoursePopup': AddLessonCoursePopup,
    'mangeContentPopup': MangeContentPopup,
    'manageTagsPopup': ManageTagsPopup,
    nameOverflow,
  },
  data: () => ({
    pageIndex: 0,
    selectedSectionID: 0,
    options: {},
    headers: [
      {text: 'نام درس', align: 'center', sortable: false, value: 'Title',},
      {text: 'توضیحات', align: 'center', sortable: false, value: 'Description'},
      {text: 'بخش', align: 'center', sortable: false, value: 'Section.Title'},
      {text: 'عملیات', align: 'center', value: 'actions', sortable: false},
    ],
  }),
  methods: {
    onSelectSection(sectionID) {
      this.selectedSectionID = sectionID
      this.getLessons(0)
    },
    getLessons(index) {
      this.pageIndex = index

      const query = {
        index: index,
        limit: this.$store.getters.getLimit,
        sectionID: this.selectedSectionID === 0 ? null : this.selectedSectionID
      }
      this.$store.dispatch('getLessons', query)
    },
    deleteLesson(item) {
      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {
            this.$store.dispatch('deleteLesson', item.ID).then(
                () => {
                  this.getLessons(this.calculateIndex(this.$store.getters.getAllLessonsCount, false, this.pageIndex))
                })
          })
    }
  },
  mounted() {
    if (this.$store.getters.getAllSectionsList.length === 0)
      this.$store.dispatch('getAllSections', {index: 0, limit: this.$store.getters.getLimit30,})
  },
  computed: {
    lessons() {
      return this.$store.getters.getLessonsList
    },
    allSections() {
      return this.$store.getters.getAllSectionsList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getLessons((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>
