import {store} from "@/plugins/store/store";

const rn = store.state.rn

import Splash from "@/components/splash/Splash";
import Login from "@/components/login/Login";
import Dashboard from "@/components/dashboard/Dashboard";
import Courses from "@/components/dashboard/sections/courses/Courses";
import ArchivedCourses from "@/components/dashboard/sections/archivedCourses/ArchivedCourses";
import DraftCourses from "@/components/dashboard/sections/draftCourses/DraftCourses";
import SystemUsers from "@/components/dashboard/sections/systemUsers/SystemUsers";
import Profile from "@/components/dashboard/sections/profile/Profile";
import BasicSettings from "@/components/dashboard/sections/basicSettings/BasicSettings";
import ManageTags from "@/components/dashboard/sections/basicSettings/Tags/ManageTags";
import TagDetail from "@/components/dashboard/sections/basicSettings/Tags/TagDetail";
import ManageAgeCategories from "@/components/dashboard/sections/basicSettings/AgeCategories/ManageAgeCategories";
import ManageCategories from "@/components/dashboard/sections/basicSettings/Catagories/ManageCategories";
import ManageBanners from "@/components/dashboard/sections/basicSettings/Banner/ManageBanners";

import DashboardCourse from "@/components/dashboard/sections/courses/course/DashboardCourse";
import Sections from "@/components/dashboard/sections/courses/course/sections/sections/Sections";
import Lessons from "@/components/dashboard/sections/courses/course/sections/lessons/Lessons";
import SectionLessons from "@/components/dashboard/sections/courses/course/sections/sections/SectionLessons";
import SectionExaminations from "@/components/dashboard/sections/courses/course/sections/sections/SectionExaminations";
import Exams from "@/components/dashboard/sections/courses/course/sections/exams/Exams";
import ExamResults from "@/components/dashboard/sections/courses/course/sections/exams/ExamResults";
import Exercise from "@/components/dashboard/sections/courses/course/sections/exercise/Exercise";
import Participants from "@/components/dashboard/sections/courses/course/sections/participants/Participants";
import Supports from "@/components/dashboard/sections/courses/course/sections/supports/Supports";

export const Routes = [

    {
        path: '/',
        component: Splash,
    },

    {
        path: '/',
        component: Dashboard,
        children: [
            {
                name: rn.courses,
                path: rn.courses,
                component: Courses,
            },
            {
                name: rn.archivedCourses,
                path: rn.archivedCourses,
                component: ArchivedCourses,
            },
            {
                name: rn.draftCourses,
                path: rn.draftCourses,
                component: DraftCourses,
            },
            {
                name: rn.systemUsers,
                path: rn.systemUsers,
                component: SystemUsers,
            },
            {
                name: rn.profile,
                path: rn.profile,
                component: Profile,
                // beforeEnter: (to, from, next) => {
                //     next(false)
                // }
            },
            {
                name: rn.basicSettings,
                path: rn.basicSettings,
                component: BasicSettings,
            },
            {
                name: rn.manageTags,
                path: rn.basicSettings + '/' + rn.manageTags,
                component: ManageTags,
            },
            {
                name: rn.tagDetail,
                path: rn.basicSettings + '/' + rn.manageTags + '/' + rn.tagDetail,
                component: TagDetail,
            },
            {
                name: rn.manageAgeCategories,
                path: rn.basicSettings + '/' + rn.manageAgeCategories,
                component: ManageAgeCategories,
            },
            {
                name: rn.manageCategories,
                path: rn.basicSettings + '/' + rn.manageCategories,
                component: ManageCategories,
            },
            {
                name: rn.manageBanners,
                path: rn.basicSettings + '/' + rn.manageBanners,
                component: ManageBanners,
            },
        ]
    },

    {
        path: '/course/:ID',
        component: DashboardCourse,
        children: [
            {
                name: rn.sections,
                path: rn.sections,
                component: Sections,
            },
            {
                name: rn.sectionLessons,
                path: rn.sections + '/:sectionID/' + rn.sectionLessons,
                component: SectionLessons,
            },
            {
                name: rn.sectionExaminations,
                path: rn.sections + '/:sectionID/' + rn.sectionExaminations,
                component: SectionExaminations,
            },
            {
                name: rn.lessons,
                path: rn.lessons,
                component: Lessons,
            },
            {
                name: rn.exams,
                path: rn.exams,
                component: Exams,
            },
            {
                name: rn.examsResults,
                path: rn.exams + '/:examID/' + rn.examsResults,
                component: ExamResults,
            },
            {
                name: rn.exercise,
                path: rn.exercise,
                component: Exercise,
            },
            {
                name: rn.participants,
                path: rn.participants,
                component: Participants,
            },
            {
                name: rn.supports,
                path: rn.supports,
                component: Supports,
            },
        ]
    },

    {
        name: rn.login,
        path: '/' + rn.login,
        component: Login,
    },


    // {
    //     name : '404',
    //     path: '/404',
    //     component: notFound,
    // },
    //
    // {
    //     path: '*',
    //     redirect: '/404'
    // },

]




