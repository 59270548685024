<template>
  <div class="d-flex flex-column">

    <AddSupportPopup class="align-self-end mb-4"
                     @getList="getSupports(calculateIndex($store.getters.getAllSupportsCount, true, pageIndex))"
                     v-if="checkAccessList({property: 'Name', value: 'addSupport'})">
    </AddSupportPopup>

    <v-data-table
        :headers="headers"
        :items="users"
        :options.sync="options"
        :server-items-length="$store.getters.getAllSupportsCount"
        :loading="$store.getters.isLoading"
        class="elevation-1">

      <template v-slot:item.Name="{ item }">
        <name-overflow :mText="item.Name" :width="70"></name-overflow>
      </template>

      <template v-slot:item.Family="{ item }">
        <name-overflow :mText="item.Family" :width="70"></name-overflow>
      </template>

      <template v-slot:item.LastLogin="{ item }">{{ convertFormat(item.LastLogin, 'jYYYY/jMM/jDD - HH:mm') }}</template>

      <template v-slot:item.actions="{ item }">

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" v-if="checkAccessList({property: 'Name', value: 'course_deleteSupport'})" dark class="red" small @click="deleteSupport(item)">
              <v-icon class="white--text">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>حذف</span>
        </v-tooltip>

      </template>
    </v-data-table>
  </div>
</template>

<script>
import {constMethods} from "@/mixin/constMethods";
import AddSupportPopup from "@/components/dashboard/sections/courses/course/sections/supports/AddSupportPopup";
import nameOverflow from "@/components/helper/nameOverflow";
export default {
  name: "Supports",
  components: {
    AddSupportPopup,
    nameOverflow
  },
  mixins: [
    constMethods
  ],
  data() {
    return {
      pageIndex: 0,
      options: {},
      headers: [
        {text: 'نام', align: 'center', sortable: false, value: 'Name',},
        {text: 'نام خانوادگی', align: 'center', sortable: false, value: 'Family',},
        {text: 'همراه', align: 'center', sortable: false, value: 'Mobile',},
        {text: 'آخرین ورود', align: 'center', sortable: false, value: 'LastLogin'},
        {text: 'عملیات', align: 'center', value: 'actions', sortable: false},

      ],
    }
  },
  methods: {
    getSupports(index) {
      this.pageIndex = index

      const query = {
        index,
        limit: this.$store.getters.getLimit
      }
      this.$store.dispatch('getSupports', query)
    },
    deleteSupport(support) {
      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {
            this.$store.dispatch('deleteSupport', support.ID).then(
                () => {
                  this.getSupports(this.calculateIndex(this.$store.getters.getAllSupportsCount, false, this.pageIndex))
                })
          })
    }
  },
  computed: {
    users() {
      return this.$store.getters.getSupportsList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getSupports((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },

}
</script>

<style scoped>

</style>
