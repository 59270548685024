import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

import rn from "@/plugins/store/modules/const/routesName";
import server from "@/plugins/store/modules/const/server";
import models from "@/plugins/store/modules/const/models";
import statics from "@/plugins/store/modules/const/static";
import login from "@/plugins/store/modules/login/login";
import profile from "@/plugins/store/modules/profile/profile";
import courses from "@/plugins/store/modules/courses/courses";
import archivedCourses from "@/plugins/store/modules/courses/archivedCourses";
import draftCourses from "@/plugins/store/modules/courses/draftCourses";
import ageCategory from "@/plugins/store/modules/ageCategory/ageCategory";
import category from "@/plugins/store/modules/category/category";
import section from "@/plugins/store/modules/section/section";
import lesson from "@/plugins/store/modules/lesson/lesson";
import examination from "@/plugins/store/modules/examination/examination";
import examinationQuestions from "@/plugins/store/modules/examination/contents/examinationQuestions";
import examinationFiles from "@/plugins/store/modules/examination/contents/examinationFiles";
import usersExam from "@/plugins/store/modules/examination/usersExam";
import content from "@/plugins/store/modules/content/content";
import tag from "@/plugins/store/modules/tag/tag";
import lessonTags from "@/plugins/store/modules/tag/lessonTags";
import systemUsers from "@/plugins/store/modules/systemUsers/systemUsers";
import publics from "@/plugins/store/modules/public/public";
import enrolledUsers from "@/plugins/store/modules/enrolledUsers/enrolledUsers";
import supports from "@/plugins/store/modules/supports/supports";
import banner from "@/plugins/store/modules/banner/banner";

export const store = new Vuex.Store({

    modules: {
        rn,
        server,
        models,
        statics,
        login,
        profile,
        courses,
        archivedCourses,
        draftCourses,
        ageCategory,
        category,
        section,
        lesson,
        examination,
        examinationQuestions,
        examinationFiles,
        usersExam,
        content,
        tag,
        lessonTags,
        systemUsers,
        publics,
        enrolledUsers,
        supports,
        banner,
    }

})
