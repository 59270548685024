import router from "@/plugins/router/router";
import {encodeQueryData} from "@/plugins/resource/queryReq";
import ReqJson from "@/plugins/resource/reqJson";
import {store} from "@/plugins/store/store";

const state = {
    sections: [],
    allSectionsCount: 0,
    allSections: [],
    section: {},
};

const getters = {
    getSectionsList(state) {
        return state.sections
    },
    getAllSectionsCount(state) {
        return state.allSectionsCount
    },
    getAllSectionsList(state) {
        return state.allSections
    },
    getSection(state) {
        return state.section
    },
};

const mutations = {
    setSectionsList(state, sections) {
        state.sections = sections
    },
    setAllSectionsCount(state, allSectionsCount) {
        state.allSectionsCount = allSectionsCount
    },
    setAllSectionsList(state, allSections) {
        state.allSections = allSections
    },
    setSection(state, section) {
        state.section = section
    },
};

const actions = {

    getSections({commit}, query) {

        this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/sections${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllSectionsCount', response.meta.TotalCount)
                commit('setSectionsList', response.result)

            })
    },

    getAllSections({getters, commit, dispatch}, query) {
        if (query.index === 0)
            commit('setAllSectionsList', [])

        this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/sections${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                getters.getAllSectionsList.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    query.index = getters.getAllSectionsList.length
                    dispatch('getAllSections', query)
                }

            })
    },

    getSingleSection({commit}, sectionID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Sections/${sectionID}`, method: 'get'})
                .then((response) => {
                    commit('setSection', response.result[0])
                    resolve(response.result[0])
                })
        })
    },

    addSection({getters, commit, dispatch}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: 'Sections', body: new ReqJson(data.data).reqJS(), method: 'post'})
                .then(() => {
                    commit("setAllSectionsCount", getters.getAllSectionsCount + 1)
                    resolve()

                    if (getters.getAllSectionsList.length !== 0) {
                        dispatch('getAllSections', {index: 0, limit: store.getters.getLimit30,})
                    }
                })
        })
    },

    editSection({getters, dispatch}, data) {

        this.dispatch('sendRequest', {url: `Sections/${data.sectionID}`, body: new ReqJson(data.data).reqJS(), method: 'put'})
            .then(() => {

                dispatch('getSingleSection', data.sectionID).then((res) => {

                    const index = getters.getSectionsList.findIndex(item => item.ID === data.sectionID);
                    if (index !== -1) {
                        getters.getSectionsList.splice(index, 1, res)
                    }

                    const index2 = getters.getAllSectionsList.findIndex(item => item.ID === data.sectionID);
                    if (index2 !== -1) {
                        getters.getAllSectionsList.splice(index2, 1, res)
                    }
                })


            })
    },

    deleteSection({getters, commit}, sectionID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Sections/${sectionID}`, method: 'delete'})
                .then(() => {
                    commit("setAllSectionsCount", getters.getAllSectionsCount - 1)
                    resolve()

                    const index2 = getters.getAllSectionsList.findIndex(item => item.ID === sectionID);
                    if (index2 !== -1) {
                        getters.getAllSectionsList.splice(index2, 1)
                    }
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
