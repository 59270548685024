<template>
  <div  class="d-flex flex-column align-center">
    <p class="mb-0 font-weight-bold mb-4">{{ content.Title }}</p>
    <v-responsive :aspect-ratio="16/4" width="50%">
      <img :src="$store.getters.getImageServerURL + content.FileName" alt="alt" width="100%">
    </v-responsive>
    <p class="mb-0">{{ content.Text }}</p>
  </div>
</template>

<script>
export default {
  name: "MPic",
  props: {
    content: {
      type: Object,
      default: () => {
      }
    }
  },
}
</script>

<style scoped>

</style>