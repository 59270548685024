import ReqJson from "@/plugins/resource/reqJson";
import {encodeQueryData} from "@/plugins/resource/queryReq";
import {store} from "@/plugins/store/store";

const state = {
    ageCategories: [],
    allAgeCategoriesCount: 0,
    ageCategory: {},
    allAgeCategories: [],
};

const getters = {
    getAgeCategoriesList(state) {
        return state.ageCategories
    },
    getAllAgeCategoriesCount(state) {
        return state.allAgeCategoriesCount
    },
    getAgeCategory(state) {
        return state.ageCategory
    },
    getAllAgeCategories(state) {
        return state.allAgeCategories
    },
};

const mutations = {
    setAgeCategoriesList(state, ageCategories) {
        state.ageCategories = ageCategories
    },
    setAllAgeCategoriesCount(state, allAgeCategoriesCount) {
        state.allAgeCategoriesCount = allAgeCategoriesCount
    },
    setAgeCategory(state, ageCategory) {
        state.ageCategory = ageCategory
    },
    setAllAgeCategories(state, allAgeCategories) {
        state.allAgeCategories = allAgeCategories
    },
};

const actions = {

    getAgeCategories({commit}, query) {

        this.dispatch('sendRequest', {url: `AgeCategories${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (response.meta)
                    commit('setAllAgeCategoriesCount', response.meta.TotalCount)

                commit('setAgeCategoriesList', response.result)

            })
    },

    getAllAgeCategories({getters, commit, dispatch}, query) {
        if (query.index === 0)
            commit('setAllAgeCategories', [])

        this.dispatch('sendRequest', {url: `AgeCategories${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                getters.getAllAgeCategories.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    query.index = getters.getAllAgeCategories.length
                    dispatch('getAllAgeCategories', query)
                }

            })
    },

    getSingleAgeCategory({commit}, ageCategoryID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `AgeCategories/${ageCategoryID}`, method: 'get'})
                .then((response) => {
                    commit('setAgeCategory', response.result[0])
                    resolve(response.result[0])
                })
        })
    },

    addAgeCategory({getters, commit, dispatch}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: 'AgeCategories', body: new ReqJson(data.data).reqJS(), method: 'post'})
                .then(() => {
                    commit("setAllAgeCategoriesCount", getters.getAllAgeCategoriesCount + 1)
                    resolve()

                    if (getters.getAllAgeCategories.length !== 0) {
                        dispatch('getAllAgeCategories', {index: 0, limit: store.getters.getLimit30,})
                    }
                })
        })
    },

    editAgeCategory({getters, dispatch}, data) {

        this.dispatch('sendRequest', {url: `AgeCategories/${data.ageCategoryID}`, body: new ReqJson(data.data).reqJS(), method: 'put'})
            .then(() => {

                dispatch('getSingleAgeCategory',data.ageCategoryID).then((res) => {

                    const index = getters.getAgeCategoriesList.findIndex(item => item.ID === data.ageCategoryID);
                    if (index !== -1) {
                        getters.getAgeCategoriesList.splice(index, 1, res)
                    }

                    const index2 = getters.getAllAgeCategories.findIndex(item => item.ID === data.ageCategoryID);
                    if (index2 !== -1) {
                        getters.getAllAgeCategories.splice(index2, 1, res)
                    }
                })

            })
    },

    deleteAgeCategory({getters, commit}, ageCategoryID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `AgeCategories/${ageCategoryID}`, method: 'delete'})
                .then(() => {
                    commit("setAllAgeCategoriesCount", getters.getAllAgeCategoriesCount - 1)
                    resolve()

                    const index2 = getters.getAllAgeCategories.findIndex(item => item.ID === ageCategoryID);
                    if (index2 !== -1) {
                        getters.getAllAgeCategories.splice(index2, 1)
                    }
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
