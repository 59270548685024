<template>
  <div>
    <v-container class="mt-8 d-flex flex-column">

      <add-age-category-popup
          @getList="getAgeCategories(calculateIndex($store.getters.getAllAgeCategoriesCount, true, pageIndex))"
          class="align-self-end mb-4">
      </add-age-category-popup>

      <v-data-table
          :headers="headers"
          :items="ageCategories"
          :options.sync="options"
          :server-items-length="$store.getters.getAllAgeCategoriesCount"
          :loading="$store.getters.isLoading"
          class="elevation-1">

        <template #item.ageRange="{ item }">از {{ item.From }} تا {{ item.To }} سال</template>

        <template v-slot:item.actions="{ item }">
          <add-age-category-popup :obj="item"></add-age-category-popup>
          <v-btn dark class="mr-2 red" small @click="deleteAgeCategory(item)">حذف</v-btn>
        </template>
      </v-data-table>

    </v-container>

  </div>
</template>

<script>
import AddAgeCategoryPopup from "@/components/dashboard/sections/basicSettings/AgeCategories/AddAgeCategoryPopup";
import {constMethods} from "@/mixin/constMethods";

export default {
  name: "ManageAgeCategories",
  components: {
    AddAgeCategoryPopup
  },
  mixins: [
    constMethods
  ],
  data() {
    return {
      pageIndex: 0,
      options: {},
      headers: [
        {text: 'نام', align: 'center', sortable: false, value: 'Title',},
        {text: 'بازه سنی', align: 'center', sortable: false, value: 'ageRange',},
        {text: 'عملیات', align: 'center', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getAgeCategories(index) {
      this.pageIndex = index

      const query = {
        index,
        limit: this.$store.getters.getLimit
      }

      this.$store.dispatch('getAgeCategories', query)
    },
    deleteAgeCategory(item) {
      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {
            this.$store.dispatch('deleteAgeCategory', item.ID).then(
                () => {
                  this.getAgeCategories(this.calculateIndex(this.$store.getters.getAllAgeCategoriesCount, false, this.pageIndex))
                })
          })
    },
  },
  mounted() {

  },
  computed: {
    ageCategories() {
      return this.$store.getters.getAgeCategoriesList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getAgeCategories((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>