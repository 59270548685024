<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        v-on:keydown.esc="$emit('hide')">


      <v-card>

        <v-toolbar
            dark
            height="60"
            color="primary">

          <h3>{{ !obj ? 'ایجاد دوره جدید' : 'ویرایش دوره' }}</h3>

          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn x-large depressed text dark class="primary darken-1" v-on:click="submit">{{ !obj ? 'ثبت دوره جدید' : ' ثبت و ویرایش دوره' }}</v-btn>
            <v-btn class="mr-2" icon dark @click="$emit('hide')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="mt-8">

          <v-form ref="form" class="px-3">

            <v-row>

              <v-col cols="12" sm="6" md="4">
                <v-text-field label="عنوان دوره" v-model="course.Title" prepend-icon="mdi-folder" :rules="rules.titleCourseRules"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field label="ظرفیت(نفر)" v-model="course.Capacity" prepend-icon="mdi-folder" :rules="rules.capacityRules"></v-text-field>
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field readonly id="startDate" clearable @input="course.StartDate = $event ? $event : ''" label="تاریخ شروع دوره" v-model="course.StartDate" prepend-icon="mdi-calendar" :rules="rules.startDateRules"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field readonly id="endDate" clearable @input="course.EndDate = $event ? $event : ''" @click:clear="course.EndDate = '2222'" label="تاریخ پایان دوره" v-model="course.EndDate" prepend-icon="mdi-calendar"
                              :rules="rules.endDateRules"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-select
                    v-model="course.Category.ID"
                    :items="categories"
                    :rules="rules.categoryRules"
                    label="دسته بندی"
                    item-text="Title"
                    :menu-props="{ auto: true, overflowY: true}"
                    item-value="ID"
                    prepend-icon="mdi-map"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                    v-model="course.AgeCategory.ID"
                    :items="ageCategories"
                    :rules="rules.ageCategoryRules"
                    item-text="Title"
                    item-value="ID"
                    :menu-props="{ auto: true, overflowY: true}"
                    label="رده سنی"
                    prepend-icon="mdi-map"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex align-center">

                <v-radio-group prepend-icon="mdi-map" :rules="rules.courseType" label="نوع دوره:" v-model="course.Private" class="mt-0" row>
                  <v-radio label="عمومی" :value=0></v-radio>
                  <v-radio label="خصوصی" :value=1></v-radio>
                </v-radio-group>
                <p class="mr-10">{{ course.Private === 1 ? '(این دوره صرفا با لینک دعوت قابل دسترس است)' : course.Private === 0 ? '(این دوره برای عموم قابل دسترس است)' : '' }}</p>
              </v-col>

              <v-col cols="12" sm="6" md="5" lg="5" xl="5" class="d-flex align-center">
                <v-icon class="black--text ml-2">mdi-link-variant</v-icon>
                <p class="mb-0">لینک دعوت:</p>

                <p class="mr-2 mb-0 text-left pa-2" style="border: 2px solid grey;border-radius: 4px">{{ course.Link }}</p>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small depressed text class="pa-0 mr-2" v-bind="attrs" v-on="on" @click="$store.dispatch('getCourseTag')">
                      <v-icon class="black--text">mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>ایجاد لینک جدید</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <div class="d-flex align-center mt-12">
              <v-icon class="black--text ml-2">mdi-information-outline</v-icon>
              <h4>اطلاعات دوره (روابط عمومی)</h4>
            </div>

            <p class="ma-0 mr-1 mt-2">- لطفا برای درج لینک حتما از عبارت http و یا https در ابتدای آدرس استفاده نمایید.</p>
            <p class="ma-0 mr-1">- برای درج شماره تلفن یا شماره همراه ، فقط متن وارد شده شامل عدد باشد و حتما با 0 (صفر) شروع شود .</p>

            <!--            <v-textarea v-model="course.Description" label="توضیحات" rows="2" prepend-icon="mdi-comment"-->

            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-form ref="infoForm">
                  <v-text-field v-model="infoTitle" label="عنوان" :rules="infoRules.title"></v-text-field>
                  <v-textarea v-model="infoDescription" label="توضیحات" rows="1" :rules="infoRules.description"></v-textarea>
                </v-form>
              </v-col>
            </v-row>

            <v-btn depressed dark class="green mx-0 mt-3" v-on:click="addInfo">افزودن</v-btn>

            <div class="d-flex mt-8 mr-3" v-for="(item, index) in course.Info" :key="index">
              <v-row class="align-center">
                <p class="mb-0 ml-2 font-weight-bold" style="width: 20px">{{ index + 1 }} -</p>
                <v-col class="grey" cols="12" sm="4" md="2">
                  <p class="font-weight-bold ml-4 mb-0 white--text">{{ 'عنوان' + ': ' + item.Title }}</p>
                </v-col>
                <v-col class="grey" cols="12" sm="5" md="4">
                  <p class="mb-0 white--text">{{ 'توضیحات' + ': ' + item.Description }}</p>
                </v-col>
                <v-btn class="mr-sm-2 mt-2 mt-sm-0 red" small dark v-on:click="course.Info.splice(index,1)">حذف</v-btn>
              </v-row>
            </div>


          </v-form>
        </v-card-text>

      </v-card>

      <date-picker
          type="date"
          v-model="course.StartDate"
          format="jYYYY/jMM/jDD"
          element="startDate"
          :clearable="true"
          @input="onGetDate($event)"/>

      <date-picker
          type="date"
          :clearable="true"
          v-model="course.EndDate"
          format="jYYYY/jMM/jDD"
          element="endDate"
          :min="course.StartDate"
          @input="onGetDate($event)"/>

    </v-dialog>

  </v-row>

</template>

<script>

import momentJalali from 'moment-jalaali'
import {validation} from "@/mixin/validation";
import {constMethods} from "@/mixin/constMethods";

export default {
  name: "AddCourseDialog",
  mixins: [
    validation,
    constMethods
  ],
  props: {
    obj: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      course: {AgeCategory: {}, Category: {}},
      rules: {},
      infoRules: {},
      infoTitle: '',
      infoDescription: '',
    }
  },
  methods: {
    submit() {

      if (this.$refs.form.validate()) {
        // this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
        //     () => {
        const data = {
          "data": {
            "CategoryID": this.course.Category.ID,
            "AgeCategoryID": this.course.AgeCategory.ID,
            "Title": this.course.Title,
            "Capacity": this.course.Capacity ? parseInt(this.convertNumbersToEnglish(this.course.Capacity)) : 0,
            "Description": this.course.Description ? this.course.Description : '',
            "StartDate": this.course.StartDate,
            "EndDate": this.course.EndDate,
            "Info": this.course.Info,
            "Private": this.course.Private,
            "Tag": this.course.Tag,
          },
        }

        if (!this.obj) {//add new course
          this.$store.dispatch('addCourse', data).then(() => {
            this.$emit('getList')
            this.$emit('hide')
          })
        } else {//edit course
          data['courseID'] = this.obj.ID
          this.$store.dispatch('editCourse', data).then(() => {
            this.$emit('hide')
          })
        }

        // })

      }
    },
    onGetDate() {
      if (new Date(this.course.StartDate) > new Date(this.course.EndDate)) {
        this.course.EndDate = ''
      }
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()
      if (this.$refs.infoForm)
        this.$refs.infoForm.resetValidation()

      this.course = this.obj ? Object.assign({}, this.obj) : {AgeCategory: {}, Category: {}}
    },
    addInfo() {
      if (this.$refs.infoForm.validate()) {
        this.course.Info.push({
          "Title": this.infoTitle,
          "Description": this.infoDescription
        })
        this.infoTitle = ''
        this.infoDescription = ''
        this.$refs.infoForm.resetValidation()
      }
    },
  },
  computed: {
    ageCategories() {
      return this.$store.getters.getAllAgeCategories
    },
    categories() {
      return this.$store.getters.getAllCategories
    },
    courseTag() {
      return this.$store.getters.getCourseTag
    },
    // formattedStartDate: {
    //   get: function () {
    //     return this.course.StartDate ? momentJalali(this.course.StartDate).format('jYYYY/jMM/jDD') : ''
    //   },
    //   set: function () {
    //   }
    // },
    // formattedEndDate: {
    //   get: function () {
    //     return this.course.EndDate ? momentJalali(this.course.EndDate).format('jYYYY/jMM/jDD') : ''
    //   },
    //   set: function () {
    //   }
    // },
  },
  mounted() {
    this.rules = {
      titleCourseRules: [v => !!v || 'عنوان دوره را وارد کنید', v => (v && v.length >= 5) || 'عنوان دوره نباید کمتر از 5 حرف باشد',],
      // startDateRules: [v => !!v || 'تاریخ شروع را وارد کنید',],
      // endDateRules: [v => !!v || 'تاریخ پایان را وارد کنید',],
      categoryRules: [v => !!v || 'دسته بندی را انتخاب کنید',],
      ageCategoryRules: [v => !!v || 'رده سنی را انتخاب کنید',],
      // v => !!v || 'ظرفیت دوره را وارد کنید',
      capacityRules: [v => (!v || this.isNumber(v)) || 'ظرفیت دوره معتبر نیست!',],
      courseType: [v => (v === 0 || v === 1) || 'نوع دوره را انتخاب کنید',],
    }
    this.infoRules = {
      title: [v => !!v || 'عنوان را وارد کنید',],
      description: [v => !!v || 'توضیحات را انتخاب کنید',],
    }
  },
  watch: {
    dialog(n) {
      if (n) {
        if (!this.obj || !this.obj.Tag || this.obj.Tag === '')
          this.$store.dispatch('getCourseTag')
        this.reset()
        this.course = (this.obj && Object.keys(this.obj).length !== 0) ? Object.assign({}, this.obj) : {AgeCategory: {}, Category: {}, Info: [], Link: ''}
        this.course.EndDate = this.course.EndDate ? momentJalali(this.course.EndDate).format('jYYYY/jMM/jDD') : ''
        this.course.StartDate = this.course.StartDate ? momentJalali(this.course.StartDate).format('jYYYY/jMM/jDD') : ''
      }
    },
    courseTag(n) {
      this.course.Tag = n.Tag
      this.course.Link = n.Link
    }
  },
}
</script>

<style scoped>

</style>