<template>
  <div>

    <v-container class="mt-8 d-flex flex-column">

      <add-category-popup
          @getList="getCategories(calculateIndex($store.getters.getAllCategoriesCount, true, pageIndex))"
          class="align-self-end mb-4">
      </add-category-popup>

      <v-data-table
          :headers="headers"
          :items="categories"
          :options.sync="options"
          :server-items-length="$store.getters.getAllCategoriesCount"
          :loading="$store.getters.isLoading"
          class="elevation-1">

        <template v-slot:item.actions="{ item }">
          <add-category-popup :obj="item"></add-category-popup>
          <v-btn dark class="mr-2 red" small @click="deleteCategory(item)">حذف</v-btn>
        </template>
      </v-data-table>

    </v-container>

  </div>
</template>

<script>
import {constMethods} from "@/mixin/constMethods";
import AddCategoryPopup from "@/components/dashboard/sections/basicSettings/Catagories/AddCategoryPopup";

export default {
  name: "ManageCategories",
  components: {
    AddCategoryPopup
  },
  mixins: [
    constMethods
  ],
  data() {
    return {
      pageIndex: 0,
      options: {},
      headers: [
        {text: 'نام', align: 'center', sortable: false, value: 'Title',},
        {text: 'عملیات', align: 'center', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getCategories(index) {
      this.pageIndex = index

      const query = {
        index,
        limit: this.$store.getters.getLimit
      }

      this.$store.dispatch('getCategories', query)
    },
    deleteCategory(item) {
      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {
            this.$store.dispatch('deleteCategory', item.ID).then(
                () => {
                  this.getCategories(this.calculateIndex(this.$store.getters.getAllCategoriesCount, false, this.pageIndex))
                })
          })
    },
  },
  mounted() {

  },
  computed: {
    categories() {
      return this.$store.getters.getCategoriesList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getCategories((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>