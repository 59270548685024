<template>
  <v-data-table
      :headers="headers"
      :items="examinations"
      :options.sync="options"
      :server-items-length="$store.getters.getAllSectionExaminationsCount"
      :loading="$store.getters.isLoading"
      class="elevation-1">
  </v-data-table>
</template>

<script>
export default {
name: "SectionExaminations",
  methods: {

    getSectionExaminations(index) {
      const query = {
        index: index,
        limit: this.$store.getters.getLimit,
      }
      this.$store.dispatch('getSectionExaminations', query)
    },
  },
  data: () => ({
    options: {},
    headers: [
      {text: 'نام', align: 'center', sortable: false, value: 'Title',},
      {text: 'توضیحات', align: 'center', sortable: false, value: 'Description'},
    ],
  }),
  computed: {
    examinations() {
      return this.$store.getters.getSectionExaminationsList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getSectionExaminations((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>