<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!obj" color="green" dark v-bind="attrs" v-on="on">ایجاد رده سنی جدید</v-btn>
        <v-btn v-else small dark class="orange" v-on="on">ویرایش</v-btn>
      </template>
      <v-card>

        <v-card-title v-if="!obj"><h3>ایجاد رده سنی جدید</h3></v-card-title>
        <v-card-title v-else><h3>ویرایش رده سنی</h3></v-card-title>

        <v-card-text>
          <v-form @submit.prevent="submit" ref="form" class="px-3">
            <v-text-field label="عنوان رده سنی" v-model.trim="ageCategory.Title" prepend-icon="mdi-folder" :rules="rules.rangeAgeRules"></v-text-field>
            <div class="d-flex align-center mx-4">
              <p class="mx-4">از</p>
              <v-text-field class="centered-input" v-model.trim="ageCategory.From" dense maxlength="2" :rules="rules.startRangeRules"></v-text-field>
              <p class="mx-4">تا</p>
              <v-text-field class="centered-input" v-model.trim="ageCategory.To" dense maxlength="2" :rules="rules.endRangeRules"></v-text-field>
              <p class="mx-4">سال</p>
            </div>
            <v-btn depressed dark class="green mx-0 mt-3" type="submit">تایید</v-btn>
          </v-form>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";
import {validation} from "@/mixin/validation";

export default {
  name: "AddAgeCategory",
  props: {
    obj: {
      type: Object,
      default: () => {

      }
    },
  },
  mixins: [
    constMethods,
    validation
  ],
  data() {
    return {
      ageCategory: this.obj ? Object.assign({}, this.obj) : {To: '', From: ''},
      dialog: false,
      rules: {},
    }
  },
  methods: {
    submit() {

      if (this.$refs.form.validate()) {
        // this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
        //     () => {

              const data = {
                data: {
                  "Title": this.ageCategory.Title,
                  "From": parseInt(this.convertNumbersToEnglish(this.ageCategory.From)),
                  "To": parseInt(this.convertNumbersToEnglish(this.ageCategory.To)),
                }
              }

              if (!this.obj) {//add new age category
                this.$store.dispatch('addAgeCategory', data).then(
                    () => {
                      this.$emit('getList')
                    })
              } else {//edit age category
                data['ageCategoryID'] = this.obj.ID
                this.$store.dispatch('editAgeCategory', data)
              }

              this.dialog = false
            // })

      }
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      this.ageCategory = this.obj ? Object.assign({}, this.obj) : {To: '', From: ''}
    }
  },
  mounted() {
    this.rules = {
      rangeAgeRules: [v => !!v || 'عنوان رده سنی را وارد کنید'],
      startRangeRules: [v => !!v || 'ابتدای بازه را وارد کنید', v => this.isNumber(v) || 'ابتدای بازه معتبر نیست!',],
      endRangeRules: [v => !!v || 'انتهای بازه را وارد کنید', v => this.isNumber(v) || 'انتهای بازه معتبر نیست!',
        v => parseInt(this.convertNumbersToEnglish(v)) >= parseInt(this.convertNumbersToEnglish(this.ageCategory.From)) || 'انتهای بازه معتبر نیست!'],
    }
  },
  watch: {
    dialog() {
      this.reset()
    }
  },
}
</script>

<style scoped>

</style>