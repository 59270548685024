<template>
  <div class="d-flex flex-column">

    <v-data-table
        :headers="headers"
        :items="courses"
        :options.sync="options"
        :server-items-length="$store.getters.getAllDraftCoursesCount"
        :loading="$store.getters.isLoading"
        :footer-props="{ disableItemsPerPage : true }"
        class="elevation-1">

      <template v-slot:item.Title="{ item }">
        <name-overflow :mText="item.Title" :width="240"></name-overflow>
      </template>
      <!--      <template v-if="courses.AgeCategory" v-slot:item.AgeCategory.Title="{ item }">-->
      <!--        {{ item.AgeCategory.Title + ' (' + item.AgeCategory.To + ' تا ' + item.AgeCategory.From + ' سال)' }}-->
      <!--      </template>-->
      <template v-slot:item.StartDate="{ item }">{{ convertGregorianToJalali(item.StartDate) }}</template>
      <template v-slot:item.EndDate="{ item }">{{ convertGregorianToJalali(item.EndDate) }}</template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark class="primary ml-2" v-bind="attrs" v-on="on" @click="enterCourse(item)">
              <v-icon class="white--text">mdi-location-enter</v-icon>
            </v-btn>
          </template>
          <span>ورود به دوره</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark class="primaryDark ml-2" v-bind="attrs" v-on="on" @click="removeFromDraft(item)">
              <v-icon class="white--text">mdi-notebook-minus</v-icon>
            </v-btn>
          </template>
          <span>خروج از پیش نویس</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="checkAccessList({property: 'Name', value: 'deleteCourse'})" v-bind="attrs" v-on="on" dark class="orange ml-2" small v-on:click="showDialog(item)">
              <v-icon class="white--text">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>ویرایش</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="checkAccessList({property: 'Name', value: 'editCourse'})" v-bind="attrs" v-on="on" dark class="red ml-2" small @click="deleteCourse(item)">
              <v-icon class="white--text">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>حذف</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" dark class="primary" small v-on:click="enterCourseInfo(item)">
              <v-icon class="white--text">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>اطلاعات دوره</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <add-course-dialog :obj="course" :dialog="dialog" @hide="dialog = false"
                       @getList="getDraftCourses(calculateIndex($store.getters.getAllDraftCoursesCount, true, pageIndex))">
    </add-course-dialog>

    <course-info-dialog :dialog="courseInfoDialog" :obj="course" @hide="courseInfoDialog = false"></course-info-dialog>

  </div>
</template>

<script>
import {constMethods} from "@/mixin/constMethods";
import AddCourseDialog from "@/components/dashboard/sections/courses/AddCourseDialog";
import CourseInfoDialog from "@/components/dashboard/sections/courses/CourseInfoDialog";
import nameOverflow from "@/components/helper/nameOverflow";
export default {
  name: "DraftCourses",
  mixins: [
    constMethods
  ],
  components: {
    AddCourseDialog,
    CourseInfoDialog,
    nameOverflow,
  },
  data: () => ({
    pageIndex: 0,
    course: {},
    dialog: false,
    courseInfoDialog: false,
    options: {},
    headers: [
      {text: 'نام دوره', align: 'center', sortable: false, value: 'Title',},
      // {text: 'شروع', align: 'center', sortable: false, value: 'StartDate',},
      // {text: 'پایان', align: 'center', sortable: false, value: 'EndDate',},
      {text: 'رده سنی', align: 'center', sortable: false, value: 'AgeCategory.Title'},
      {text: 'دسته بندی', align: 'center', sortable: false, value: 'Category.Title'},
      // {text: 'ظرفیت', align: 'center', sortable: false, value: 'Capacity'},
      {text: 'تعداد شرکت کنندگان', align: 'center', sortable: false, value: 'RegisteredUsers'},
      {text: 'عملیات', align: 'center', value: 'actions', sortable: false},
    ],
  }),
  methods: {
    getDraftCourses(index) {
      this.pageIndex = index

      const query = {
        index,
        limit: this.$store.getters.getLimit,
      }
      this.$store.dispatch('getDraftCourses', query)
    },
    enterCourse(course) {
      if (constMethods.methods.checkAccessList({property: 'Name', value: 'course_sectionList'})) {
        this.$router.push({name: this.$store.state.rn.sections, params: {ID: course.ID,}})
      } else if (constMethods.methods.checkAccessList({property: 'Name', value: 'course_lessonsList'})) {
        this.$router.push({name: this.$store.state.rn.lessons, params: {ID: course.ID,}})
      } else if (constMethods.methods.checkAccessList({property: 'Name', value: 'course_examsList'})) {
        this.$router.push({name: this.$store.state.rn.exams, params: {ID: course.ID,}})
      }
          // else if (constMethods.methods.checkAccessList({property: 'Name', value: 'course_supportsList'})) {
          //   this.$router.push({name: this.$store.state.rn.supports, params: {ID: course.ID,}})
      // }
      else {
        this.$router.push({name: this.$store.state.rn.exercise, params: {ID: course.ID,}})
      }
    },
    deleteCourse(item) {
      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {
            this.$store.dispatch('deleteCourse', item.ID).then(
                () => {
                  this.getDraftCourses(this.calculateIndex(this.$store.getters.getAllDraftCoursesCount, false, this.pageIndex))
                })
          })
    },
    removeFromDraft(item) {
      // this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
      //     () => {
      this.$store.dispatch('removeFromDraft', item.ID).then(
          () => {
            this.getDraftCourses(this.calculateIndex(this.$store.getters.getAllDraftCoursesCount, false, this.pageIndex))
          })
      // })
    },
    showDialog(course) {
      if (this.$store.getters.getAllCategories.length === 0)
        this.$store.dispatch('getAllCategories', {index: 0, limit: this.$store.getters.getLimit30,})
      if (this.$store.getters.getAllAgeCategories.length === 0)
        this.$store.dispatch('getAllAgeCategories', {index: 0, limit: this.$store.getters.getLimit30,})
      this.course = course
      this.dialog = true
    },
    enterCourseInfo(course) {
      this.course = course
      this.courseInfoDialog = true
    }
  },
  computed: {
    courses() {
      return this.$store.getters.getDraftCoursesList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getDraftCourses((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>