import Vue from "vue";
import {store} from "@/plugins/store/store";
import router from "@/plugins/router/router";
import momentJalali from 'moment-jalaali'


export const constMethods = {
    methods: {

        convertJalaliToGregorian(date) {
            return date ? momentJalali(date).format('YYYY-MM-DD') : '';
        },
        convertGregorianToJalali(date) {
            return date ? momentJalali(date).format('jYYYY/jMM/jDD') : '';
        },
        convertFormat(date, format) {
            return date ? momentJalali(date).format(format) : '';
        },
        onOpenNewTab(n) {
            window.open(this.$store.getters.getImageServerURL + n, '_blank')
        },
        createImage(file) {
            return new Promise(resolve => {
                let reader = new FileReader();

                reader.onload = (e) => {
                    resolve(e.target.result)
                };

                reader.readAsDataURL(file);
            })
        },
        convertNumbersToEnglish: function (value) {
            if (value !== undefined && value !== null) {
                value = value.toString()
                return value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, function (c) {
                    return c.charCodeAt(0) & 0xf;
                });
            }
            return ''
        },
        validURL(str) {
            let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            return !!pattern.test(str);
        },
        logoutFromApp() {

            store.commit('setAgeCategoriesList', [])
            store.commit('setAllAgeCategoriesCount', 0)
            store.commit('setAgeCategory', {})
            store.commit('setAllAgeCategories', [])
            store.commit('setCategoriesList', [])
            store.commit('setAllCategoriesCount', 0)
            store.commit('setCategory', {})
            store.commit('setAllCategories', [])
            store.commit('setContentsList', [])
            store.commit('setAllContentsCount', 0)
            store.commit('setContentTypes', [])
            store.commit('setAllContentsList', [])
            store.commit('setCoursesList', [])
            store.commit('setAllCoursesCount', 0)
            store.commit('setCourse', {})
            store.commit('setEnrolledUsersList', [])
            store.commit('setAllEnrolledUsersCount', 0)
            store.commit('setExaminationsList', [])
            store.commit('setAllExaminationsCount', 0)
            store.commit('setExaminationTypes', [])
            store.commit('setAllExaminationsList', [])
            store.commit('setExamination', {})
            store.commit('setSectionExaminationsList', [])
            store.commit('setAllSectionExaminationsCount', 0)
            store.commit('setLessonsList', [])
            store.commit('setAllLessonsCount', 0)
            store.commit('setLesson', {})
            store.commit('setSectionLessonsList', [])
            store.commit('setAllSectionLessonsCount', 0)
            store.commit('setMobile', '')
            store.commit('setSecurityCode', '')
            store.commit('setCaptcha', {})
            store.commit('setProfile', {})
            store.commit('setProvinces', [])
            store.commit('setCities', [])
            store.commit('setSectionsList', [])
            store.commit('setAllSectionsCount', 0)
            store.commit('setAllSectionsList', [])
            store.commit('setSection', {})
            store.commit('setSupportsList', [])
            store.commit('setAllSupportsCount', 0)
            store.commit('setSupport', {})
            store.commit('setUsersList', [])
            store.commit('setAllUsersCount', 0)
            store.commit('setLessonTagsList', [])
            store.commit('setAllLessonTagsList', [])
            store.commit('setTagsList', [])
            store.commit('setAllTagsCount', 0)
            store.commit('setAllTagsList', [])
            store.commit('setTag', {})
            store.commit('setTagLessonsList', [])
            store.commit('setAllTagLessonsListCount', 0)

            Vue.$cookies.remove(store.getters.getBearer)
            Vue.$cookies.remove(store.getters.getACK)
            if (router.currentRoute.path !== '/login')
                router.replace({path: '/login'});
        },
        toast(data) {
            Vue.swal.fire({
                icon: data.type,
                title: data.title,
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: data.timer ? data.timer : 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Vue.swal.stopTimer)
                    toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
                }
            })
        },
        clipboard(str) {
            const el = document.createElement('textarea');
            el.addEventListener('focusin', e => e.stopPropagation());
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            this.toast({type: 'success', title: 'کپی شد', timer: 1000})
        },
        popup(data) {
            return new Promise((resolve, reject) => {
                Vue.swal.fire({
                    icon: data.type,
                    backdrop: `rgba(68, 165, 255, 0.2)`,
                    timerProgressBar: data.progress === true,
                    timer: data.timer ? data.timer : null,
                    showConfirmButton: false,
                    scrollbarPadding: false,
                    allowOutsideClick: data.outSideClick !== false,
                    allowEscapeKey: data.escapeKey !== false,
                    allowEnterKey: data.enterKey === true,
                    html:
                        '<head>' +
                        '<style>' +
                        '.btn-container {display: flex;justify-content: center}' +
                        '.width-90 {width: 90px;}' +
                        '.width-150 { width: 150px;}' +
                        '.primary-bg {background: #9652ff;}' +
                        ' </style>' +
                        '<div>' +
                        '<p id="title" class="primary-dark-color bold" style="line-height: 25px; margin:0 0 15px 0"></p>' +
                        '<p id="body" class="gray-very-dark-color" style="line-height: 25px; margin:0 0 15px 0"></p>' +
                        '      <div id="btnContainer" class="btn-container">' +
                        '        <button id="btnDeny" class="m-btn width-90 red-bg" v-on:click="cancel" style="margin-right: 10px"></button>' +

                        '        <button id="btnConfirm" class="m-btn width-90 green-bg" v-on:click="confirm"></button>' +
                        '      </div>' +
                        '</div>' +
                        '</head>',
                    willOpen() {
                        document.getElementById('title').textContent = data.title
                        document.getElementById('body').textContent = data.body
                        if (data.type === 'error' || data.button === 1) {
                            document.querySelector('#btnConfirm').classList.remove('width-90', 'green-bg')
                            document.querySelector('#btnConfirm').classList.add('width-150', 'primary-bg')
                            document.querySelector('#btnConfirm').textContent = 'خب'
                            document.querySelector('#btnDeny').style.display = 'none'
                            document.querySelector('#btnContainer').classList.remove('justify-content-end')
                            document.querySelector('#btnContainer').classList.add('justify-content-center')
                        } else {
                            document.getElementById('btnDeny').textContent = data.denyBtnTxt ? data.denyBtnTxt : 'انصراف'
                            document.getElementById('btnConfirm').textContent = data.confirmBtnTxt ? data.confirmBtnTxt : 'تایید'
                        }
                    },
                    didOpen() {
                        document.getElementById('btnConfirm').addEventListener('click', function () {
                            Vue.swal.close()
                            resolve()
                        })
                        document.getElementById('btnDeny').addEventListener('click', function () {
                            Vue.swal.close()
                            reject()
                        })
                    },
                    didClose() {
                    }
                })
                    .then((result) => {
                        if (result.dismiss === 'timer') {
                            resolve()
                        }
                    });
            })
        },
        previewFiles(event) {
            let file = null;
            // let url = null;
            const type = event.target.files[0].type
            if (type !== 'image/jpeg' && type !== 'image/jpg' && type !== 'image/bmp' && type !== 'image/bitmap'
                && type !== 'image/tiff' && type !== 'image/gif' && type !== 'image/png' && type !== 'image/svg') {
                this.popup({type: 'error', title: 'عکس ارسالی باید به یکی از فرمت های مجاز عکس باشد!'})
            } else if (event.target.files[0].size > 2000000) {
                this.popup({type: 'error', title: 'حجم عکس ارسالی باید کمتر از ۲ مگابایت باشد!'})
            } else {
                file = event.target.files[0];
                // url = URL.createObjectURL(file);

                return file
            }
        },
        calculateIndex(totalCount, addToList, pageIndex) {
            let index = null
            let limit = store.getters.getLimit

            if (addToList) {
                if (totalCount % limit === 0) {//حالتی که تعداد ردیف های لیست با limit برابر است
                    if (totalCount - limit === pageIndex) {//در صفحه آخری هستم
                        index = pageIndex
                    } else {//یکی از صفحات به غیر از آخری
                        index = totalCount - limit
                    }
                } else {
                    index = (Math.floor(totalCount / limit) * limit)
                }
            } else {
                if (totalCount % limit === 0) {//حالتی که تعداد ردیف های لیست با limit برابر است
                    if (totalCount === pageIndex) {//در صفحه آخری هستیم و یک مورد بود که پاک شد
                        if (totalCount === 0) {// اگر فقط یک صفحه باشد
                            index = 0 // 0 یا totalCount یا pageIndex
                        } else {// بیشتر از یک صفحه داشته باشیم
                            index = pageIndex - limit
                        }
                    } else {// در یکی از صفحات به غیر از آخری هستم
                        index = pageIndex
                    }
                } else {
                    index = pageIndex
                }
            }

            return index
        },
        checkAccessList(data) {
            let profile = store.getters.getProfile
            if (profile && profile.AccessList)
                for (let i = 0; i < profile.AccessList.length; i++) {
                    if (profile.AccessList[i][data.property] === data.value) {
                        return true
                    }
                }
            return false
        },

    }
}


