<template>

  <div class="d-flex flex-column">

    <v-row class="mb-4" no-gutters>
      <v-col cols="12" sm="5" md="3" order="2" order-sm="1">
        <v-select v-model="selectedSectionID"
                  @change="onSelectSection"
                  :items="allSections"
                  item-text="Title"
                  item-value="ID"
                  hide-details="auto"
                  label="همه بخش ها"
                  dense
                  solo
                  :menu-props="{ auto: true, overflowY: true, closeOnContentClick: true }">

          <template v-slot:prepend-item>
            <v-list-item @click="onSelectSection(0)">
              <v-list-item-content>
                <v-list-item-title>همه بخش ها</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

        </v-select>
      </v-col>
      <v-col cols="12" sm="5" md="3" class="mr-0 mr-sm-auto text-left mb-4 mb-sm-0" order="1" order-sm="2">
        <add-examination-popup v-if="checkAccessList({property: 'Name', value: 'addExamination'})"
                               @getList="getExaminations(calculateIndex($store.getters.getAllExaminationsCount, true, pageIndex))"
                               class="align-self-end"></add-examination-popup>
      </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="examinations"
        :options.sync="options"
        :server-items-length="$store.getters.getAllExaminationsCount"
        :loading="$store.getters.isLoading"
        class="elevation-1">

      <template v-slot:item.Title="{ item }">
        <name-overflow :mText="item.Title" :width="70"></name-overflow>
      </template>

      <template v-slot:item.ExamType.TitleFa="{ item }">
        <name-overflow :mText="item.ExamType.TitleFa" :width="100"></name-overflow>
      </template>

      <template v-slot:item.Section.Title="{ item }">
        <name-overflow :mText="item.Section.Title" :width="70"></name-overflow>
      </template>

      <template v-slot:item.Description="{ item }">
        <name-overflow :mText="item.Description" :width="240"></name-overflow>
      </template>


      <template v-slot:item.actions="{ item }">
        <add-examination-popup :obj="item" v-if="checkAccessList({property: 'Name', value: 'editExamination'})"></add-examination-popup>
        <manage-exam-files v-if="item.ExamType.Title === 'File'" :exam="item"></manage-exam-files>
        <mange-exam-questions v-if="item.ExamType.Title === 'Question'" :exam="item"></mange-exam-questions>


        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark class="mr-2 primaryLight" v-bind="attrs" v-on="on" small @click="$router.push({name: $store.state.rn.examsResults, params: {examID: item.ID}, query: {examType: item.ExamType.TitleFa,name: item.Title}})">
              <v-icon class="white--text">mdi-clipboard-list</v-icon>
            </v-btn>
          </template>
          <span>نتایج</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark class="mr-2 red" v-bind="attrs" v-on="on" small @click="deleteExamination(item)" v-if="checkAccessList({property: 'Name', value: 'deleteExamination'})">
              <v-icon class="white--text">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>حذف</span>
        </v-tooltip>

      </template>
    </v-data-table>
  </div>

</template>

<script>
import {constMethods} from "@/mixin/constMethods";
import AddExaminationPopup from "@/components/dashboard/sections/courses/course/sections/exams/popup/AddExaminationPopup";
import ManageExamFiles from "@/components/dashboard/sections/courses/course/sections/exams/popup/ManageExamFiles";
import MangeExamQuestions from "@/components/dashboard/sections/courses/course/sections/exams/popup/MangeExamQuestions";
import nameOverflow from "@/components/helper/nameOverflow";

export default {
  name: "Exams",
  mixins: [
    constMethods
  ],
  components: {
    'addExaminationPopup': AddExaminationPopup,
    'manageExamFiles': ManageExamFiles,
    'mangeExamQuestions': MangeExamQuestions,
    nameOverflow,
  },
  data: () => ({
    pageIndex: 0,
    selectedSectionID: 0,
    options: {},
    headers: [
      {text: 'نام امتحان', align: 'center', sortable: false, value: 'Title'},
      {text: 'نوع', align: 'center', sortable: false, value: 'ExamType.TitleFa'},
      {text: 'توضیحات', align: 'center', sortable: false, value: 'Description'},
      {text: 'بخش', align: 'center', sortable: false, value: 'Section.Title'},
      {text: 'عملیات', align: 'center', value: 'actions', sortable: false},
    ],
  }),
  methods: {
    onSelectSection(sectionID) {
      this.selectedSectionID = sectionID
      this.getExaminations(0)
    },
    getExaminations(index) {
      this.pageIndex = index

      const query = {
        index: index,
        limit: this.$store.getters.getLimit,
        sectionID: this.selectedSectionID === 0 ? null : this.selectedSectionID
      }
      this.$store.dispatch('getExaminations', query)
    },
    deleteExamination(item) {
      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {
            this.$store.dispatch('deleteExamination', item.ID).then(
                () => {
                  this.getExaminations(this.calculateIndex(this.$store.getters.getAllExaminationsCount, false, this.pageIndex))
                })
          })
    }
  },
  mounted() {
    if (this.$store.getters.getAllSectionsList.length === 0)
      this.$store.dispatch('getAllSections', {index: 0, limit: this.$store.getters.getLimit30})
  },
  computed: {
    examinations() {
      return this.$store.getters.getExaminationsList
    },
    allSections() {
      return this.$store.getters.getAllSectionsList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getExaminations((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>
