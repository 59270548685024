import {encodeQueryData} from "@/plugins/resource/queryReq";
import ReqJson from "@/plugins/resource/reqJson";
import {store} from "@/plugins/store/store";

const state = {
    questions: [],
    allQuestionsCount: 0,
    allQuestions : [],
};

const getters = {
    getQuestionsList(state) {
        return state.questions
    },
    getAllQuestionsCount(state) {
        return state.allQuestionsCount
    },
    getAllQuestionsList(state) {
        return state.allQuestions
    },
};

const mutations = {
    setQuestionsList(state, questions) {
        state.questions = questions
    },
    setAllQuestionsCount(state, allQuestionsCount) {
        state.allQuestionsCount = allQuestionsCount
    },
    setAllQuestionsList(state, allQuestions) {
        state.allQuestions = allQuestions
    },
};

const actions = {

    getQuestions({commit}, data) {
        this.dispatch('sendRequest', {url: `Examination/${data.examID}/questions${encodeQueryData(data.query)}`, method: 'get'})
            .then(response => {

                if (data.query.index == 0 && response.meta)
                    commit('setAllQuestionsCount', response.meta.TotalCount)
                commit('setQuestionsList', response.result)

            })
    },

    getAllQuestions({getters, commit, dispatch}, data) {

        this.dispatch('sendRequest', {url: `Examination/${data.examID}/questions${encodeQueryData(data.query)}`, method: 'get'})
            .then(response => {
                if (data.query.index == 0)
                    commit('setAllQuestionsList', [])

                getters.getAllQuestionsList.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    data.query.index = getters.getAllQuestionsList.length
                    dispatch('getAllQuestions', data)
                }

            })
    },

    addQuestion({getters, commit, dispatch}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Examination/${data.examID}/questions`, body: new ReqJson(data.data).reqJS(), method: 'post'})
                .then(() => {
                    commit("setAllQuestionsCount", getters.getAllQuestionsCount + 1)
                    resolve()

                    // if (getters.getAllQuestionsList.length !== 0) {
                        dispatch('getAllQuestions', {examID: data.examID, query: {index: 0, limit: store.getters.getLimit30}})
                    // }
                })
        })
    },

    deleteQuestion({getters, commit}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Examination/${data.examID}/questions/${data.questionID}`, method: 'delete'})
                .then(() => {
                    commit("setAllQuestionsCount", getters.getAllQuestionsCount - 1)
                    resolve()

                    const index2 = getters.getAllQuestionsList.findIndex(item => item.ID === data.questionID);
                    if (index2 !== -1) {
                        getters.getAllQuestionsList.splice(index2, 1)
                    }
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
