import Vue from 'vue'
import VueResource from 'vue-resource'
import {store} from '@/plugins/store/store'
import {constMethods} from '@/mixin/constMethods'
import VueCookies from "vue-cookies";

let uploadUniqueHint = '/file'
let numberOfPendingRequest = 0;
let url = ''

Vue.use(VueResource)

Vue.http.options.root = store.getters.getServerURL

// Vue.http.options.timeout = 10000000

Vue.http.interceptors.push((request, next) => {

    url = request.url
    numberOfPendingRequest++;
    store.commit('setRequest', request)
    store.commit('setProgress', 0)
    store.commit('setLoading', true)

    request.headers.set('Authorization', 'Bearer ' + VueCookies.get(store.getters.getBearer));

    if (request.body && !request.url.includes(uploadUniqueHint)) {
        request.body = JSON.parse(constMethods.methods.convertNumbersToEnglish(JSON.stringify(request.body)))
    }
    request.url = JSON.parse(constMethods.methods.convertNumbersToEnglish(JSON.stringify(request.url)))


    if (store.getters.isDebug)
        request.url = request.url.includes('?') ? request.url + '&' + store.getters.getDebugURL : request.url + '?' + store.getters.getDebugURL

    if (!store.getters.isHideLogs)
        console.log(`request(${request.method}) =>`, request.url, request.body ? request.body : '')


    next(response => {//response received from server
        numberOfPendingRequest--;
        if (numberOfPendingRequest === 0) {
            store.commit('setLoading', false)
        }
        response.body = JSON.parse(JSON.stringify(response.body).replace(/NULL/g, '').replace(/Null/g, ''))

        if (response.status === 200) {
            if (!store.getters.isHideLogs)
                console.log('response =>', request.url, response)
        } else {
            if (!store.getters.isHideLogs)
                console.log(`error(${response.status}) =>`, request.url, response)

            if (response.status === 401 || response.status === 403) {
                constMethods.methods.popup({type: 'error', title: 'دسترسی شما مجاز نیست!', progress: true, timer: 5000, outSideClick: false, escapeKey: false}).then(() => {
                    constMethods.methods.logoutFromApp()
                })
            } else if (response.status === 404) {
                constMethods.methods.popup({type: 'error', title: 'خطا ۴۰۴!' + '\n' + request.url})
            } else if (response.status === 500) {
                constMethods.methods.popup({type: 'error', title: 'خطا ۵۰۰!' + '\n' + request.url})
            } else if (response.status === 405) {
                constMethods.methods.popup({type: 'error', title: 'خطا ۴۰۵ متد اشتباه!!!' + '\n' + request.url})
            } else if (response.status === 0) {
                // constMethods.methods.popup({type: 'error', title: 'خطای نامشخص!!!'})
            } else if (response && response.body && response.body.message && response.body.message.umsg) {
                constMethods.methods.popup({type: 'error', title: response.body.message.umsg})
            } else {
                constMethods.methods.popup({type: 'error', title: 'خطای نامشخص!!!'})
            }

        }

    })

});

Vue.http.options.progress = uploadEvent => {
    if (uploadEvent.lengthComputable) {
        if (url.includes(uploadUniqueHint))
            store.commit('setProgress', Math.round(uploadEvent.loaded / uploadEvent.total * 100))
    }
}

// Vue.http.interceptor.jsonp = function (request) {
//     // if (uploadUniqueHint) {
//     //     setTimeout(() => {
//     //         request.abort()
//     //     }, 4000)
//     // }
// };