import {encodeQueryData} from "@/plugins/resource/queryReq";

const state = {
    draftCourses: [],
    allDraftCoursesCount: 0,
};

const getters = {
    getDraftCoursesList(state) {
        return state.draftCourses
    },
    getAllDraftCoursesCount(state) {
        return state.allDraftCoursesCount
    },
};

const mutations = {
    setDraftCoursesList(state, draftCourses) {
        state.draftCourses = draftCourses
    },
    setAllDraftCoursesCount(state, allDraftCoursesCount) {
        state.allDraftCoursesCount = allDraftCoursesCount
    },
};

const actions = {

    getDraftCourses({commit}, query) {
        this.dispatch('sendRequest', {url: `Courses/drafts${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllDraftCoursesCount', response.meta.TotalCount)
                commit('setDraftCoursesList', response.result)

            })
    },

    addToDraft({getters, commit}, courseID) {
        return new Promise(resolve => {
                this.dispatch('sendRequest', {url: `Courses/${courseID}/draft/1`, method: 'patch'})
                .then(() => {
                    commit("setAllCoursesCount", getters.getAllCoursesCount - 1)
                    resolve()
                })
        })
    },

    removeFromDraft({getters, commit}, courseID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Courses/${courseID}/draft/0`, method: 'patch'})
                .then(() => {
                    commit("setAllDraftCoursesCount", getters.getAllDraftCoursesCount - 1)
                    resolve()
                })
        })
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
