<template>
  <v-row justify="center">
    <v-dialog
        fullscreen
        @click:outside="$emit('hide')"
        v-on:keydown.esc="$emit('hide')"
        v-model="dialog"
        width="600px">
      <!--      <template v-slot:activator="{ on, attrs }">-->
      <!--        <v-btn v-bind="attrs" v-on="on" dark class="primary" small>-->
      <!--          <v-icon class="white&#45;&#45;text">mdi-information-outline</v-icon>-->
      <!--        </v-btn>-->
      <!--      </template>-->
      <v-card>
        <v-toolbar
            dark
            height="60"
            color="primary">

          <v-btn icon dark @click="$emit('hide')">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <h3>اطلاعات دوره</h3>

          <v-spacer></v-spacer>

        </v-toolbar>

        <v-card-title>

        </v-card-title>
        <v-card-text>
          <p><b>نام دوره : </b> {{ course.Title }}</p>

          <p><b>نوع دوره : </b> {{ course.Private === 1 ? 'خصوصی' : 'عمومی' }}</p>

          <div class="d-flex align-center flex-wrap">
            <p class="ml-10"><b>رده سنی : </b> {{ course.AgeCategory.Title }}</p>
            <p><b>دسته بندی : </b> {{ course.Category.Title }}</p>
          </div>

          <div class="d-flex align-center flex-wrap">
            <p class="ml-10"><b>تاریخ شروع : </b> {{ convertGregorianToJalali(course.StartDate) }}</p>
            <p><b>تاریخ پایان : </b> {{ convertGregorianToJalali(course.EndDate) }}</p>
          </div>

          <div class="d-flex align-center flex-wrap">
            <p class="ml-10"><b>ظرفیت : </b> {{ course.Capacity }} نفر</p>
            <p><b>تعداد شرکت کنندگان : </b> {{ course.RegisteredUsers }} نفر</p>
          </div>


          <div v-if="course.Link" class="d-flex align-center flex-wrap">
            <p class="ml-1 mb-0"><b>لینک دعوت : </b></p>
            <p class="mb-0 pa-2 ml-1" style="border: 2px solid grey;border-radius: 4px">{{ course.Link }}</p>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small depressed text class="pa-0" v-bind="attrs" v-on="on" @click="clipboard(course.Link)">
                  <v-icon class="black--text">mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>کپی لینک دعوت</span>
            </v-tooltip>
          </div>

          <p v-if="course.Info &&  course.Info.length !== 0" class="mt-4 mb-1"><b>اطلاعات دوره (روابط عمومی) : </b></p>

          <div class="d-flex" v-for="(item, index) in course.Info" :key="index">
              <div class="d-flex flex-wrap">
                <p class="mb-0 font-weight-bold" style="width: 20px">{{ index + 1 }} -</p>
                <p class="mb-0">{{item.Title + ' (' }}</p>
                <p class="mb-0">{{ item.Description + ')' }}</p>
              </div>
          </div>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "CourseInfoDialog",
  mixins: [
    constMethods
  ],
  props: {
    obj: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      course: {AgeCategory: {}, Category: {}},
    }
  },
  watch: {
    dialog(n) {
      if (n) {
        this.course = (this.obj && Object.keys(this.obj).length !== 0) ? Object.assign({}, this.obj) : {AgeCategory: {}, Category: {}, Info: [], Link: ''}
      }
    },
  },
}
</script>

<style scoped>

</style>