import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify/vuetify'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, {
    plyr: {}
})
Vue.config.productionTip = false

import router from '@/plugins/router/router'
import {store} from '@/plugins/store/store'
// import '@/plugins/resource/axios'
import '@/plugins/resource/resource'

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.use(VuePersianDatetimePicker, {name: 'date-picker'});

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)


new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
