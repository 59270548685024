import router from "@/plugins/router/router";
import {encodeQueryData} from "@/plugins/resource/queryReq";

const state = {
    usersExam: [],
    allUsersExamCount: 0,
};

const getters = {
    getUsersExamList(state) {
        return state.usersExam
    },
    getAllUsersExamCount(state) {
        return state.allUsersExamCount
    },

};

const mutations = {
    setUsersExamList(state, usersExam) {
        state.usersExam = usersExam
    },
    setAllUsersExamCount(state, allUsersExamCount) {
        state.allUsersExamCount = allUsersExamCount
    },

};

const actions = {

    getUsersExam({commit}, query) {
        if (query.index == 0)
            commit('setUsersExamList', [])

        this.dispatch('sendRequest', {url: `Examination/${router.currentRoute.params.examID}/userExams${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllUsersExamCount', response.meta.TotalCount)
                commit('setUsersExamList', response.result)

            })
    },

    addComment({getters}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Examination/${router.currentRoute.params.examID}/userExams/${data.userExamID}/review${encodeQueryData(data.comment)}`, method: 'get'})
                .then(response => {

                    const index = getters.getUsersExamList.findIndex(item => item.ID === data.userExamID);
                    if (index !== -1) {
                        getters.getUsersExamList.splice(index, 1, response.result[0])
                    }

                    resolve()
                })
        })
    },

    addScore({getters}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Examination/${router.currentRoute.params.examID}/userExams/${data.userExamID}/score${encodeQueryData(data.score)}`, method: 'get'})
                .then(response => {

                    const index = getters.getUsersExamList.findIndex(item => item.ID === data.userExamID);
                    if (index !== -1) {
                        getters.getUsersExamList.splice(index, 1, response.result[0])
                    }

                    resolve()
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
