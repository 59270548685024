import ReqJson from "@/plugins/resource/reqJson";
import router from "@/plugins/router/router";
import {store} from "@/plugins/store/store";
import {constMethods} from "@/mixin/constMethods";

const state = {
    profile: {},
    mProfile: {},
};

const getters = {
    getProfile(state) {
        return state.profile
    },
    getMProfile(state) {
        return state.mProfile
    },
};

const mutations = {
    setProfile(state, profile) {
        if (!profile.Province) profile.Province = {}
        if (!profile.City) profile.City = {}
        state.mProfile = Object.assign({}, profile)
        state.profile = profile
    },
};

const actions = {

    getProfile({commit, dispatch}) {

        this.dispatch('sendRequest', {url: 'User/profile', method: 'get'})
            .then(response => {
                commit('setProfile', response.result)

                if (response.result.AccessList && response.result.AccessList.length !== 0) {
                    if (!router.currentRoute.name || router.currentRoute.name === store.state.rn.login) {

                        if (constMethods.methods.checkAccessList({property: 'Prefix', value: 'courses'})) {
                            router.replace({name: store.state.rn.courses})
                        } else if (constMethods.methods.checkAccessList({property: 'Prefix', value: 'setting'})) {
                            router.replace({name: store.state.rn.systemUsers})
                        } else if (constMethods.methods.checkAccessList({property: 'Prefix', value: 'basic'})) {
                            router.replace({name: store.state.rn.basicSettings})
                        } else {
                            router.replace({name: store.state.rn.profile})
                        }
                    }

                    dispatch('getProvinces')
                    if (response.result.Province && response.result.Province.LinkID)
                        dispatch('getCities', response.result.Province.LinkID)

                } else {
                    constMethods.methods.popup({type: 'error', title: 'دسترسی شما برای ورود مجاز نیست!', progress: true, timer: 5000, outSideClick: false, escapeKey: false}).then(() => {
                        constMethods.methods.logoutFromApp()
                    })
                }
            })
    },

    setProfile({dispatch}, data) {

        this.dispatch('sendRequest', {url: 'User/profile', body: new ReqJson(data).reqJS(), method: 'post'})
            .then(() => {
                constMethods.methods.popup({type: 'success', title: 'پروفایل شما ثبت شد', button: 1, progress: true, timer: 4000})
                dispatch('getProfile')
            })
    },

    uploadAvatar({getters}, pic) {

        const fd = new FormData();
        fd.append('avatar', pic, pic.name);

        this.dispatch('sendRequest', {url: 'User/avatar/file', body: fd, method: 'post'})
            .then(response => {
                constMethods.methods.popup({type: 'success', title: 'عکس پروفایل شما ثبت شد', button: 1, progress: true, timer: 4000})
                getters.getProfile.Avatar = response.result.FileID
                getters.getMProfile.Avatar = response.result.FileID
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
