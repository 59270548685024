<template>
  <div class="text-center d-inline">

    <v-dialog v-model="dialog" width="700" @click:outside="$emit('hide')">

      <v-data-table
          :headers="headers"
          :items="userCourses"
          :options.sync="options"
          :server-items-length="userCourses.length"
          :loading="$store.getters.isLoading"
          class="elevation-1">
      </v-data-table>

    </v-dialog>

  </div>
</template>

<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "AddCoursePopup",
  mixins: [
    constMethods
  ],
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: {},
      headers: [
        {text: 'نام دوره', align: 'center', sortable: false, value: 'Title',},
        {text: 'رده سنی', align: 'center', sortable: false, value: 'AgeCategory.Title',},
        {text: 'دسته بندی', align: 'center', sortable: false, value: 'Category.Title',},
        {text: 'تاریخ ثبت نام', align: 'center', sortable: false, value: 'EnrolledAt',},
      ],
    }
  },

  computed: {
    userCourses() {
      return this.$store.getters.getUserCoursesList
    },
  },
  // watch: {
  //   options: {
  //     // handler(val) {
  //     //   this.getSystemUsers((val.page - 1) * this.$store.getters.getLimit)
  //     // },
  //   },
  // },
}
</script>

<style scoped>

</style>