<template>
  <v-data-table
      :headers="headers"
      :items="lessons"
      :options.sync="options"
      :server-items-length="$store.getters.getAllSectionLessonsCount"
      :loading="$store.getters.isLoading"
      class="elevation-1">
  </v-data-table>
</template>

<script>
export default {
name: "SectionLessons",
  data: () => ({
    options: {},
    headers: [
      {text: 'نام', align: 'center', sortable: false, value: 'Title',},
      {text: 'توضیحات', align: 'center', sortable: false, value: 'Description'},
    ],
  }),
  methods: {

    getSectionLessons(index) {
      const query = {
        index: index,
        limit: this.$store.getters.getLimit,
      }
      this.$store.dispatch('getSectionLessons', query)
    },
  },
  computed: {
    lessons() {
      return this.$store.getters.getSectionLessonsList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getSectionLessons((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>