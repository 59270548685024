<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn dark class="mr-2 primaryLight" small v-bind="attrs" @click="getAllLessonTags()" v-on="on">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }"><v-icon class="white--text" v-bind="attrs" v-on="on">mdi-tag-text</v-icon></template>
            <span>برچسب</span>
          </v-tooltip>
        </v-btn>
      </template>

      <v-card>

        <v-card-title><h3>مدیریت برچسب</h3></v-card-title>

        <v-card-text>

          <div class="d-inline" v-for="(item,i) in allLessonTags" v-bind:key="i">
            <v-chip class="ma-1" close color="primary" outlined @click:close="removeTag(item)">{{ item.Title }}</v-chip>
          </div>
          <v-form @submit.prevent="addTag" v-if="checkAccessList({property: 'Name', value: 'lesson_addTag'})" ref="tag" class="px-3">

            <div class="d-flex">
              <v-text-field label="عنوان برچسب" v-model.trim="tagTitle" prepend-icon="mdi-folder" :rules="tagTitleRules"></v-text-field>

              <v-btn depressed dark small class="green mx-0 mt-5 mr-4" type="submit">تایید</v-btn>

            </div>

          </v-form>

          <v-form @submit.prevent="addTags" v-if="checkAccessList({property: 'Name', value: 'lesson_addTags'})" ref="tags" class="px-3">

            <div class="d-flex">
              <v-select v-model="tagItems" :items="allTags" :rules="tagItemsRules" label="انتخاب برچسب"
                        item-text="Title" item-value="Title" multiple prepend-icon="mdi-folder" :menu-props="{ auto: true, overflowY: true, closeOnContentClick: true}"></v-select>
              <v-btn depressed dark small class="green mx-0 mt-5 mr-4" type="submit">تایید</v-btn>

            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "ManageTagsPopup",
  mixins: [
    constMethods
  ],
  props: {
    lesson: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      dialog: false,
      tagTitle: '',
      tagItems: [],
      tagTitleRules: [v => !!v || 'عنوان برچسب را وارد کنید',],
      tagItemsRules: [v => !!v && v.length !== 0 || 'حداقل یک برچسب را انتخاب کنید',],
    }
  },
  methods: {
    getAllLessonTags() {
      this.$store.commit('setAllLessonTagsList', [])
      this.$store.dispatch('getAllLessonTags', {lessonID: this.lesson.ID, query: {index: 0, limit: this.$store.getters.getLimit30,}})

      if (this.$store.getters.getAllTagsList.length === 0)
        this.$store.dispatch('getAllTags', {index: 0, limit: this.$store.getters.getLimit30,})
    },
    removeTag(item) {
      const data = {
        lessonID: this.lesson.ID,
        tagID: item.ID
      }
      this.$store.dispatch('deleteLessonTag', data).then((tagID) => {
        const index = this.$store.getters.getAllLessonTagsList.findIndex(item => item.ID === tagID);
        if (index !== -1) {
          this.$store.getters.getAllLessonTagsList.splice(index, 1)
        }
      })
    },
    addTags() {
      if (this.$refs.tags.validate()) {

        let items = []
        this.tagItems.forEach(item => {
          items.push({
            Title: item
          })
        })

        const data = {
          lessonID: this.lesson.ID,
          data: {
            Tags: items
          }
        }
        this.$store.dispatch('addLessonTags', data)

        this.reset()
      }
    },
    addTag() {
      if (this.$refs.tag.validate()) {
        const data = {
          lessonID: this.lesson.ID,
          data: {
            Title: this.tagTitle
          }
        }
        this.$store.dispatch('addLessonTag', data)

        this.reset()
      }
    },
    reset() {
      if (this.$refs.tag)
        this.$refs.tag.resetValidation()
      if (this.$refs.tags)
        this.$refs.tags.resetValidation()
      this.tagTitle = ''
      this.tagItems = []
    }
  },
  computed: {
    allTags() {
      return this.$store.getters.getAllTagsList
    },
    allLessonTags() {
      return this.$store.getters.getAllLessonTagsList
    }
  },
  watch: {
    dialog(n) {
      if (n) {
        this.reset()
      }
    }
  },
}
</script>

<style scoped>

</style>