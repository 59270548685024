<template>
  <v-window-item :value="2">
    <v-row class="fill-height">
      <v-col cols="12" md="4" class="teal accent-3">
        <v-card-text class="white--text mt-6">
          <h1 class="text-center subtitle-1">سامانه مدیریت ادیب عشق</h1>
        </v-card-text>

        <v-flex>
          <v-layout column align-center>
            <v-btn width="130" rounded outlined dark @click="changeMobileNumber" class="mb-3">تغییر شماره</v-btn>
            <v-btn id="time" width="130" rounded outlined dark @click="reSendCode()" class="mb-3">ارسال مجدد کد</v-btn>
          </v-layout>
        </v-flex>

      </v-col>
      <v-col cols="12" md="8">
        <v-card-text class="mt-6">
          <h1 class="text-center display-1 teal--text text--accent-3">ورود کد اعتبار سنجی</h1>
          <h4 class="text-center mt-4">لطفا کد ارسال شده را وارد نمایید</h4>
          <v-form>
            <div class="d-flex justify-center" style="direction: ltr">

              <div v-for="(digit, index) in digits" :key="index">
                <v-text-field
                    dense
                    outlined
                    class="centered-input mx-2 mt-4"
                    v-model="digit.value"
                    maxlength="1"
                    ref="digits"
                    @input="moveToNextField(index)"
                    v-on:keydown.enter="verify">
                </v-text-field>
              </div>
            </div>

          </v-form>
          <p v-if="incompleteCode" class="error--text">کد ورود را وارد کنید</p>

        </v-card-text>
        <div class="text-center mt-3 mb-3">
          <v-btn rounded dark class="teal accent-3" @click="verify">ورود به حساب</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-window-item>
</template>

<script>
export default {
  name: "Verify",
  props: {
    step: {}
  },
  data: () => ({
    endOfTimer: false,
    digits: [
      {id: "1", value: ""},
      {id: "2", value: ""},
      {id: "3", value: ""},
      {id: "4", value: ""},
      {id: "5", value: ""},
    ],
    incompleteCode: false,
    timerInterval: null

  }),
  methods: {

    startTimer() {
      const self = this;
      let duration = 120 - 1
      let timer = duration, minutes, seconds;

      document.querySelector('#time').textContent = '02' + ":" + '00'

      this.timerInterval = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        if (--timer < 0) {
          document.querySelector('#time').textContent = 'ارسال مجدد کد'
          self.endOfTimer = true
        } else {
          self.endOfTimer = false
          document.querySelector('#time').textContent = minutes + ":" + seconds
        }

      }, 1000);
    },

    reSendCode() {
      if (this.endOfTimer) {
        this.endOfTimer = false
        this.$refs.digits[0].focus()
        this.$store.dispatch("signup").then(
            () => {
              this.startTimer()
            })
      }
    },
    changeMobileNumber() {
      this.$emit('step')
      clearInterval(this.timerInterval)
      this.digits.forEach(item => item.value = '')
    },
    moveToNextField(index) {
      const nextIndex = index + 1
      if (nextIndex < this.digits.length) {
        this.$refs.digits[nextIndex].focus()
      }

      this.incompleteCode = false
    },

    createVerificationCode() {
      this.vCode = ''
      for (let i = 0; i < this.digits.length; i++)
        this.vCode += this.digits[i].value
      return this.vCode
    },

    validation() {
      for (let i = 0; i < this.digits.length; i++)
        if (this.digits[i].value === "") return false
      return true
    },

    verify() {
      if (this.validation()) {
        this.incompleteCode = false
        this.$store.dispatch("verify", this.createVerificationCode()).then(
            () => {
              clearInterval(this.timerInterval)
            })
      } else {
        this.$refs.digits[0].focus()
        this.incompleteCode = true
      }
    },

  },

  watch: {
    endOfTimer() {
      if (this.endOfTimer) clearInterval(this.timerInterval)
    },
    step(val) {
      if (val === 2) {
        const self = this
        setTimeout(function () {
          self.$refs.digits[0].focus()
          self.startTimer()
        }, 50)

      }
    }
  },
  destroyed() {
    clearInterval(this.timerInterval)
  }
}
</script>

<style scoped>

</style>