
const state = {
    provinces : [],
    cities : [],
};

const getters = {
    getProvincesList(state) {
        return state.provinces
    },
    getCitiesList(state) {
        return state.cities
    },
};

const mutations = {
    setProvinces(state, provinces) {
        state.provinces = provinces
    },
    setCities(state, cities) {
        state.cities = cities
    },
};

const actions = {

    getProvinces({commit}) {

        this.dispatch('sendRequest', {url: 'Setting/division', method: 'get'})
            .then(response => {
                commit('setProvinces',response.result)
            })
    },

    getCities({commit}, provinceID) {

        this.dispatch('sendRequest', {url: `Setting/division?Province=${provinceID}`, method: 'get'})
            .then(response => {
                commit('setCities',response.result)
            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
