import ReqJson from "@/plugins/resource/reqJson";
import Vue from 'vue';
import {encodeQueryData} from "@/plugins/resource/queryReq";
import {constMethods} from "@/mixin/constMethods";

const state = {
    mobile: '',
    securityCode: '',
    captcha: {},
};
const getters = {
    getMobile(state) {
        return state.mobile
    },
    getSecurityCode(state) {
        return state.securityCode
    },
    getCaptcha(state) {
        return state.captcha
    },
};
const mutations = {
    setMobile(state, mobile) {
        state.mobile = mobile
    },
    setSecurityCode(state, securityCode) {
        state.securityCode = securityCode
    },
    setCaptcha(state, captcha) {
        state.captcha = captcha
    },
};
const actions = {

    captcha({getters, commit}) {

        const data = {}

        this.dispatch('sendRequest', {url: 'Login/captcha', body: new ReqJson(getters.getLoginModel, data, {}, 0, 0).reqJS(), method: 'get'})
            .then(response => {
                commit('setCaptcha', response)
            })
    },

    signup({getters}) {

        return new Promise((resolve, reject) => {

                const query = {
                    Mobile: getters.getMobile
                }

                this.dispatch('sendRequest', {url: `Login/signup${encodeQueryData(query)}`, method: 'get'})
                    .then(
                        response => {

                            if (!getters.isHideLogs && response.result.Verify) {
                                constMethods.methods.toast({type: 'success', title: response.result.Verify, timer: 6000})
                            }

                            resolve()
                        },
                        () => {
                            reject()
                        })

            },
        )

    },

    verify({getters,dispatch}, verifyCode) {

        return new Promise((resolve) => {

            const query = {
                "Mobile": getters.getMobile,
                "Verify": verifyCode
            }

            this.dispatch('sendRequest', {url: `Login/verify${encodeQueryData(query)}`, method: 'get'})
                .then(response => {

                    Vue.$cookies.set(getters.getBearer, response.result.Bearer, "7d")
                    Vue.$cookies.set(getters.getACK, response.result.ACK, "7d")

                    resolve()

                    dispatch('getProfile')
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
