<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn dark class="mr-2 primaryDark" small v-bind="attrs" v-on="on">ثبت نمره</v-btn>
      </template>

      <v-card>

        <v-card-title><h3>ثبت نمره</h3></v-card-title>

        <v-card-text>

          <v-form @submit.prevent="addScore" ref="form" class="px-3">

            <v-text-field label="نمره" v-model.trim="score" prepend-icon="mdi-folder" :rules="scoreRules"></v-text-field>

            <v-btn depressed dark class="green mx-0 mt-5 mr-4" type="submit">ثبت</v-btn>

          </v-form>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";
import {validation} from "@/mixin/validation";

export default {
  name: "AddUserExamScore",
  mixins: [
    constMethods,
    validation,
  ],
  props: {
    userExam: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      dialog: false,
      score: '',
      scoreRules: [v => !!v || 'نمره را وارد کنید', v => this.isNumber(v) || 'نمره وارد شده معتبر نیست!'],
    }
  },
  methods: {

    addScore() {
      if (this.$refs.form.validate()) {
        const data = {
          userExamID: this.userExam.ID,
          score: {
            s: this.score
          }
        }
        this.$store.dispatch('addScore', data).then(() =>{
          this.dialog = false
        })

        this.reset()
      }
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()
      this.score = ''
    }
  },
  watch: {
    dialog(n) {
      if (n) {
        this.reset()
      }
    }
  },
}
</script>

<style scoped>

</style>