<template>
  <div class="text-center d-inline">

    <v-dialog v-model="dialog" width="500" @click:outside="$emit('hide')">

      <v-data-table
          :headers="headers"
          :items="userFamily"
          :options.sync="options"
          :server-items-length="userFamily.length"
          :loading="$store.getters.isLoading"
          class="elevation-1">
      </v-data-table>

    </v-dialog>

  </div>
</template>

<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "AddCoursePopup",
  mixins: [
    constMethods
  ],
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: {},
      headers: [
        {text: 'نام', align: 'center', sortable: false, value: 'Name',},
        {text: 'نام خانوادگی', align: 'center', sortable: false, value: 'Family',},
        {text: 'همراه', align: 'center', sortable: false, value: 'Mobile',},
      ],

    }
  },
  computed: {
    userFamily() {
      return this.$store.getters.getUserFamily
    },
  },
  // watch: {
  //   options: {
  //     // handler(val) {
  //     //   this.getSystemUsers((val.page - 1) * this.$store.getters.getLimit)
  //     // },
  //   },
  // },
}
</script>

<style scoped>

</style>