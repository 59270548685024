import {encodeQueryData} from "@/plugins/resource/queryReq";

const state = {
    users: [],
    allUsersCount: 0,

    userCourses: [],
    userFamily: {}
};

const getters = {
    getUsersList(state) {
        return state.users
    },
    getAllUsersCount(state) {
        return state.allUsersCount
    },
    getUserCoursesList(state) {
        return state.userCourses
    },
    getUserFamily(state) {
        return state.userFamily
    },
};

const mutations = {
    setUsersList(state, users) {
        state.users = users
    },
    setAllUsersCount(state, allUsersCount) {
        state.allUsersCount = allUsersCount
    },
    setUserCoursesList(state, userCourses) {
        state.userCourses = userCourses
    },
    setUserFamily(state, userFamily) {
        state.userFamily = userFamily
    },
};

const actions = {

    getUsers({commit}, query) {

        if (query.index == 0) {
            commit('setAllUsersCount', 0)
            commit('setUsersList', [])
        }

        this.dispatch('sendRequest', {url: `Setting/userList${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllUsersCount', response.meta.TotalCount)
                commit('setUsersList', response.result)

            })
    },

    getUserCourses({commit}, user) {
        return new Promise(resolve => {

            this.dispatch('sendRequest', {url: `Setting/userList/${user.ID}/courses`, method: 'get'})
                .then(response => {
                    commit('setUserCoursesList', response.result)
                    resolve()
                })
        })
    },


    getUserFamily({commit}, user) {
        return new Promise(resolve => {

            this.dispatch('sendRequest', {url: `Setting/userList/${user.ID}/family`, method: 'get'})
                .then(response => {
                    console.log(response.result)
                    commit('setUserFamily', response.result)
                    resolve()
                })
        })
    }

};

export default {
    state,
    getters,
    mutations,
    actions
}
