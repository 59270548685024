<template>
  <v-row class="align-center justify-center primary fill-height ma-0">
    <v-col cols="12" md="8" lg="6" xl="4">
      <v-card class="elevation-12">
        <v-window v-model="step">
          <signup @step="step++"></signup>
          <verify @step="step--" :step="step"></verify>
        </v-window>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
import Signup from "@/components/login/parts/Signup";
import Verify from "@/components/login/parts/Verify";

export default {
  name: "login",
  components: {
    'signup': Signup,
    'verify': Verify
  },
  data: () => ({
    step: 1
  }),
  methods: {}
}
</script>

<style scoped>

</style>