<template>

  <div class="d-flex flex-column">
    <v-data-table
        :headers="headers"
        :items="usersExam"
        :options.sync="options"
        :server-items-length="$store.getters.getAllUsersExamCount"
        :loading="$store.getters.isLoading"
        class="elevation-1">

      <template v-slot:item.name="{ item }">{{ item.User.Name + ' ' + item.User.Family }}</template>
      <template v-slot:item.Score="{ item }">{{ item.Score == -1 ? '-' : item.Score }}</template>
      <template v-slot:item.Certificated="{ item }"><p class="ma-0 black--text" :class="{'red--text' : item.Score != -1 && !item.Certificated ,'green--text' : item.Score != -1 && item.Certificated }">
        {{ item.Score == -1 ? '-' : item.Certificated ? 'قبول' : 'رد' }}</p></template>

      <template v-slot:item.comment="{ item }">{{ item.SupportReview ? item.SupportReview.substring(0,20) : '-'}}</template>


      <template v-slot:item.actions="{ item }">
        <see-exam-files v-if="item.Files && item.Files.length !== 0" :userExam="item"></see-exam-files>
        <AddUserExamScore v-if="item.Score == -1" :userExam="item"></AddUserExamScore>
        <add-user-exam-comment :userExam="item"></add-user-exam-comment>
      </template>
    </v-data-table>
  </div>

</template>

<script>

import AddUserExamScore from "@/components/dashboard/sections/courses/course/sections/exams/popup/AddUserExamScore";
import AddUserExamComment from "@/components/dashboard/sections/courses/course/sections/exams/popup/AddUserExamComment";
import SeeExamFiles from "@/components/dashboard/sections/courses/course/sections/exams/popup/SeeExamFiles";

export default {
  name: "ExamResults",
  components: {
    AddUserExamScore,
    AddUserExamComment,
    SeeExamFiles,
  },
  data: () => ({
    options: {},
    headers: [
      {text: 'نام', align: 'center', sortable: false, value: 'name'},
      {text: 'سن', align: 'center', sortable: false, value: 'User.Age'},
      {text: 'شهر', align: 'center', sortable: false, value: 'User.CityName'},
      {text: 'نمره', align: 'center', sortable: false, value: 'Score'},
      {text: 'نتیجه', align: 'center', sortable: false, value: 'Certificated'},
      {text: 'نظر', align: 'center', sortable: false, value: 'comment'},
      {text: 'عملیات', align: 'center', value: 'actions', sortable: false},
    ],
  }),
  methods: {
    getUsersExam(index) {

      const query = {
        index: index,
        limit: this.$store.getters.getLimit,
      }
      this.$store.dispatch('getUsersExam', query)
    },
  },
  computed: {
    usersExam() {
      return this.$store.getters.getUsersExamList
    },
  },
  watch: {
    options: {
      handler(val) {
        this.getUsersExam((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>