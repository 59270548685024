class ReqJson {
    constructor(_object) {
        {
            this.jsonObject = {}
            this.jsonObject['reference'] = ''
            this.jsonObject.data = _object
        }
    }

    reqJS() {
        return this.jsonObject;
    }

}

export default ReqJson