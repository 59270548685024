import {encodeQueryData} from "@/plugins/resource/queryReq";
import ReqJson from "@/plugins/resource/reqJson";
import router from "@/plugins/router/router";
import {constMethods} from "@/mixin/constMethods";

const state = {
    courses: [],
    allCoursesCount: 0,
    course: {},
    courseTag: {},
};

const getters = {
    getCoursesList(state) {
        return state.courses
    },
    getAllCoursesCount(state) {
        return state.allCoursesCount
    },
    getCourse(state) {
        return state.course
    },
    getCourseTag(state) {
        return state.courseTag
    },
};

const mutations = {
    setCoursesList(state, courses) {
        state.courses = courses
    },
    setAllCoursesCount(state, allCoursesCount) {
        state.allCoursesCount = allCoursesCount
    },
    setCourse(state, course) {
        state.course = course
    },
    setCourseTag(state, courseTag) {
        state.courseTag = courseTag
    },
};

const actions = {

    getCourses({commit}, query) {

        this.dispatch('sendRequest', {url: `Courses${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllCoursesCount', response.meta.TotalCount)
                commit('setCoursesList', response.result)

            })
    },

    getSingleCourse({commit}, courseID) {
        commit('setCourse', {})

        this.dispatch('sendRequest', {url: `Courses/${courseID}`, method: 'get'})
            .then(response => {

                commit('setCourse', response.result[0])

            })
    },

    addCourse({getters, commit}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: 'Courses', body: new ReqJson(data.data).reqJS(), method: 'post'})
                .then(() => {
                    commit("setAllCoursesCount", getters.getAllCoursesCount + 1)
                    constMethods.methods.toast({type: 'success', title: 'دوره جدید اضافه شد', timer: 3000})
                    resolve()
                })
        })
    },

    editCourse({getters}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Courses/${data.courseID}`, body: new ReqJson(data.data).reqJS(), method: 'put'})
                .then((response) => {

                    const index = getters.getCoursesList.findIndex(item => item.ID === data.courseID);
                    if (index !== -1) {
                        getters.getCoursesList.splice(index, 1, response.result[0])
                    }

                    //برای لیست بایگانی دوره ها
                    const index2 = getters.getArchivedCoursesList.findIndex(item => item.ID === data.courseID);
                    if (index2 !== -1) {
                        getters.getArchivedCoursesList.splice(index2, 1, response.result[0])
                    }

                    constMethods.methods.toast({type: 'success', title: 'دوره ویرایش شد', timer: 3000})

                    resolve()
                })
        })
    },

    deleteCourse({getters, commit}, courseID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Courses/${courseID}`, method: 'delete'})
                .then(() => {
                    commit("setAllCoursesCount", getters.getAllCoursesCount - 1)
                    resolve()
                })
        })
    },

    uploadCourseCover({getters}, pic) {

        const fd = new FormData();

        fd.append('image', pic, pic.name);

        this.dispatch('sendRequest', {url: `Courses/${router.currentRoute.params.ID}/cover/file`, body: fd, method: 'post'})
            .then(response => {
                constMethods.methods.popup({type: 'success', title: '!عکس دوره بارگذاری شد', button: 1, progress: true, timer: 4000, outSideClick: false, escapeKey: false}).then(
                    () => {
                        getters.getCourse.Cover = response.result
                    })
            })
    },

    switchCourse({getters}, course) {

        this.dispatch('sendRequest', {url: `Courses/${course.ID}/active/${course.Active == 1 ? 0 : 1}`, method: 'get'})
            .then(response => {

                const index = getters.getCoursesList.findIndex(item => item.ID === course.ID);
                if (index !== -1) {
                    getters.getCoursesList.splice(index, 1, response.result[0])
                }

                constMethods.methods.toast({type: 'success', title: course.Active == 1 ? `دوره ${course.Title} غیرفعال شد` : `دوره ${course.Title} فعال شد`, timer: 2000})

            })
    },

    getCourseTag({commit}) {

        this.dispatch('sendRequest', {url: 'Courses/newTag', method: 'get'})
            .then(response => {
                commit('setCourseTag', response.result)
            })
    }


};

export default {
    state,
    getters,
    mutations,
    actions
}
