<template>
  <div>

    <Navbar></Navbar>

    <v-container>
      <transition name="m-fade" mode="out-in">
        <router-view class="mt-16"></router-view>
      </transition>
    </v-container>

  </div>

</template>

<script>

import Navbar from "@/components/dashboard/Navbar";

export default {
  name: "dashboard",
  components: {
    Navbar
  },
}
</script>

<style scoped>


</style>