<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="users"
        :options.sync="options"
        :server-items-length="$store.getters.getAllEnrolledUsersCount"
        :loading="$store.getters.isLoading"
        class="elevation-1">

      <template v-slot:item.Name="{ item }">
        <name-overflow :mText="item.Name" :width="70"></name-overflow>
      </template>

      <template v-slot:item.Family="{ item }">
        <name-overflow :mText="item.Family" :width="70"></name-overflow>
      </template>

      <template v-if="users.City" v-slot:item.City.Title="{ item }">{{ item.Title }}</template>
      <template v-slot:item.LastLogin="{ item }">{{ convertFormat(item.LastLogin, 'jYYYY/jMM/jDD - HH:mm') }}</template>
    </v-data-table>
  </div>
</template>

<script>
import {constMethods} from "@/mixin/constMethods";
import nameOverflow from "@/components/helper/nameOverflow";

export default {
  name: "Participants",
  components:{
    nameOverflow
  },
  mixins: [
    constMethods
  ],
  data() {
    return {
      options: {},
      headers: [
        {text: 'نام', align: 'center', sortable: false, value: 'Name',},
        {text: 'نام خانوادگی', align: 'center', sortable: false, value: 'Family',},
        {text: 'همراه', align: 'center', sortable: false, value: 'Mobile',},
        {text: 'شهر', align: 'center', sortable: false, value: 'City.Title',},
        {text: 'آخرین ورود', align: 'center', sortable: false, value: 'LastLogin'},
      ],
    }
  },
  methods: {
    getEnrolledUsers(index) {
      const query = {
        index,
        limit: this.$store.getters.getLimit
      }
      this.$store.dispatch('getEnrolledUsers', query)
    }
  },
  computed: {
    users() {
      return this.$store.getters.getEnrolledUsersList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getEnrolledUsers((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },

}
</script>

<style scoped>

</style>
