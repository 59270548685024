import ReqJson from "@/plugins/resource/reqJson";
import {encodeQueryData} from "@/plugins/resource/queryReq";
import {store} from "@/plugins/store/store";

const state = {
    tags: [],
    allTagsCount: 0,
    allTags: [],
    tag: {},
    tagLessonsList: [],
    allTagLessonsListCount: 0,
};

const getters = {
    getTagsList(state) {
        return state.tags
    },
    getAllTagsCount(state) {
        return state.allTagsCount
    },
    getAllTagsList(state) {
        return state.allTags
    },
    getTag(state) {
        return state.tag
    },
    getTagLessonsList(state) {
        return state.tagLessonsList
    },
    getAllTagLessonsListCount(state) {
        return state.allTagLessonsListCount
    },
};

const mutations = {
    setTagsList(state, tags) {
        state.tags = tags
    },
    setAllTagsList(state, allTags) {
        state.allTags = allTags
    },
    setAllTagsCount(state, allTagsCount) {
        state.allTagsCount = allTagsCount
    },
    setTag(state, tag) {
        state.tag = tag
    },
    setTagLessonsList(state, tagLessonsList) {
        state.tagLessonsList = tagLessonsList
    },
    setAllTagLessonsListCount(state, allTagLessonsListCount) {
        state.allTagLessonsListCount = allTagLessonsListCount
    },
};

const actions = {

    getTags({commit}, query) {

        this.dispatch('sendRequest', {url: `Tags${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                if (query.index == 0 && response.meta)
                    commit('setAllTagsCount', response.meta.TotalCount)
                commit('setTagsList', response.result)

            })
    },

    getAllTags({getters, commit, dispatch}, query) {
        if (query.index === 0)
            commit('setAllTagsList', [])

        this.dispatch('sendRequest', {url: `Tags${encodeQueryData(query)}`, method: 'get'})
            .then(response => {

                getters.getAllTagsList.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    query.index = getters.getAllTagsList.length
                    dispatch('getAllTags', query)
                }

            })
    },

    getSingleTag({commit}, tagID) {
        commit('setTag', {})
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Tags/${tagID}`, method: 'get'})
                .then(response => {
                    commit('setTag', response.result[0])
                    resolve(response.result[0])
                })
        })
    },

    addTag({getters, commit, dispatch}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: 'Tags', body: new ReqJson(data).reqJS(), method: 'post'})
                .then(() => {
                    commit("setAllTagsCount", getters.getAllTagsCount + 1)
                    resolve()

                    if (getters.getAllTagsList.length !== 0) {
                        dispatch('getAllTags', {index: 0, limit: store.getters.getLimit30,})
                    }
                })
        })
    },

    deleteTag({getters, commit}, tagID) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Tags/${tagID}`, method: 'delete'})
                .then(() => {
                    commit("setAllTagsCount", getters.getAllTagsCount - 1)
                    resolve()

                    const index2 = getters.getAllTagsList.findIndex(item => item.ID === tagID);
                    if (index2 !== -1) {
                        getters.getAllTagsList.splice(index2, 1)
                    }
                })
        })
    },

    getTagLessons({commit}, data) {

        this.dispatch('sendRequest', {url: `Tags/${data.tagID}/lessons${encodeQueryData(data.query)}`, method: 'get'})
            .then(response => {

                if (data.query.index == 0 && response.meta)
                    commit('setAllTagLessonsListCount', response.meta.TotalCount)
                commit('setTagLessonsList', response.result)

            })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
