import {encodeQueryData} from "@/plugins/resource/queryReq";
import ReqJson from "@/plugins/resource/reqJson";
import {store} from "@/plugins/store/store";

const state = {
    fileTypes: [],
    allFileTypesCount: 0,
    allFileTypes : [],
};

const getters = {
    getFileTypesList(state) {
        return state.fileTypes
    },
    getAllFileTypesCount(state) {
        return state.allFileTypesCount
    },
    getAllFileTypesList(state) {
        return state.allFileTypes
    },
};

const mutations = {
    setFileTypesList(state, fileTypes) {
        state.fileTypes = fileTypes
    },
    setAllFileTypesCount(state, allFileTypesCount) {
        state.allFileTypesCount = allFileTypesCount
    },
    setAllFileTypesList(state, allFileTypes) {
        state.allFileTypes = allFileTypes
    },
};

const actions = {

    getFileTypes({commit}, data) {
        this.dispatch('sendRequest', {url: `Examination/${data.examID}/contentFiles${encodeQueryData(data.query)}`, method: 'get'})
            .then(response => {

                if (data.query.index == 0 && response.meta)
                    commit('setAllFileTypesCount', response.meta.TotalCount)
                commit('setFileTypesList', response.result)

            })
    },

    getAllFileTypes({getters, commit, dispatch}, data) {

        this.dispatch('sendRequest', {url: `Examination/${data.examID}/contentFiles${encodeQueryData(data.query)}`, method: 'get'})
            .then(response => {
                if (data.query.index == 0)
                    commit('setAllFileTypesList', [])

                getters.getAllFileTypesList.push(...response.result)

                if (response.result.length === getters.getLimit30) {
                    data.query.index = getters.getAllFileTypesList.length
                    dispatch('getAllFileTypes', data)
                }

            })
    },

    addFileType({getters, commit, dispatch}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Examination/${data.examID}/contentFiles`, body: new ReqJson(data.data).reqJS(), method: 'post'})
                .then(() => {
                    commit("setAllFileTypesCount", getters.getAllFileTypesCount + 1)
                    resolve()

                    // if (getters.getAllFileTypesList.length !== 0) {
                        dispatch('getAllFileTypes', {examID: data.examID, query: {index: 0, limit: store.getters.getLimit30}})
                    // }
                })
        })
    },

    deleteFileType({getters, commit}, data) {
        return new Promise(resolve => {
            this.dispatch('sendRequest', {url: `Examination/${data.examID}/contentFiles/${data.questionID}`, method: 'delete'})
                .then(() => {
                    commit("setAllFileTypesCount", getters.getAllFileTypesCount - 1)
                    resolve()

                    const index2 = getters.getAllFileTypesList.findIndex(item => item.ID === data.questionID);
                    if (index2 !== -1) {
                        getters.getAllFileTypesList.splice(index2, 1)
                    }
                })
        })
    },

};

export default {
    state,
    getters,
    mutations,
    actions
}
