<template>
  <div>

    <NavbarCourse></NavbarCourse>

    <v-container>
      <transition name="m-fade" mode="out-in">
        <router-view class="mt-16"></router-view>
      </transition>
    </v-container>

  </div>

</template>

<script>

import NavbarCourse from "@/components/dashboard/sections/courses/course/NavbarCourse";

export default {
  name: "dashboard",
  components: {
    NavbarCourse
  },
  mounted() {
    //این رو به این علت گذاشتم که با ورود به صفحه دوره باید یک بار لیست کل بخش ها و کل لیست امتحانات خالی شود
    this.$store.commit('setAllSectionsList', [])
    this.$store.commit('setAllExaminationsList', [])

    //همین طور کل لیست های بخش های اصلی دوره باید یک دور خالی شوند
    this.$store.commit('setSectionsList', [])
    this.$store.commit('setLessonsList', [])
    this.$store.commit('setExaminationsList', [])
    this.$store.commit('setEnrolledUsersList', [])
    this.$store.commit('setSupportsList', [])

    this.$store.dispatch('getSingleCourse', this.$route.params.ID)
  },
}
</script>

<style scoped>

</style>