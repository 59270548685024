<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!obj" color="green" dark v-bind="attrs" :block="$vuetify.breakpoint.xsOnly" v-on="on">ایجاد پشتیبان جدید</v-btn>
        <v-btn v-else dark class="orange" small v-on="on">ویرایش</v-btn>
      </template>

      <v-card>

        <v-card-title v-if="!obj"><h3>ایجاد پشتیبان جدید</h3></v-card-title>
        <v-card-title v-else><h3>ویرایش پشتیبان</h3></v-card-title>

        <v-card-text>
          <v-form @submit.prevent="submit" ref="form" class="px-3">

            <v-text-field label="نام پشتیبان" v-model="support.Name" prepend-icon="mdi-folder" :rules="rules.nameRules"></v-text-field>

            <v-text-field label="نام خانوادگی" v-model="support.Family" prepend-icon="mdi-folder" :rules="rules.familyRules"></v-text-field>

            <v-text-field label="همراه" v-model="support.Mobile" prepend-icon="mdi-folder" :rules="rules.mobileRules" maxlength="11"></v-text-field>

            <v-btn depressed dark class="green mx-0 mt-3" type="submit">تایید</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";
import {validation} from "@/mixin/validation";

export default {
  name: "AddSupportPopup",
  mixins: [
    constMethods,
    validation
  ],
  props: {
    obj: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      support: this.obj ? Object.assign({}, this.obj) : {},
      dialog: false,
      rules: {},
    }
  },
  methods: {
    submit() {

      if (this.$refs.form.validate()) {
        // this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
        //     () => {
              const data = {
                "data": {
                  "Name": this.support.Name,
                  "Family": this.support.Family,
                  "Mobile": this.support.Mobile,
                },
              }

              if (!this.obj) {//add new support
                this.$store.dispatch('addSupport', data).then(() => {
                  this.$emit('getList')
                })
              } else {//edit support
                data['supportID'] = this.obj.ID
                this.$store.dispatch('editSupport', data)
              }

              this.dialog = false
            // })

      }
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      this.support = this.obj ? Object.assign({}, this.obj) : {}
    }
  },
  mounted() {
    this.rules = {
      nameRules: [v => !!v || 'نام پشتیبان را وارد کنید'],
      familyRules: [v => !!v || 'نام خانوادگی پشتیبان را وارد کنید'],
      mobileRules: [v => !!v || 'شماره همراه را وارد کنید', v => this.validMobile(v) || 'شماره همراه معتبر نیست!'],
    }
  },
  watch: {
    dialog() {
      this.reset()
    }
  },
}
</script>

<style scoped>

</style>