<template>
  <div>
    <h1 class="subtitle-1 grey--text pa-2">برچسب: {{ tag.Title }}</h1>
    <v-container>

      <v-data-table
          :headers="headers"
          :items="tagLessons"
          :options.sync="options"
          :server-items-length="$store.getters.getAllTagLessonsListCount"
          :loading="$store.getters.isLoading"
          class="elevation-1">

        <template v-slot:item.actions="{ item }">
          <v-btn dark class="red" small @click="deleteLesson(item)">حذف</v-btn>
        </template>
      </v-data-table>

    </v-container>

  </div>
</template>

<script>
import {constMethods} from "@/mixin/constMethods";

export default {
  name: "TagDetail",
  mixins: [
    constMethods
  ],
  data() {
    return {
      pageIndex: 0,
      options: {},
      headers: [
        {text: 'نام درس', align: 'center', sortable: false, value: 'Title',},
        {text: 'عملیات', align: 'center', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getTagLessons(index) {
      this.pageIndex = index

      const data = {
        tagID: this.$route.query.ID,
        query: {
          index,
          limit: this.$store.getters.getLimit
        }
      }
      this.$store.dispatch('getTagLessons', data)
    },
    deleteLesson(item) {
      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {
            const data = {
              lessonID: item.ID,
              tagID: this.$route.query.ID
            }
            this.$store.dispatch('deleteLessonTag', data).then(
                () => {
                  this.getTagLessons(this.calculateIndex(this.$store.getters.getAllTagLessonsListCount, false, this.pageIndex))
                })
          })
    }
  },
  mounted() {
    this.$store.dispatch('getSingleTag', this.$route.query.ID)
  },
  computed: {
    tag() {
      return this.$store.getters.getTag
    },
    tagLessons() {
      return this.$store.getters.getTagLessonsList
    },
  },
  watch: {
    options: {
      handler(val) {
        this.getTagLessons((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>