var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"server-items-length":_vm.$store.getters.getAllEnrolledUsersCount,"loading":_vm.$store.getters.isLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('name-overflow',{attrs:{"mText":item.Name,"width":70}})]}},{key:"item.Family",fn:function(ref){
var item = ref.item;
return [_c('name-overflow',{attrs:{"mText":item.Family,"width":70}})]}},(_vm.users.City)?{key:"item.City.Title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.Title))]}}:null,{key:"item.LastLogin",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.convertFormat(item.LastLogin, 'jYYYY/jMM/jDD - HH:mm')))]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }