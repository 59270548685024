<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      scrollable
      persistent
      transition="dialog-bottom-transition">

    <template v-slot:activator="{ on, attrs }">
      <v-btn dark class="mr-2 primaryLight" small v-bind="attrs" v-on:click="getAllContents()" v-on="on">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="white--text" v-bind="attrs" v-on="on">mdi-layers-edit</v-icon>
          </template>
          <span>مدیریت محتوا</span>
        </v-tooltip>
      </v-btn>
    </template>

    <v-card>

      <v-toolbar dark color="primary" style="max-height: 64px">
        <v-toolbar-title><strong>مدیریت محتوا :</strong> {{ lesson.Title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="onCancelRequest">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>


      <v-card-text class="pa-0">
        <v-row class="ma-0">

          <v-col cols="12" md="3">
            <div>

              <v-subheader class="pr-0">افزودن محتوا جدید</v-subheader>

              <v-form @submit.prevent="submit" ref="form">

                <div>نوع محتوا:</div>

                <v-radio-group :rules="rules.contentTypeRules" v-model="contentType" row>
                  <v-radio v-for="(item , index) in contentTypes" :label="item.TitleFa" v-bind:value="item" v-bind:key="index"></v-radio>
                </v-radio-group>


                <v-text-field v-if="contentType" v-model="title" :rules="rules.titleRules" dense outlined label="عنوان" prepend-icon="mdi-pencil-plus"></v-text-field>


                <div v-if="contentType && contentType.Title !== 'Text'">


                  <v-file-input v-model="file" outlined dense :rules="rules.fileRules"
                                :prepend-icon="contentType.Title === 'Audio' ? 'mdi-cast-audio' : contentType.Title === 'Pic' ? 'mdi-file-image' : contentType.Title === 'Video' ? 'mdi-message-video' : null"
                                :accept="contentType.Title === 'Audio' ? 'audio/*' : contentType.Title === 'Pic' ? 'image/*' : contentType.Title === 'Video' ? 'video/*' : null"
                                :label="contentType.Title === 'Audio' ? 'افزودن صدا' : contentType.Title === 'Pic' ? 'افزودن تصویر' : contentType.Title === 'Video' ? 'افزودن ویدیو' : null"
                  ></v-file-input>

                  <v-text-field v-model="description" dense outlined label="توضیحات" prepend-icon="mdi-pencil-plus"></v-text-field>
                </div>

                <div v-else-if="contentType && contentType.Title === 'Text'" class="align-self-center">
                  <v-textarea v-model="description" :rules="rules.descriptionRules" dense outlined rows="3" label="متن" prepend-icon="mdi-pencil-plus"></v-textarea>
                </div>

                <!--                <v-checkbox class="ma-0" hide-details="auto" v-model="privateContent" :label="'نمایش محتوا به صورت خصوصی'"></v-checkbox>-->

                <v-btn dark class="green mt-5" type="submit">ثبت محتوا</v-btn>

              </v-form>

            </div>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col cols="12" md="9" class="overflow-y-auto pa-0">

            <v-card-text class="pa-0">
              <div v-for="(n,i) in allContents" v-bind:key="i" class="d-flex flex-column ">

                <div class="d-flex justify-end">
                  <v-icon v-if="i+1 !== allContents.length" style="cursor: pointer" class="ml-2 mt-2" @click="onReorderContent(n,1)">mdi-arrow-down</v-icon>
                  <v-icon v-if="i !== 0" style="cursor: pointer" class="ml-2 mt-2" @click="onReorderContent(n,2)">mdi-arrow-up</v-icon>
                  <v-icon style="cursor: pointer" class="ml-2 mt-2" @click="onEditContent(n)">mdi-pencil</v-icon>
                  <v-icon style="cursor: pointer" class="red--text ml-2 mt-2" @click="onDeleteContent(n)">mdi-close-circle</v-icon>
                </div>

                <m-text v-if="n.ContentType.Title === 'Text'" :content="n" class="pa-4 pt-0"></m-text>

                <m-pic v-else-if="n.ContentType.Title === 'Pic'" :content="n" class="pa-4 pt-0"></m-pic>

                <m-audio v-else-if="n.ContentType.Title === 'Audio'" :content="n" class="pa-4 pt-0" :closePopup="dialog"></m-audio>

                <m-video v-else-if="n.ContentType.Title === 'Video'" :content="n" class="pa-4 pt-0" :closePopup="dialog"></m-video>

                <v-divider></v-divider>

              </div>
            </v-card-text>

          </v-col>

        </v-row>

        <edit-content-dialog :dialog="editDialog" :obj="content" @hide="editDialog = false"></edit-content-dialog>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import {constMethods} from "@/mixin/constMethods";
import mVideo from "@/components/dashboard/sections/courses/course/sections/lessons/manageContent/sections/MVideo";
import mAudio from "@/components/dashboard/sections/courses/course/sections/lessons/manageContent/sections/MAudio";
import mPic from "@/components/dashboard/sections/courses/course/sections/lessons/manageContent/sections/MPic";
import mText from "@/components/dashboard/sections/courses/course/sections/lessons/manageContent/sections/MText";
import EditContentDialog from "@/components/dashboard/sections/courses/course/sections/lessons/manageContent/EditContentDialog";
import {mapGetters} from "vuex";

export default {
  name: "MangeContentPopup",
  components: {
    mVideo,
    mAudio,
    mPic,
    mText,
    EditContentDialog,
  },
  mixins: [
    constMethods
  ],
  props: {
    lesson: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      dialog: false,
      contentType: null,
      privateContent: null,
      title: null,
      description: null,
      file: null,
      rules: [],
      content: null,
      editDialog: false,
    }
  },
  methods: {
    getAllContents() {
      if (this.$store.getters.getContentTypesList.length === 0)
        this.$store.dispatch('getContentTypes', {index: 0, limit: this.$store.getters.getLimit30})

      const data = {
        lessonID: this.lesson.ID,
        query: {
          index: 0,
          limit: this.$store.getters.getLimit
        }
      }
      this.$store.dispatch('getAllContents', data)
    },
    onCancelRequest() {
      this.$store.dispatch('cancelRequest')
      this.dialog = false
    },
    async submit() {

      if (this.$refs.form.validate()) {

        const data = {
          lessonID: this.lesson.ID,
          data: {
            "Title": this.title,
            "ContentTypeID": this.contentType.ID,
            "Text": this.description ? this.description : '',
            "FileName": '',
          },
          file: this.file,
          contentType: this.contentType.Title,
        }

        let newID = await this.$store.dispatch('addContent', data)
        this.$store.commit('setNewContentID', newID)
      }
    },
    onDeleteContent(content) {

      this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
          () => {
            this.$store.dispatch('deleteContent', content.ID)
          })
    },
    onReorderContent(content, reorderType) {
      const data = {
        contentID: content.ID,
        reorderType
      }
      this.$store.dispatch('reorderContent', data)
    },
    onEditContent(content) {
      this.content = content
      this.editDialog = true
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      this.contentType = null
      this.privateContent = null

      this.title = null
      this.description = null
      this.file = null
    },
  },
  mounted() {
    this.rules = {
      contentTypeRules: [v => !!v || 'نوع محتوا را انتخاب کنید',],
      titleRules: [v => !!v || 'عنوان را وارد کنید'],
      descriptionRules: [v => !!v && this.contentType.Title === 'Text' || 'متن را وارد کنید'],
      fileRules: [v => !!v && this.contentType.Title !== 'Text' || 'فایل را انتخاب کنید'],
    }
  },
  computed: {
    ...mapGetters({
      contentTypes: 'getContentTypesList',
      allContents: 'getAllContentsList',
      cancelRequest: 'isCancelCurrentRequest',
    }),
  },
  watch: {
    dialog() {
      this.reset()

    },
    contentType() {
      this.$refs.form.resetValidation()
      this.file = null
      this.title = null
      this.description = null
    },
    cancelRequest: {
      handler(n) {
        if (n && this.$store.getters.getNewContentID !== 0) {
          this.$store.dispatch('deleteContent', this.$store.getters.getNewContentID)
          this.$store.commit('setNewContentID', 0)
        }
      }
    }
  },
}
</script>

<style scoped>

</style>
