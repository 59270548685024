<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn color="green" dark v-bind="attrs" v-on="on">ایجاد برچسب جدید</v-btn>
      </template>

      <v-card>

        <v-card-title><h3>ایجاد برچسب جدید</h3></v-card-title>

        <v-card-text>
          <v-form @submit.prevent="submit" ref="form" class="px-3">

            <v-text-field label="عنوان برچسب" v-model.trim="tagTitle" prepend-icon="mdi-folder" :rules="rules.tagTitleRules"></v-text-field>

            <v-btn depressed dark class="green mx-0 mt-3" type="submit">تایید</v-btn>
          </v-form>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "AddTag",
  mixins: [
    constMethods
  ],
  data() {
    return {
      dialog: false,
      tagTitle: '',
      rules: {},
    }
  },
  methods: {
    submit() {

      if (this.$refs.form.validate()) {
        // this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
        //     () => {
              const data = {
                Title: this.tagTitle
              }

              this.$store.dispatch('addTag', data).then(() => {
                this.$emit('getList')
              })

              this.dialog = false
            // })

      }
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      this.tagTitle = ''
    }
  },
  mounted() {
    this.rules = {
      tagTitleRules: [v => !!v || 'عنوان برچسب را وارد کنید'],
    }
  },
  watch: {
    dialog() {
      this.reset()
    }
  },
}
</script>

<style scoped>

</style>