<template>
  <nav>
    <v-snackbar v-model="snackbar" :timeout="4000" bottom color="success">پروژه جدید اضافه شد!</v-snackbar>


    <v-app-bar class="elevation-3" app>
      <v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="text-uppercase grey--text d-flex pa-0">
        <v-divider vertical class="mx-2 my-auto" style="height: 30px"></v-divider>
        <v-icon v-if="showBackBtn($route)" style="cursor: pointer" @click="$router.go(-1)">mdi-arrow-right</v-icon>
        <v-divider v-if="showBackBtn($route)" vertical class="mx-2 my-auto" style="height: 30px"></v-divider>
        <h1 class="subtitle-1 grey--text pa-2">{{ setHeaderTitle($route) }}</h1>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="text-center">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="grey" text v-bind="attrs" v-on="on">
              <v-icon>mdi-chevron-down</v-icon>
              <span>منو</span>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, index) in links" :key="index" :to="{name:item.route}">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-btn text color="pink" @click="logout">
        <span>خروج</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" right class="primary" app>

      <span class="font-weight-bold text-center d-block white--text mt-10">سامانه مدیریت ادیب عشق</span>

      <v-layout column align-center>
        <v-flex class="mt-5">
          <v-avatar size="80" class="grey lighten-2">
            <!--            <img src="../assets/avatar-1.png" alt="a">-->
            <img v-if="profile.Avatar" :src="$store.getters.getImageServerURL + profile.Avatar" alt="a">

            <v-icon v-else class="white--text">mdi-account</v-icon>
          </v-avatar>
          <p class="white--text subtitle-2 mt-1 text-center">{{ profile.Name + ' ' + profile.Family }}</p>
        </v-flex>
      </v-layout>

      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item v-for="(link,index) in links" :key="index" :to="{name : link.route}">
            <v-list-item-icon>
              <v-icon class="white--text">{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "Navbar",
  components: {},
  mixins: [
    constMethods
  ],
  data() {
    return {
      rn: this.$store.state.rn,
      drawer: true,
      items: [
        {icon: 'mdi-book-open-outline', text: 'دوره ها', route: this.$store.state.rn.courses},
        {icon: 'mdi-archive', text: 'بایگانی دوره ها', route: this.$store.state.rn.archivedCourses},
        {icon: 'mdi-notebook-edit', text: 'دورهای پیش نویس', route: this.$store.state.rn.draftCourses},
        {icon: 'mdi-account-group', text: 'کاربران سیستم', route: this.$store.state.rn.systemUsers},
        {icon: 'mdi-cogs', text: 'تنظیمات پایه', route: this.$store.state.rn.basicSettings},
        {icon: 'mdi-account', text: 'حساب کاربری', route: this.$store.state.rn.profile},
      ],
      snackbar: false
    }
  },
  methods: {
    setHeaderTitle(route) {
      let n = route.name
      let s = this.$store.state.rn
      switch (n) {
        case s.courses:
          return 'دوره ها'
        case s.archivedCourses:
          return 'بایگانی دوره ها'
        case s.draftCourses:
          return 'دوره های پیش نویس'
        case s.systemUsers:
          return 'کاربران سیستم'
        case s.profile:
          return 'حساب کاربری'
        case s.basicSettings:
          return 'تنظیمات پایه'
        case s.manageTags:
          return 'تنظیمات پایه / مدیریت برچسب ها'
        case s.tagDetail:
          return 'تنظیمات پایه / مدیریت برچسب ها / جزییات'
        case s.manageAgeCategories:
          return 'تنظیمات پایه / رده سنی'
        case s.manageCategories:
          return 'تنظیمات پایه / دسته بندی'
        case s.manageBanners:
          return 'تنظیمات پایه / بنر'
      }
    },
    showBackBtn(route) {
      let n = route.name
      let s = this.$store.state.rn
      return n !== s.courses && n !== s.archivedCourses && n !== s.draftCourses && n !== s.systemUsers && n !== s.profile && n !== s.basicSettings;
    },
    logout() {
      this.popup({type: 'question', title: 'آیا از خروج اطمینان دارید؟', button: 2}).then(
          () => {
            this.logoutFromApp()
          })
    },
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile
    },
    // state.tasks = state.tasks.filter(task => task.id !== id)
    links() {
      return this.items.filter(item => {
        return (item.route === this.rn.courses && this.checkAccessList({property: 'Prefix', value: 'courses'}))
            || (item.route === this.rn.archivedCourses && this.checkAccessList({property: 'Prefix', value: 'courses'}))
            || (item.route === this.rn.draftCourses && this.checkAccessList({property: 'Prefix', value: 'courses'}))
            || (item.route === this.rn.systemUsers && this.checkAccessList({property: 'Prefix', value: 'setting'}))
            || (item.route === this.rn.basicSettings && this.checkAccessList({property: 'Prefix', value: 'basic'}))
            || item.route === this.rn.profile
      })
    }
  },
}
</script>

<style scoped>

</style>