const rn = {
    namespaced: true,

    state: {
        login: 'login',
        courses: 'courses',
        archivedCourses: 'archivedCourses',
        draftCourses: 'draftCourses',
        systemUsers: 'systemUsers',
        profile: 'profile',
        basicSettings: 'basicSettings',

        manageTags: 'manageTags',
        tagDetail: 'tagDetail',
        manageAgeCategories: 'manageAgeCategories',
        manageCategories: 'manageCategories',
        manageBanners: 'manageBanners',

        sections: 'sections',
        lessons: 'lessons',
        sectionLessons: 'sectionLessons',
        sectionExaminations: 'sectionExaminations',
        manageContent: 'manageContent',
        exams: 'exams',
        examsResults: 'examsResults',
        exercise: 'exercise',
        participants: 'participants',
        supports: 'supports',
    },
}

export default rn;


