<template>
  <div class="d-flex flex-column align-center">
    <p class="mb-0 font-weight-bold mb-4">{{ content.Title }}</p>
    <p class="mb-0">{{ content.Text }}</p>
  </div>
</template>

<script>
export default {
  name: "MText",
  props: {
    content: {
      type: Object,
      default: () => {
      }
    }
  },
}
</script>

<style scoped>

</style>