<template>
  <div class="d-flex flex-column align-center">
    <p class="mb-0 font-weight-bold mb-4">{{ content.Title }}</p>
    <v-responsive :aspect-ratio="16" width="50%">
      <vue-plyr  id="player" ref="plyr">
        <audio controls crossorigin playsinline>
          <source :src="$store.getters.getImageServerURL + content.FileName" type="audio/mp3"/>
          <source :src="$store.getters.getImageServerURL + content.FileName" type="audio/ogg"/>
        </audio>
      </vue-plyr>
    </v-responsive>
    <p class="mb-0">{{ content.Text }}</p>
  </div>
</template>

<script>
export default {
  name: "MAudio",
  props: {
    content: {
      type: Object,
      default: () => {
      }
    },
    closePopup: {
      type: Boolean,
      default: false
    },
  },
  methods: {
  },
  mounted() {
    // if (this.$refs.plyr)
    //   console.log('==> ', this.$refs.plyr.player)
  },
  watch: {
    closePopup(val) {
      if (!val) {
        if (this.$refs.plyr)
          this.$refs.plyr.player.destroy()
      }
    }
  }
}
</script>

<style scoped>

</style>