<template>

  <div class="d-flex flex-column">

    <add-section-course-popup v-if="checkAccessList({property: 'Name', value: 'addSection'})"
        @getList="getSections(calculateIndex($store.getters.getAllSectionsCount, true, pageIndex))"
        class="align-self-end mb-4">
    </add-section-course-popup>

    <v-data-table
        :headers="headers"
        :items="sections"
        :options.sync="options"
        :server-items-length="$store.getters.getAllSectionsCount"
        :loading="$store.getters.isLoading"
        class="elevation-1">


      <template v-slot:item.Title="{ item }">
        <name-overflow :mText="item.Title" :width="70"></name-overflow>
      </template>

      <template v-slot:item.Description="{ item }">
        <name-overflow :mText="item.Description" :width="240"></name-overflow>
      </template>


      <template v-slot:item.actions="{ item }">
        <add-section-course-popup v-if="checkAccessList({property: 'Name', value: 'editSection'})" :obj="item"></add-section-course-popup>
<!--        <v-btn dark class="mr-2" color="grey" @click="onSectionLessons(item)" small>دروس</v-btn>-->
<!--        <v-btn dark class="mr-2" color="grey" @click="onSectionExaminations(item)" small>امتحانات</v-btn>-->

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark class="mr-2 red" v-bind="attrs" v-on="on" small @click="deleteSection(item)" v-if="checkAccessList({property: 'Name', value: 'deleteSection'})"><v-icon class="white--text">mdi-delete</v-icon></v-btn>
          </template>
          <span>حذف</span>
        </v-tooltip>

      </template>
    </v-data-table>

  </div>

</template>

<script>

import AddSectionCoursePopup from "@/components/dashboard/sections/courses/course/sections/sections/AddSectionCoursePopup";
import {constMethods} from "@/mixin/constMethods";
import nameOverflow from "@/components/helper/nameOverflow";

export default {
  name: "Sections",
  mixins: [
    constMethods
  ],
  components: {
    'addSectionCoursePopup': AddSectionCoursePopup,
    nameOverflow,
  },
  data: () => ({
    pageIndex: 0,
    options: {},
    headers: [
      {text: 'نام بخش', align: 'center', sortable: false, value: 'Title',},
      {text: 'توضیحات', align: 'center', sortable: false, value: 'Description'},
      {text: 'عملیات', align: 'center', value: 'actions', sortable: false},
    ],
  }),
  methods: {

    getSections(index) {
      this.pageIndex = index

      const query = {
        index: index,
        limit: this.$store.getters.getLimit,
      }
      this.$store.dispatch('getSections', query)
    },
    onSectionLessons(item) {
      this.$router.push({name: this.$store.state.rn.sectionLessons, params: {sectionID: item.ID}, query: {name: item.Title}})
    },
    onSectionExaminations(item) {
      this.$router.push({name: this.$store.state.rn.sectionExaminations, params: {sectionID: item.ID}, query: {name: item.Title}})
    },
    deleteSection(item) {
      this.popup({type: 'question', title: 'با حذف یک بخش تمام دروس این بخش نیز حذف خواهند شد‌‌, آیا اطمینان دارید؟', button: 2}).then(
          () => {
            this.$store.dispatch('deleteSection', item.ID).then(
                () => {
                  this.getSections(this.calculateIndex(this.$store.getters.getAllSectionsCount, false, this.pageIndex))
                })
          })
    }
  },
  computed: {
    sections() {
      return this.$store.getters.getSectionsList
    }
  },
  watch: {
    options: {
      handler(val) {
        this.getSections((val.page - 1) * this.$store.getters.getLimit)
      },
    },
  },
}
</script>

<style scoped>

</style>
