<template>
  <v-window-item :value="1">
    <v-row>
      <v-col cols="12" sm="8" order="2" order-sm="1">
        <v-card-text class="mt-6">
          <h1 class="text-center display-1 teal--text text--accent-3">ورود به سیستم</h1>
          <h4 class="text-center mt-4">شماره همراه خود را وارد کنید</h4>
          <v-text-field class="mt-4" dense label="همراه" :rules="mobileRules" outlined  v-on:keydown.enter="signup" id="email" v-model="mobile" ref="mobileNumber" prepend-icon="mdi-phone" type="text" maxlength="11" color="teal accent-3"></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center mb-3">
          <v-btn rounded dark class="teal accent-3" v-on:click="signup">دریافت کد ورود</v-btn>
        </v-card-actions>
      </v-col>
      <v-col class="teal accent-3" cols="12" sm="4" order="1" order-sm="2">
        <v-card-text class="white--text mt-12">
          <h1 class="text-center subtitle-1">سامانه مدیریت ادیب عشق</h1>
        </v-card-text>
      </v-col>
    </v-row>
  </v-window-item>
</template>

<script>

import {constMethods} from '@/mixin/constMethods'
import {validation} from "@/mixin/validation";

export default {
  name: "Signup",
  data: () => ({
    mobile: '',
    securityCode: '',
    invalidSecurityCode: false,
    mobileRules: []
  }),
  mixins: [
    constMethods,
    validation
  ],
  methods: {
    signup() {
      if (this.$refs.mobileNumber.validate()) {

        this.$store.commit('setMobile', this.mobile)
        // this.$store.commit('setSecurityCode', this.securityCode)

        this.$store.dispatch("signup").then(
            () => {
              this.$emit('step')
            },
            () => {
              // this.invalidSecurityCode = true
              // this.getCaptcha()
            }
        )
      } else {
        this.$refs.mobileNumber.focus()
      }
    },
    getCaptcha() {
      this.$store.dispatch("captcha")
    }
  },
  mounted() {
    this.$refs.mobileNumber.focus()
    // this.getCaptcha()
    this.mobileRules.push(
        v => !!v || 'شماره همراه را وارد کنید',
        v => this.validMobile(v) || 'شماره همراه معتبر نیست!',)
  },
  watch: {
    securityCode: function () {
      this.invalidSecurityCode = false
    }
  }
}
</script>

<style scoped>

</style>