<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!obj" color="green" dark v-bind="attrs" v-on="on">ایجاد دسته بندی جدید</v-btn>
        <v-btn v-else small dark class="orange" v-on="on">ویرایش</v-btn>
      </template>
      <v-card>

        <v-card-title v-if="!obj"><h3>ایجاد دسته بندی جدید</h3></v-card-title>
        <v-card-title v-else><h3>ویرایش دسته بندی</h3></v-card-title>

        <v-card-text>
          <v-form @submit.prevent="submit" ref="form" class="px-3">
            <v-text-field label="عنوان دسته بندی" v-model.trim="category.Title" prepend-icon="mdi-folder" :rules="rules.categoryTitleRules"></v-text-field>

            <v-checkbox hide-details="auto" class="mt-0 mr-2" v-model="category.Private" :label="'ثبت به صورت خصوصی'"></v-checkbox>

            <v-btn depressed dark class="green mx-0 mt-3" type="submit">تایید</v-btn>
          </v-form>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";
import {validation} from "@/mixin/validation";

export default {
  name: "AddCategory",
  props: {
    obj: {
      type: Object,
      default: () => {

      }
    }
  },
  mixins: [
    constMethods,
    validation
  ],
  data() {
    return {
      category: this.obj ? Object.assign({}, this.obj) : {},
      dialog: false,
      rules: {},
    }
  },
  methods: {
    submit() {

      if (this.$refs.form.validate()) {
        // this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
        //     () => {

              const data = {
                data: {
                  "Title": this.category.Title,
                  "Private": this.category.Private ? 1 : 0,
                }
              }

              if (!this.obj) {//add new category
                this.$store.dispatch('addCategory', data).then(
                    () => {
                      this.$emit('getList')
                    })
              } else {//edit category
                data['categoryID'] = this.obj.ID
                this.$store.dispatch('editCategory', data)
              }

              this.dialog = false
            // })

      }
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      this.category = this.obj ? Object.assign({}, this.obj) : {}
    }
  },
  mounted() {
    this.rules = {
      categoryTitleRules: [v => !!v || 'عنوان دسته بندی را وارد کنید'],
    }
  },
  watch: {
    dialog() {
      this.reset()
    }
  },
}
</script>

<style scoped>

</style>