<template>
  <div class="text-center d-inline">
    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!obj" color="green" dark v-bind="attrs" v-on="on" v-on:click="getAllExaminations" :block="$vuetify.breakpoint.xsOnly">ایجاد امتحان جدید</v-btn>
        <v-btn v-else dark class="orange" small v-on="on" v-on:click="getAllExaminations">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }"><v-icon class="white--text" v-bind="attrs" v-on="on">mdi-pencil</v-icon></template>
            <span>ویرایش</span>
          </v-tooltip>
        </v-btn>

      </template>

      <v-card>

        <v-card-title v-if="!obj"><h3>ایجاد امتحان جدید</h3></v-card-title>
        <v-card-title v-else><h3>ویرایش امتحان</h3></v-card-title>

        <v-card-text>
          <v-form @submit.prevent="submit" ref="form" class="px-3">

            <v-text-field label="عنوان امتحان" v-model="examination.Title" prepend-icon="mdi-folder" :rules="rules.examinationTitleRules"></v-text-field>

            <v-select
                v-model="examination.Section.ID"
                :items="allSections"
                :rules="rules.sectionRules"
                label="بخش"
                item-text="Title"
                item-value="ID"
                :menu-props="{ auto: true, overflowY: true}"
                prepend-icon="mdi-map"
            ></v-select>

            <v-select
                v-model="examination.ExamType.ID"
                :items="allExaminationTypes"
                :rules="rules.examinationTypeRules"
                label="نوع امتحان"
                item-text="TitleFa"
                :menu-props="{ auto: true, overflowY: true}"
                item-value="ID"
                prepend-icon="mdi-map"
            ></v-select>

            <!--            :rules="rules.prerequisitesRules"-->
            <v-select
                v-model="examination.PreExam.ID"
                :items="allExaminations"
                label="پیشنیاز ندارد"
                :menu-props="{ auto: true, overflowY: true, closeOnContentClick: true }"
                item-text="Title"
                item-value="ID"
                prepend-icon="mdi-map">

              <template v-slot:prepend-item>
                <v-list-item @click="examination.PreExam.ID = 0">
                  <v-list-item-content>
                    <v-list-item-title>پیشنیاز ندارد</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>

            </v-select>

            <v-textarea rows="2" label="توضیحات امتحان" v-model="examination.Description" prepend-icon="mdi-folder" :rules="rules.examinationDescriptionRules"></v-textarea>

            <v-btn depressed dark class="green mx-0 mt-3" type="submit">تایید</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import {constMethods} from "@/mixin/constMethods";

export default {
  name: "AddCoursePopup",
  mixins: [
    constMethods
  ],
  props: {
    obj: {
      type: Object,
      default: () => {

      }
    }
  },
  data() {
    return {
      examination: this.obj ? Object.assign({}, this.obj) : {Section: {}, ExamType: {}, PreExam: {}},
      dialog: false,
      rules: {},
    }
  },
  methods: {
    submit() {

      if (this.$refs.form.validate()) {
        // this.popup({type: 'question', title: 'آیا اطمینان دارید؟', button: 2}).then(
        //     () => {
              const data = {
                "data": {
                  "Title": this.examination.Title,
                  "Description": this.examination.Description,
                  "CourseID": parseInt(this.$route.params.ID),
                  "SectionID": parseInt(this.examination.Section.ID),
                  "ExamTypeID": parseInt(this.examination.ExamType.ID),
                  "PreExamID": this.examination.PreExam.ID ? parseInt(this.examination.PreExam.ID) : 0,
                },
              }

              if (!this.obj) {//add new examination
                this.$store.dispatch('addExamination', data).then(() => {
                  this.$emit('getList')
                })
              } else {//edit examination
                data['examinationID'] = this.obj.ID
                this.$store.dispatch('editExamination', data)
              }

              this.dialog = false
            // })

      }
    },
    getAllExaminations() {
      if (this.$store.getters.getAllExaminationsList.length === 0)
        this.$store.dispatch('getAllExaminations', {index: 0, limit: this.$store.getters.getLimit30})
      if (this.$store.getters.getExaminationTypesList.length === 0)
        this.$store.dispatch('getExaminationTypes', {index: 0, limit: this.$store.getters.getLimit30})
    },
    reset() {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      this.examination = this.obj ? Object.assign({}, this.obj) : {Section: {}, ExamType: {}, PreExam: {}}
    }
  },
  mounted() {
    this.rules = {
      examinationTitleRules: [v => !!v || 'عنوان امتحان را وارد کنید', v => (v && v.length >= 5) || 'عنوان امتحان نباید کمتر از 5 حرف باشد',],
      examinationDescriptionRules: [v => !!v || 'توضیحات امتحان را وارد کنید', v => (v && v.length >= 5) || 'توضیحات امتحان نباید کمتر از 5 حرف باشد',],
      sectionRules: [v => !!v || 'بخش را انتخاب کنید',],
      examinationTypeRules: [v => !!v || 'نوع امتحان را انتخاب کنید',],
      prerequisitesRules: [v => !!v || 'پیش نیاز را انتخاب کنید',],
    }
  },
  computed: {
    allSections() {
      return this.$store.getters.getAllSectionsList
    },
    allExaminationTypes() {
      return this.$store.getters.getExaminationTypesList
    },
    allExaminations() {
      // array.unshift()
      // array.splice(index, 0, item)
      return this.$store.getters.getAllExaminationsList
    },
  },
  watch: {
    dialog() {
      this.reset()
    }
  },
}
</script>

<style scoped>

</style>