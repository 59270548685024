<template>
  <v-app>

    <loading></loading>
    <v-layout class="grey lighten-3" style="min-height: 100vh">
      <v-main>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-main>
    </v-layout>

  </v-app>
</template>

<script>


import Loading from "@/components/helper/Loading";

export default {
  name: 'App',
  components: {
    Loading
  },
  beforeMount() {
    if (!this.$cookies.get(this.$store.getters.getACK)) {
      if (!this.$router.currentRoute.name)
        this.$router.replace({name: this.$store.state.rn.login});
    } else {
      this.$store.dispatch('getProfile')
    }
  }
};
</script>

<style lang="scss">

@import "~@/assets/sass/variables";
@import '~vuetify/src/styles/styles.sass';


@media only screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .container {
    max-width: 1500px !important;
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    .container {
      max-width: 1500px !important;
    }
}

@media only screen and (min-width: map-get($grid-breakpoints, 'xl')) {
  //.container {
  //  max-width: 1440px !important;
  //}
}
</style>