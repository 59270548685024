import Vue from "vue";

const state = {
    mainServer: false,
    hideLogs: false,
    debug: false,
    mainURL: 'https://' + 'api.adibeshgh.com',
    localURL: 'http://hapi.eserv.ir',
    appKey: 'ASNuAtwPz3JelAD',
    imageServerURL: '',
    debugURL: 'XDEBUG_SESSION_START=PHPSTORM',
};

const getters = {
    isMainServer(state) {
        return state.mainServer
    },
    isHideLogs(state) {
        return state.hideLogs
    },
    isDebug(state) {
        return state.debug
    },
    getServerURL(state, getters) {
        return getters.isMainServer ? state.mainURL : state.localURL
    },
    getAppKey(state) {
        return state.appKey
    },
    getImageServerURL(state, getters) {
        return getters.getServerURL + '/Attachment/' + Vue.$cookies.get(getters.getACK) + '/dl?filename='
    },
    getImageCaptchaURL(state, getters) {
        return getters.getServerURL + '/Login/captchaImage?filename='
    },
    getDebugURL(state) {
        return state.debugURL
    },
};

const mutations = {};

const actions = {

    // sendRequest({commit}, object) {
    //  //   if (!object.noLoading)
    //  //     context.commit('setLoading', true)

    // commit('setCancelCurrentRequest', false)

//     return new Promise((resolve, reject) => {
    //         Vue.axios[object.method](object.url, object.method !== 'get' ? object.body : null, {
    //             // onUploadProgress: uploadEvent => {
    //             //     console.log('progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
    //             // }
    //         })
    //             .then(
    //                 response => {
    //                     // context.commit('setLoading', false)
    //
    //                     if (response)
    //                         if (response.status === 200)
    //                             resolve(response.data)
    //                 },
    //                 error => {
    //                     // context.commit('setLoading', false)
    //                     reject(error)
    //                 });
    //     })
    // },


    sendRequest({commit}, object) {
        // if (!object.noLoading)
        //     commit('setLoading', true)

        commit('setCancelCurrentRequest', false)

        return new Promise((resolve, reject) => {

            Vue.http[object.method](object.url, object.method !== 'get' ? object.body : null, {
                // progress(e) {
                //     if (e.lengthComputable) {
                //         console.log('progress: ' + Math.round(e.loaded / e.total * 100) + '%');
                //     }
                // }
            })
                .then(
                    response => {
                        // commit('setLoading', false)

                        if (response.status === 200) {
                            resolve(response.body)
                        }
                    },
                    error => {
                        // commit('setLoading', false)
                        reject(error)
                    });
        })
    },


};

export default {
    state,
    getters,
    mutations,
    actions
}
